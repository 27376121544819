import { Controller } from "@stimulus/core";

export default class extends Controller {
  static values = {
    id: String,
    username: String,
  };

  remove() {
    var dialog = document.querySelector('sl-dialog');
    dialog.title = 'Remove suspension';
    dialog.open = true;
  }

  confirmRemove() {
    var url = `/users/${this.usernameValue}/suspensions/${this.idValue}`
    fetch(url, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then( () => {
      var dialog = document.querySelector('sl-dialog');
      dialog.open = false;
      window.location.href = `/users/${this.usernameValue}`;
    }).catch((error) => {
      console.error('Error:', error);
    });
  }

}
