import $ from "jquery";
import axios from "axios";

import { Control } from "../control";
import { shareTwitter } from "../social";
import { wait } from "../timer_utils";
import { animateCSS, fadeOut, slideDown } from "../animation";

export class NotificationsController extends Control {
  constructor(el, options) {
    super(el, options);
    if (
      this._app.currentUser != null &&
      this._app.currentUser.username &&
      !this._app.currentUser.guest
    ) {
      this.deferred = wait(1000, () => {
        this.nextPopupNotification();
      });
    }
  }

  destroy() {
    super.destroy();
    if (this.deferred) {
      this.deferred.abort();
      this.deferred = null;
    }
  }

  maybeCheckNext() {
    // Don't check again after closing sticky notifications because this will be shown again.
    if (!$("#popup-notification-container").data("notification-sticky")) {
      this.deferred = wait(500, () => {
        this.nextPopupNotification();
      });
    }
  }

  nextPopupNotification(defaultPopupId) {
    this.deferred = null;
    axios
      .post(
        this._app.route("unread_popup_notifications"),
        {
          href: location.href,
          data: this._app.data,
          default_popup_id: defaultPopupId,
        },
        {
          responseType: "text",
        }
      )
      .then((response) => {
        const html = response.data;
        if (!html || typeof html !== "string") return;

        window.requestAnimationFrame(() => {
          $("#popup-notification-container").remove();
          const $el = $(html).appendTo("#app").hide();
          setTimeout(() => slideDown($el.get(0)), 250);
        });
      });
  }

  markAllRead() {
    this.markReadWait = null;
    this.markedRead = true;
    const $countEl = $("#notification_count");
    const data = { ids: $countEl.data("unread-ids") };
    if (!data.ids) return;

    axios.post(this._app.route("mark_notifications_read"), data).then((_) => {
      fadeOut($countEl.get(0));
    });
  }
}

NotificationsController.prototype.events = {
  /**
   * @this {NotificationsController}
   */
  "#notifications_drawer mouseenter"() {
    if (!this.markedRead) {
      if (this.markReadWait != null) this.markReadWait.abort();
      this.markReadWait = wait(700, () => this.markAllRead());
    }
  },

  /**
   * @this {NotificationsController}
   */
  "#notifications_drawer mouseleave"() {
    if (this.markReadWait != null) this.markReadWait.abort();
  },

  /**
   * @this {NotificationsController}
   */
  "#popup-notification-container .popup-toast a.close click"() {
    this.maybeCheckNext();
    animateCSS("#popup-notification-container", "fadeOutDown", (node) => {
      node.parentNode.removeChild(node);
    });
  },

  /**
   * @this {NotificationsController}
   */
  "#popup-notification-container .js-show-dialog click"() {
    $("#popup-notification-container").removeClass("toast").addClass("dialog");
  },

  /**
   * @this {NotificationsController}
   */
  "#popup-notification-container .popup-dialog .js-close click"() {
    this.maybeCheckNext();
    const $el = $("#popup-notification-container");
    fadeOut($el.get(0), {
      finish: () => {
        $el.remove();
      },
    });
  },

  /**
   * @this {NotificationsController}
   */
  "#popup-notification-container .js-tweet click"($el) {
    shareTwitter($el.data("tweet"));
  },
};
