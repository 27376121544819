export const codeMirrorMode = (mode) => {
  if (!mode || /^if(?:-not)?:/.test(mode)) return "text/plain";

  switch (mode) {
    case "bash":
    case "shell":
      return "shell";

    case "bf":
      return "text/x-brainfuck";
    case "c":
      return "text/x-c";
    case "cpp":
      return "text/x-c++src";
    case "cfml":
      return "javascript";
    case "csharp":
      return "text/x-csharp";

    case "sql":
      return "text/x-pgsql";

    case "solidity":
      return "text/x-solidity";
    case "typescript":
      return "application/typescript";

    case "php":
      return "text/x-php";
    case "scala":
      return "text/x-scala";
    case "java":
      return "text/x-java";
    case "objc":
      return "text/x-objectivec";
    case "kotlin":
      return "text/x-kotlin";
    case "racket":
      return "scheme";
    case "lisp":
    case "commonlisp":
      return "text/x-common-lisp";

    case "ocaml":
      return "text/x-ocaml";
    case "fsharp":
      return "text/x-fsharp";
    case "nim":
      return "text/x-nim";
    case "python":
    case "python3":
      return "python";
    case "purescript":
    case "idris":
      return "text/x-haskell";
    case "elm":
      return "text/x-elm";
    case "perl6":
    case "raku":
      return "text/x-perl6";
    case "d":
      return "text/x-d";
    case "factor":
      return "text/x-factor";
    case "forth":
      return "text/x-forth";
    case "coq":
      return "text/x-coq";
    case "vb":
      return "text/x-vb";
    case "prolog":
      return "text/x-prolog";
    case "lean":
      return "agda";
    case "math":
    case "latex":
      return "text/x-latex";
    case "cobol":
      return "text/x-cobol";
    case "haxe":
      return "text/x-haxe";
    case "json":
      return "application/json";
    default:
      return mode;
  }
};

// Keep this synced with `tab-size` in codemirror.css
export const codeMirrorTabSize = (mode) => {
  switch (mode) {
    case "kotlin":
    case "python":
    case "python3":
    case "rust":
    case "vb":
    case "cfml":
    case "raku":
    case "perl":
    case "d":
      return 4;
    default:
      return 2;
  }
};
