import { datadogRum } from "@datadog/browser-rum";

export const initDatadog = ({
  applicationId,
  clientToken,
  service,
  env,
  version,
  user,
}) => {
  if (env === "development") return;

  try {
    datadogRum.init({
      applicationId,
      clientToken,
      service,
      site: "us5.datadoghq.com",
      env,
      version,
      sessionSampleRate: 1,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    if (user && !user.guest) {
      // https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#user-session
      datadogRum.setUser({
        // Unique identifier
        id: user.id,
        // Displayed by default in Datadog RUM UI
        name: user.username,
        // Custom attributes to filter with
        role: user.role,
        red: user.subscriber,
      });
    }

    datadogRum.startSessionReplayRecording();
  } catch (err) {
    console.debug(err);
  }
};
