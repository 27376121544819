import { Controller } from "@stimulus/core";
import axios from "axios";
import { serialize } from "@shoelace-style/shoelace/dist/utilities/form.js";
import Turbolinks from "turbolinks";

import { disableButton, enableButton } from "./utils";
import { notify } from "../app/utils";

export default class extends Controller {
  static values = {
    id: String,
  };

  submit(event) {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    const button = form.querySelector("sl-button[type='submit']");
    disableButton(button);
    axios
      .put(`/admin/users/${this.idValue}`, { user: serialize(form) })
      .then((_) => {
        // No error, just refresh the page
        Turbolinks.visit();
      })
      .catch((error) => {
        if (error.response.status === 400) {
          notify(error.response.data.errors.join(", "), {
            variant: "warning",
            duration: 10000,
          });
          enableButton(button);
        }
      });
  }

}
