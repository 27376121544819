import { stash } from "../stash";
import { Control } from "../control";

export class Stashable extends Control {
  constructor(el, options) {
    super(el, options);
  }

  cache() {
    if (!this._cache) {
      this._cache = stash(this.stashGroup());
      this._cache._ = this._cache._ || {}; // hack - need to figure out why this is sometimes null
    }

    return this._cache;
  }

  stashGroup() {
    return this.options.stashGroup || "default_group";
  }

  getStash() {
    if (this.stashId) {
      return this.cache().get(this.stashId);
    }
  }

  updateStash() {
    let value = this.getValue();
    // REVIEW What's this hack about?
    // hack to allow us to store known empty values
    if (value === "") value = " ";
    if (value === this.originalValue) value = null;

    if (value || this.cache().get(this.stashId)) {
      if (this.stashId) {
        this.cache().set(this.stashId, value);
      }
    }
  }

  setStashId(stashId) {
    if (this.stashId !== stashId) {
      this.setValue(this.getValue(), stashId, true);
    }
  }

  emptyStash() {
    this.cache().clear();
  }

  setValue(value, stashId, preferStash) {
    if (value === this.getValue()) return false;
    this.updateStash();

    this.originalValue = value;

    if (stashId) this.stashId = stashId;
    if (preferStash) {
      value = this.getStash() || value;
    }

    // REVIEW What's this hack about?
    // hack to allow stash to be set to a known blank value
    if (value === " ") value = "";

    return this._setValue(value);
  }

  isDirty() {
    return !!this.originalValue && this.getValue() !== this.originalValue;
  }

  markClean() {
    this.originalValue = this.getValue();
    this.updateStash();
  }
}
