export const usTimezones = [
  "America/Atka",
  "America/Boise",
  "America/Chicago",
  "America/Denver",
  "America/Detroit",
  "America/Fort_Wayne",
  "America/Indiana",
  "America/Indianapolis",
  "America/Juneau",
  "America/Kentucky",
  "America/Knox_IN",
  "America/Los_Angeles",
  "America/Louisville",
  "America/Menominee",
  "America/Metlakatla",
  "America/New_York",
  "America/Nome",
  "America/North_Dakota",
  "America/Phoenix",
  "America/Shiprock",
  "America/Sitka",
  "America/Yakutat",
];
