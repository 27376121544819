import $ from "jquery";
import CodeMirror from "../../vendor/codemirror";

import { codeMirrorMode, codeMirrorTabSize } from "./codemirror";

export const highlightCodeBlocks = function ($root) {
  if ($root == null) $root = $("body");

  $root.find("code").each(function (i, el) {
    const $el = $(el);
    if ($el.data("highlighted") || !$el.html()) return;

    let language = $el.data("language") || $el.parent().attr("lang");
    // marked < 0.4 used `.lang-` prefix
    if (!language && /lang(?:uage)?-/.test($el.attr("class") || "")) {
      language = $el
        .attr("class")
        .split(" ")[0]
        .replace(/lang(?:uage)?-/, "");
    }
    const html = highlightCode($el.text(), language);
    if (html) {
      $el.html(html);
      $el.data("highlighted", true);
    }
  });
};

export const highlightCode = function (code, language) {
  try {
    const mode = shouldSkip(code) ? "text/plain" : codeMirrorMode(language);
    const tmp = document.createElement("div");
    CodeMirror.runMode(code, mode, tmp, {
      tabSize: codeMirrorTabSize(language),
    });
    return tmp.innerHTML;
  } catch (ex) {
    console.warn(`Failed to highlight: ${ex.message}`);
    console.warn(ex);
  }
};

// Highlight HTML-escaped code.
export const highlightEscapedCode = function (code, language) {
  const tmp = document.createElement("div");
  tmp.innerHTML = code;
  return highlightCode(tmp.textContent, language);
};

// Skip code with too many lines or super long lines because it can take a while to highlight and freeze the page.
const shouldSkip = (code) => {
  const lines = code.split("\n");
  return lines.length > 2000 || lines.some((x) => x.length > 500);
};
