import $ from "jquery";

import { Control } from "../../control";
import { shareFacebook, shareTwitter } from "../../social";

export class CodeChallengesSocialButtons extends Control {
  constructor(el, options) {
    super(el, options);
    if (
      !this._app.data.permissions ||
      this._app.data.permissions.save === false
    ) {
      $(".js-edit-challenge").remove();
    }
  }
}

CodeChallengesSocialButtons.prototype.events = {
  /**
   * @this {CodeChallengesSocialButtons}
   */
  ".js-share-email click"($el, e) {
    // Make sure to open in new Tab/Window.
    window.open($el.attr("href"), "mail");
    e.preventDefault();
    e.stopPropagation();
  },

  /**
   * @this {CodeChallengesSocialButtons}
   */
  ".js-share-twitter click"($el) {
    shareTwitter($el.data("share-text"));
  },

  /**
   * @this {CodeChallengesSocialButtons}
   */
  ".js-share-facebook click"($el) {
    shareFacebook({
      title: $el.data("share-title"),
      body: $el.data("share-body"),
    });
  },
};
