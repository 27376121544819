import $ from "jquery";

import { autoloadViews, highlightCodeBlocks, updateLayout } from "./ui";
import { renderView } from "./view";

export class Control {
  constructor(el, options) {
    this.element = $(el);
    this.options = Object.assign({}, this.constructor.defaults, options || {});
    /** @type {App} */
    this._app = this.options._app;
    delete this.options._app;
    // Any sub-controllers to destroy together.
    /** @type {Control[]} */
    this._controllers = [];
    // Functions to disconnect ActionCable channels.
    /** @type {Function[]} */
    this._unregisters = [];

    // don't bother to do anything if there is no element
    if (this.element.length > 0) {
      let controls = this.element.data("controls");
      if (!controls) {
        controls = [];
        this.element.data("controls", controls);
      }

      controls.push(this);
      bindEvents.call(this);

      document.addEventListener(
        "turbolinks:visit",
        () => {
          this.destroy();
        },
        { once: true }
      );
    }
  }

  destroy() {
    if (this.destroyed) return;

    unbindEvents.call(this);
    const controls = this.element.data("controls");
    if (controls) {
      const i = controls.indexOf(this);
      if (i !== -1) controls.splice(i, 1);
    }
    while (this._controllers.length > 0) {
      const c = this._controllers.pop();
      if (c && !c.destroyed) c.destroy();
    }

    while (this._unregisters.length > 0) {
      const unregister = this._unregisters.pop();
      if (unregister) unregister();
    }

    this.destroyed = true;
  }

  renderViews(data) {
    if (data == null) data = this.data;
    const filters = this.filters ? boundFilters(this) : null;

    this.element.find("view").each((_, el) => renderView($(el), data, filters));
    highlightCodeBlocks();
    autoloadViews(this._app);
    updateLayout();
  }
}

const bindEvents = function () {
  if (!this.events) return;

  this._bindings = [];
  Object.keys(this.events).forEach((key) => {
    const value = this.events[key];
    // support multiple expressions by splitting on commas
    key.split(",").forEach((expr) => {
      this._bindings.push(bindEvent.call(this, expr, value));
    });
  });
};

const bindEvent = function (key, cb) {
  const keys = key.split(" ");
  const origEventName = keys[keys.length - 1];
  const eventName = origEventName.replace("|", " ");

  const self = this;
  const callback = function (ev, data) {
    return cb.call(self, $(this), ev, data);
  };

  if (key === eventName) {
    this.element.on(eventName, callback);
    // return a function that will unbind when called
    return () => this.element.off(eventName, callback);
  } else {
    let $root = this.element;
    if (key.startsWith("window")) {
      $root = $(window);
      key = null;
    } else if (key.startsWith("#")) {
      $root = $("body");
    } else if (key.startsWith("body")) {
      $root = $("body");
      key = key.replace("body ", "");
    }
    // take the event name out of the key so that its just a selector
    if (key) key = key.replace(` ${origEventName}`, "");

    $root.on(eventName, key, callback);
    // return a function that will unbind when called
    return () => $root.off(eventName, key, callback);
  }
};

const unbindEvents = function () {
  if (this._bindings) {
    this._bindings.forEach((unbind) => {
      unbind();
    });
  }
};

const boundFilters = (controller) =>
  Object.keys(controller.filters).reduce((result, key) => {
    const fn = controller.filters[key];
    if (typeof fn === "function") result[key] = fn.bind(controller);
    return result;
  }, {});
