import $ from "jquery";

export { Template } from "./template";
export { autoloadViews } from "./autoload_views";
export { codeMirrorMode, codeMirrorTabSize } from "./codemirror";
export {
  highlightCode,
  highlightCodeBlocks,
  highlightEscapedCode,
} from "./syntax_highlight";
export { initTooltips, destroyTooltips, hideTooltips } from "./tooltip";

export const updateLayout = () => $(window).trigger("resize");
