import $ from "jquery";

import { Control } from "../../control";
import { createDisplayManager } from "../../services/display_manager";
import { MarkdownDisplay } from "../markdown_display";
import { CodeChallengesSocialButtons } from "./social_buttons";

export class CodeChallengesShowController extends Control {
  constructor(el, options) {
    super(el, options);

    this.displayManager = createDisplayManager({
      user: this._app.currentUser,
      id: "kata_show",
      partner: {
        type: "image",
        contrast: true,
      },
      brazeTargets: ["kata_description_1", "kata_description_2"],
    });

    const socialButtons = new CodeChallengesSocialButtons(
      this.element.find(".js-train-social"),
      { _app: this._app }
    );

    this.markdownDisplay = new MarkdownDisplay("#description", {
      _app: this._app,
      language: this._app.data.activeLanguage,
    });
    this.markdownDisplay.setMarkdown(this._app.data.description);

    this._controllers.push(socialButtons, this.markdownDisplay);
  }
}
