import { createApp, reactive } from "petite-vue";

/**
 * Mount `petite-vue` app and return its reactive state (`@vue/reactivity`).
 * For compatibility with TurboLinks, this also sets up a hook to restore the original HTML.
 *
 * @param targetOrSel {Element|String} Target element or selector for the target element.
 * @param state {Object} App's state. A reactive copy of this object is returned.
 * @returns Reactive state.
 */
export const mountApp = (targetOrSel, state) => {
  const [reactiveState, unmount] = mountAppWithManualUnmount(
    targetOrSel,
    state
  );
  // Add event listener to unmount automatically.
  document.addEventListener("turbolinks:visit", unmount, { once: true });

  // if local then print the reactivestate for the given string selector to make it easier to observe data changes to state
  if (
    typeof targetOrSel === "string" &&
    window.location.host.includes("localhost")
  ) {
    console.debug(`${targetOrSel} state`, reactiveState);
  }
  return reactiveState;
};

/**
 * Mount `petite-vue` app and return its reactive state (`@vue/reactivity`).
 * The caller is responsible for unmounting.
 *
 * @param targetOrSel {Element|String} Target element or selector for the target element.
 * @param state {Object} App's state. A reactive copy of this object is returned.
 * @returns A tuple of reactive state and a function to unmount.
 */
export const mountAppWithManualUnmount = (targetOrSel, state) => {
  const target =
    typeof targetOrSel === "string"
      ? document.querySelector(targetOrSel)
      : targetOrSel;
  if (!target) throw new Error(`${targetOrSel} not found`);

  const reactiveState = reactive(state);
  // For TurboLinks compatibility, cache before mounting and restore it on unmount.
  const cached = target.outerHTML;
  const app = createApp(reactiveState)
    .directive("sl-model", slModel)
    .mount(target);
  const unmount = () => {
    app.unmount();
    // Restore the original after unmount
    if (target.parentNode) target.outerHTML = cached;
  };
  return [reactiveState, unmount];
};

/**
 * Creates a stateful object which can be used to track api operations in a vue friendly reactive way.
 * @param defaultLoader
 * @return {*}
 */
export const createApiOp = (defaultLoader) => {
  const state = {
    data: null,
    loading: false,
    loaded: false,
    async load(loader = defaultLoader) {
      this.loading = true;
      this.data = await loader();
      this.loading = false;
      this.loaded = true;
    },
    async maybeLoad(loader = defaultLoader) {
      if (!this.loading && !this.loaded) {
        await this.load(loader);
      }
    },
  };

  return reactive(state);
};

// `v-sl-model`
const slModel = ({ el, effect, get, exp }) => {
  if (el.tagName === "SL-SELECT") {
    return slModelSelect({ el, effect, get, exp });
  }
  if (el.tagName === "SL-CHECKBOX") {
    return slModelCheckbox({ el, effect, get, exp });
  }

  if (el.tagName === "SL-INPUT" && el.type === "number") {
    return slModelNumberInput({ el, effect, get, exp });
  }

  const assign = get(`(val) => { ${exp} = val }`);
  const handler = (event) => assign(event.target.value);
  el.value = get() ?? "";
  effect(() => {
    el.value = get() ?? "";
  });
  const event = el.tagName === "SL-TEXTAREA" ? "sl-input" : "sl-change";
  el.addEventListener(event, handler);
  return () => {
    el.removeEventListener(event, handler);
  };
};

const slModelSelect = ({ el, effect, get, exp }) => {
  const assign = get(`(val) => { ${exp} = val }`);
  const maxSelectable = parseInt(el.dataset.maxSelectable, 10);
  const handler = (event) => {
    const value = event.target.value;
    if (Array.isArray(value) && !Number.isNaN(maxSelectable)) {
      assign(value.slice(0, maxSelectable));
    } else {
      assign(value);
    }
  };
  el.addEventListener("sl-change", handler);
  effect(() => {
    const modelValue = get();
    if (Array.isArray(modelValue)) {
      if (!Array.isArray(el.value) || !sameArray(el.value, modelValue)) {
        el.value = modelValue;
      }
    } else if (el.value !== modelValue) {
      el.value = modelValue;
    }
  });
  return () => {
    el.removeEventListener("sl-change", handler);
  };
};

const sameArray = (a, b) => {
  return a.length === b.length && a.every((a, i) => a === b[i]);
};

const slModelCheckbox = ({ el, effect, get, exp }) => {
  const assign = get(`(val) => { ${exp} = val }`);
  const handler = () => {
    const modelValue = get();
    const checked = el.checked;
    if (Array.isArray(modelValue)) {
      const elValue = el.value;
      const index = modelValue.indexOf(elValue);
      const found = index !== -1;
      if (checked && !found) {
        assign(modelValue.concat(elValue));
      } else if (!checked && found) {
        const filtered = [...modelValue];
        filtered.splice(index, 1);
        assign(filtered);
      }
    }
  };
  el.addEventListener("sl-change", handler);
  effect(() => {
    const value = get();
    if (Array.isArray(value)) {
      el.checked = value.indexOf(el.value) !== -1;
    }
  });
  return () => {
    el.removeEventListener("sl-change", handler);
  };
};

const slModelNumberInput = ({ el, effect, get, exp }) => {
  const assign = get(`(val) => { ${exp} = val }`);
  const handler = (event) => {
    const el = event.target;
    let val = el.valueAsNumber;
    if (!Number.isNaN(val)) {
      if (val < el.min) val = el.min;
      if (val > el.max) val = el.max;
    }
    assign(val);
  };
  el.addEventListener("sl-change", handler);
  effect(() => {
    el.valueAsNumber = get() ?? "";
  });
  return () => {
    el.removeEventListener("sl-change", handler);
  };
};
