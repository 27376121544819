import $ from "jquery";
import axios from "axios";
import Turbolinks from "turbolinks";
import { serializeJSON } from "../../../vendor/serialize-json";

import { stash } from "../../stash";
import cable from "../../../channels/cable";
import DjaxChannel from "../../../channels/djax";
import { DjaxRequest } from "../../djax";
import { replaceHistoryState } from "../../utils";
import { Control } from "../../control";
import { OutputPanel } from "../output_panel";
import { MarkdownEditor } from "../markdown_editor";
import { TextEditor } from "../text_editor";
import { fadeIn, slideDown } from "../../animation";
import { turbolinksRender } from "../../turbolinks_utils";
import { startDemo } from "./editor_demo";

export class CodeChallengesEditorController extends Control {
  constructor(el, options) {
    super(el, options);
    const fields = ["answer", "fixture", "setup", "package", "example_fixture"];
    // REVIEW Why are fields in `App.data.languages` URI encoded?
    if (this._app.data.languages && !this._app.data.languagesDecoded) {
      for (const k of Object.keys(this._app.data.languages)) {
        const v = this._app.data.languages[k];
        fields.forEach((p) => {
          v[p] = decodeURIComponent(v[p]);
        });
      }
      this._app.data.languagesDecoded = true;
    }

    const channel = new DjaxChannel();
    cable.subscribe(channel);
    const unbindDjax = channel.on("djax", (data) => {
      const request = DjaxRequest.find(data.dmid);
      if (request) {
        request.handleData(data);
      } else {
        DjaxRequest.data[data.dmid] = data;
      }
    });
    this._unregisters.push(unbindDjax);
    this._unregisters.push(() => channel.disconnect());

    const $form = $("form");
    if ($form.length > 0) $form.get(0).reset();

    this.outputPanel = new OutputPanel("#code_results", {
      _app: this._app,
      showClose: true,
    });

    const editorOptions = {
      _app: this._app,
      change: (editor) => {
        if (editor.isDirty() && !this.languageBeingSet) {
          this.dirty = true;
        }
      },
      stashGroup: this._app.data.id || "newkata",
    };

    this.cache = stash(editorOptions.stashGroup);

    this.codeSetup = new TextEditor("#code_setup", editorOptions);
    this.codeAnswer = new TextEditor("#code_answer", editorOptions);
    this.codeFixture = new TextEditor("#code_fixture", editorOptions);
    this.codeExampleFixture = new TextEditor(
      "#code_example_fixture",
      editorOptions
    );
    this.codePackage = new TextEditor("#code_package", editorOptions);
    this.editors = [
      this.codeSetup,
      this.codeAnswer,
      this.codeFixture,
      this.codeExampleFixture,
      this.codePackage,
    ];

    this.markdownEditor = new MarkdownEditor("#description", {
      _app: this._app,
      stashGroup: editorOptions.stashGroup,
    });
    this._controllers.push(
      this.outputPanel,
      ...this.editors,
      this.markdownEditor
    );

    if (this.cache.isEmpty()) {
      this.setLanguage();
    } else {
      this._app.confirmModal.show({
        titleHtml: "We found un-saved edits",
        messageHtml:
          "You have local edits that have not been saved. What would you like to do with them?",
        cancelHtml: "Discard",
        confirmHtml: "Keep",
        cancel: () => {
          this.emptyStashes();
          this.setLanguage();
        },
        confirm: () => {
          this.dirty = true;
          this.setLanguage();
        },
      });
    }

    this.dirty = false;

    if (this._app.data.showIntro) {
      setTimeout(() => startDemo(), 1000);
    }
  }

  getFormData() {
    const { code_challenge } = serializeJSON($("form.simple_form[action]"), {
      parseBooleans: true,
    });
    return {
      languages: this._app.data.languages,
      language: this._app.data.language,
      code_challenge,
    };
  }

  getVersion() {
    return $(".lang-dd:visible dd.is-active").data("version");
  }

  setVersion(version) {
    const data = this._app.data.languages[this._app.data.language];
    data.default_version = version || this.getVersion();
  }

  setLanguage(language) {
    if (language == null) language = this._app.data.language;
    this._app.data.language = language;

    $(".lang-dd").hide();
    $(`#${language}_version`).show();

    // get the language data
    let data = this._app.data.languages[language];

    // initialize new default language data if it doesnt already exist
    if (!data) {
      this._app.data.languages[language] = data = { name: language };
    }

    this.setVersion();

    // private helper used to configure each of the 3 editors
    const configureEditor = (name, editor, readonly) => {
      editor.save();
      setTimeout(
        () =>
          editor.setMode(
            language,
            name === "answer" || name === "setup" || name === "package"
          ),
        0
      );
      editor.setReadOnly(readonly);

      editor.options.save = () => (data[name] = editor.getValue());

      editor.setValue(data[name], `${language}_${name}`, true);

      if (this._app.data.languageErrors) {
        $("#languages .has-error").removeClass("has-error");
        if (this._app.data.languageErrors[language]) {
          for (let error of this._app.data.languageErrors[language]) {
            const selector = `#code_${error.name}`;
            setFieldError(selector, error.message, {
              ignoreMsg: true,
            });
            $(`.tabs a[href='${selector}']`)
              .closest("dd")
              .addClass("has-error");
          }
        }
      }
    };

    const fixtureLocked = this._app.data.lockedFixtures.indexOf(language) >= 0;
    const $lock = $(".icon-moon-lock");
    if (fixtureLocked) {
      $lock.show();
    } else {
      $lock.hide();
    }

    this.languageBeingSet = true;
    configureEditor("setup", this.codeSetup);
    configureEditor("answer", this.codeAnswer);
    configureEditor("fixture", this.codeFixture, fixtureLocked);
    configureEditor("example_fixture", this.codeExampleFixture);
    configureEditor("package", this.codePackage);
    this.languageBeingSet = false;

    this.markdownEditor.setStashId("description");
    this.markdownEditor.setLanguage(language);

    // update the url
    replaceHistoryState(this._app.route("edit", { language }));
  }

  save(go) {
    if (this._app.data.published) {
      this.publish(go);
    } else {
      this.beforeSubmit();
      axios
        .post(this._app.route("save"), this.getFormData(), {
          responseType: "text",
        })
        .then((response) => {
          this.emptyStashes();
          turbolinksRender(response.data);
          if (typeof go === "function") go();
        });
    }
  }

  beforeSubmit() {
    for (const editor of this.editors) editor.save();
    this.markdownEditor.editor.exitFullScreen();
  }

  publish(go) {
    this.beforeSubmit();
    new DjaxRequest({
      url: this._app.route("publish"),
      method: "POST",
      data: this.getFormData(),
      timeout: 50000,
      deferred: (json) => {
        if (json && json.html) {
          this.emptyStashes();
          // Restore with the response from the DeferredControllerResponse.
          const referrer = window.location.href;
          Turbolinks.controller.cache.put(
            referrer,
            Turbolinks.Snapshot.wrap(json.html)
          );
          Turbolinks.visit(referrer, { action: "restore" });
          if (typeof go === "function") go();
        } else {
          this._app.confirmModal.show({
            titleHtml:
              "<i class='icon-moon-warning'></i> Publishing Timed Out!",
            messageHtml:
              "The server timed out while trying to publish your changes.",
          });
        }
      },
    });

    this._app.confirmModal.show({
      hideActions: true,
      titleHtml: `<div class="inline-block w-8 pr-2">${LOADER}</div>Please wait while we publish`,
      messageHtml:
        "We are validating your code and publishing your changes. This shouldn't take too long.",
    });
  }

  emptyStashes() {
    this.cache.clear();
  }

  validateCode() {
    this.running = true;
    const $output = $("#code_results");
    if (!$output.is(":visible")) slideDown($output.get(0), { duration: 100 });

    const request = {
      language: this._app.data.language,
      code: this._activeSolution(),
      fixture: this._activeTest(),
      setup: this.codePackage.getValue(),
      languageVersion: this.getVersion(),
      testFramework: this._app.data.testFrameworks[this._app.data.language],
    };
    this.outputPanel.runner.run(request);
  }

  handleResponse() {}

  insertExample(category) {
    const confirm = () => {
      const route = this._app.route("language_example", {
        language: this._app.data.language,
        category,
      });
      axios.get(route).then((response) => {
        const json = response.data;
        if (json.success) {
          this.codeAnswer.setValue(json.answer);
          this.codeFixture.setValue(json.fixture);
          this.codeSetup.setValue(json.setup);
        }
      });
    };

    if (
      this.codeAnswer.getValue() ||
      this.codeSetup.getValue() ||
      this.codeFixture.getValue()
    ) {
      this._app.confirmModal.show({
        titleHtml: '<i class="icon-moon-warning"></i> Possible loss of data!',
        messageHtml:
          "You are about to replace your current code with example code. Are you sure you want to discard your changes?",
        confirmHtml: "Yes, go ahead and replace with example code",
        confirm,
      });
    } else {
      confirm();
    }
  }

  _activeSolution() {
    const active = document.querySelector(
      '[data-editor-group="solutions"] .is-active .code-editor'
    );
    switch (active && active.id) {
      // Initial solution
      case "code_setup":
        return this.codeSetup.getValue();
      // Complete solution is returned when Preloaded/Help is focused as well.
      case "code_answer":
      case "code_package":
      default:
        return this.codeAnswer.getValue();
    }
  }

  _activeTest() {
    const active = document.querySelector(
      '[data-editor-group="tests"] .is-active .code-editor'
    );
    switch (active && active.id) {
      // Example tests
      case "code_example_fixture":
        return this.codeExampleFixture.getValue();
      // Submission test is returned when Help is focused as well
      case "code_fixture":
      default:
        return this.codeFixture.getValue();
    }
  }
}

CodeChallengesEditorController.prototype.events = {
  /**
   * @this {CodeChallengesEditorController}
   */
  "window beforeunload"(el, e) {
    if (this.dirty) {
      e.preventDefault();
      return "You risk losing your progress!";
    }
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "window keydown"(el, e) {
    if (e.keyCode === 8 && !$(e.target).is("input, textarea")) {
      e.preventDefault();
      return false;
    } else if (e.keyCode === 83 && e.metaKey) {
      this.save();
      e.preventDefault();
      return false;
    }
  },

  /** @this {CodeChallengesEditorController} */
  "#validate_answer click"(btn, e) {
    e.preventDefault();
    this.validateCode();
  },

  /** @this {CodeChallengesEditorController} */
  "#code_answer texteditor.submit, #code_fixture texteditor.submit, #code_package texteditor.submit, #code_example_fixture texteditor.submit"() {
    this.validateCode();
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "#insert_example click"(btn, e) {
    e.preventDefault();
    this.insertExample($("#code_challenge_category").val());
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "#categories li click"(el, e) {
    $("#code_challenge_category").val(el.data("value"));
    $("#categories li").removeClass("is-active");
    el.addClass("is-active");
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "#language_dd dd click"($el, e) {
    this.setLanguage($el.data("language"));
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  ".lang-dd dd click"($el, e) {
    this.setVersion();
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "#revert click"(el, e) {
    e.preventDefault();
    this.emptyStashes();
    Turbolinks.visit();
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "#retire click"() {
    this._app.confirmModal.show({
      titleHtml: "Are you sure you want to retire this kata?",
      messageHtml: "This cannot be undone via the UI",
      confirmHtml: "Retire it",
      confirm: () => {
        axios.post(this._app.route("retire")).then((response) => {
          const json = response.data;
          if (json.success) Turbolinks.visit();
        });
      },
    });
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "#save click"(el, e) {
    e.preventDefault();
    this.save();
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "#preview click"(el, e) {
    e.preventDefault();
    const go = () =>
      Turbolinks.visit(
        this._app.route("preview", { language: this._app.data.language })
      );

    if (this.dirty) {
      this._app.confirmModal.show({
        titleHtml: "You have un-saved changes",
        messageHtml:
          "You must save your changes before previewing. Do you want to continue?",
        confirmHtml: "Yes, I want to save changes and continue",
        confirm: () => {
          this.save(go);
        },
      });
    } else {
      go();
    }
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "#publish:not(.is-disabled) click"(el, e) {
    e.preventDefault();
    this.publish();
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "#unpublish click"(el, e) {
    e.preventDefault();
    this.beforeSubmit();
    axios
      .post(this._app.route("unpublish"), this.getFormData(), {
        responseType: "text",
      })
      .then((response) => {
        this.emptyStashes();
        turbolinksRender(response.data);
      });
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "#delete click"(el, e) {
    e.preventDefault();
    this._app.confirmModal.show({
      titleHtml:
        '<i class="icon-moon-warning"></i> Are you sure you want to delete this code kata?',
      messageHtml: "You will not be able to undo this action",
      confirmHtml: "Yes, I want to delete this kata",
      confirm: () => {
        // Redirects to /kata/new
        axios
          .delete(this._app.route("destroy"), { responseType: "text" })
          .then((response) => {
            turbolinksRender(response.data);
          });
      },
    });
  },

  // hack to make sure setup editor display properly
  /**
   * @this {CodeChallengesEditorController}
   */
  "#code_answerLabel click"() {
    setTimeout(() => {
      this.codeAnswer.refresh();
      this.codeAnswer.resize();
    }, 0);
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "#initial_setupLabel click"() {
    setTimeout(() => {
      this.codeSetup.refresh();
      this.codeSetup.resize();
    }, 0);
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "#code_packageLabel click"() {
    setTimeout(() => {
      this.codePackage.refresh();
      this.codePackage.resize();
    }, 0);
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "#code_fixtureLabel click"() {
    setTimeout(() => {
      this.codeFixture.refresh();
      this.codeFixture.resize();
    }, 0);
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "#code_exampleFixtureLabel click"() {
    setTimeout(() => {
      this.codeExampleFixture.refresh();
      this.codeExampleFixture.resize();
    }, 0);
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "input change"() {
    this.dirty = true;
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "textarea change"() {
    this.dirty = true;
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "select change"() {
    this.dirty = true;
  },

  // remove_language
  /**
   * @this {CodeChallengesEditorController}
   */
  "#language_dd i.remove click"(el, e) {
    e.stopPropagation();
    e.preventDefault();

    const language = el.closest("dd").data("language");
    const languageName = el.closest("dd").text();
    const languageData = this._app.data.languages[language];

    this._app.confirmModal.show({
      titleHtml: `<i class='icon-moon-warning'></i> Are you sure you want to delete ${languageName}?`,
      messageHtml:
        "This action is destructive. All data related to this language will be deleted from the record.",
      confirmHtml: "Yes, I want to delete this language",
      confirm: () => {
        const removeLanguage = () => {
          $(el).closest("dd").removeClass("has-value");
          this._app.data.languages[language] = null;

          if (this._app.data.language === language) {
            for (let editor of this.editors) editor.setValue("");
            this.setLanguage();
          }
        };

        if (languageData && languageData.id) {
          axios
            .delete(this._app.route("remove_language", { language }))
            .then((response) => {
              const json = response.data;
              if (json.success) removeLanguage();
            });
        } else {
          removeLanguage();
        }
      },
    });
  },

  /**
   * @this {CodeChallengesEditorController}
   */
  "#runner_frame message.notifyResponse"() {
    if (this.running && this.outputPanel.runner.response) {
      this.running = false;
      this.handleResponse();
    }
  },
};

const setFieldError = (selector, errorMsg, options) => {
  let $msg, labelText;
  if (options == null) options = {};
  const $field = $(selector);
  const $container = $field
    .closest(".field")
    [errorMsg ? "addClass" : "removeClass"]("has-error");
  let $small = $container.find("small");
  const $msgBox = $field.closest("form").find(".alert-box.error");

  $msgBox.find(`[data-selector='${selector}']`).remove();
  if (options.fieldName) {
    $msgBox.find(`[data-field='${options.fieldName}']`).remove();
  }

  if (errorMsg) {
    if ($small.length === 0) {
      $small = $("<small></small>");
      $container.find(".field_value").append($small);
    }

    fadeIn($small.html(errorMsg).get(0));

    labelText = options.fieldLabel || getFieldLabelText($container);

    if (!options.ignoreMsg) {
      $msg = $("<li></li>");
      $msg.attr("data-selector", selector);
      $msg.html(`${labelText} ${errorMsg}`);
      if (options.msgClass) {
        this.msg.addClass(options.msgClass);
      }
      $msgBox.find("ul").append($msg);
      fadeIn($msgBox.get(0));
    }
  } else {
    $small.hide();
    if ($msgBox.find("li").length === 0 && !options.ignoreMsg) {
      $msgBox.hide();
    }
  }

  return { $field, $container, $small, labelText, $msg, $msgBox };
};

const getFieldLabelText = (field) => {
  let $field = $(field);
  if (!$field.is(".field")) $field = $field.closest(".field");

  const $label = $field.find("label");
  return $label.clone().find("*").remove().end().text();
};

// https://github.com/SamHerbert/SVG-Loaders
const LOADER = `
<svg viewbox="0 0 57 57" stroke="currentColor" class="text-cw-red">
<g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
  <circle cx="5" cy="50" r="5">
    <animate attributeName="cy" begin="0s" dur="2.2s" values="50;5;50;50" calcMode="linear" repeatCount="indefinite"/>
    <animate attributeName="cx" begin="0s" dur="2.2s" values="5;27;49;5" calcMode="linear" repeatCount="indefinite"/>
  </circle>
  <circle cx="27" cy="5" r="5">
    <animate attributeName="cy" begin="0s" dur="2.2s" from="5" to="5" values="5;50;50;5" calcMode="linear" repeatCount="indefinite"/>
    <animate attributeName="cx" begin="0s" dur="2.2s" from="27" to="27" values="27;49;5;27" calcMode="linear" repeatCount="indefinite"/>
  </circle>
  <circle cx="49" cy="50" r="5">
    <animate attributeName="cy" begin="0s" dur="2.2s" values="50;50;5;50" calcMode="linear" repeatCount="indefinite"/>
    <animate attributeName="cx" from="49" to="49" begin="0s" dur="2.2s" values="49;5;27;49" calcMode="linear" repeatCount="indefinite"/>
  </circle>
  </g>
</svg>
`;
