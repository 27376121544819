import $ from "jquery";
import axios from "axios";
import Turbolinks from "turbolinks";

import { serializeJSON } from "../../../vendor/serialize-json";
import { Control } from "../../control";
import { turbolinksRender } from "../../turbolinks_utils";

export class RegistrationsEditController extends Control {
  constructor(el, options) {
    super(el, options);
  }
}

RegistrationsEditController.prototype.events = {
  /**
   * @this {RegistrationsEditController}
   */
  "#delete_account click"($el, e) {
    e.preventDefault();
    this._app.confirmModal.show({
      titleHtml: "Are you sure?",
      messageHtml:
        "You are about to delete everything you have worked so hard for. If all you wish to do is stop receiving notification emails you can simply turn them off without deleting your account.",
      cancelHtml: "Nooo stop! I was just kidding!",
      confirmHtml: "Yep, I'm serious about doing this",
      confirm: () => {
        axios.delete("/user").then((response) => {
          const json = response.data;
          if (json.success) Turbolinks.visit("/");
        });
      },
    });
  },

  /** @this {RegistrationsEditController} */
  "#edit_user submit"($el, e) {
    e.preventDefault();
    $("#flash").remove();

    const { user } = serializeJSON($($el.get(0)));
    const params = document.getElementById("remove_previous_clan_members")
      .checked
      ? { remove_previous_clan_members: "1" }
      : {};
    axios.put("/users", { user }, { params }).then((res) => {
      turbolinksRender(res.data);
    });
  },

  ".js-resend-confirmation:not(.is-disabled) click"($el, e) {
    e.preventDefault();
    $el.addClass("is-disabled");

    // Handle this the same way as jquery-ujs does with `data-method="post"`.
    const csrfParam = $("meta[name=csrf-param]").attr("content");
    const csrfToken = $("meta[name=csrf-token]").attr("content");
    const $form = $(
      [
        `<form method="post" action="${$el.data("href")}">`,
        `<input name="_method" value="POST" type="hidden" />`,
        `<input name="${csrfParam}" value="${csrfToken}" type="hidden" />`,
        `</form>`,
      ].join("")
    );
    $form.hide();
    $form.appendTo("body");
    $form.trigger("submit");
  },
};
