import { Control } from "../control";
import { OutputPanel } from "./output_panel";

export class CodeSnippetListItemController extends Control {
  constructor(el, options) {
    super(el, options);
  }

  showRunResult($el) {
    let $result = $el.closest(".run-result");
    if ($result.length === 0) {
      $result = $el.closest(".panel").find(".run-result");
    }

    $result.removeClass("is-hidden");
    this.result = $result.data("runResult");
    this.panel = new OutputPanel($result, {
      _app: this._app,
      box: false,
    });
    this.panel.runner.expand();
    this._controllers.push(this.panel);

    setTimeout(() => {
      this.panel.runner.display(this.result);
    }, 500);

    const output =
      this.result.output ||
      (this.result.response &&
        this.result.response.result &&
        this.result.response.result.output);
    if (output) {
      this.panel.runner.element.height(Math.min(output.length * 30 + 200, 400));
    }
  }
}

CodeSnippetListItemController.prototype.events = {
  /**
   * @this {CodeSnippetListItemController}
   */
  ".code-snippet-list-item.is-collapsed click"($el) {
    $el.removeClass("is-collapsed");
  },

  // When the list is collapsed, we want to prevent the normal link from navigating to its page, and instead
  // allow it to expand itself.
  /**
   * @this {CodeSnippetListItemController}
   */
  ".code-snippet-list-item.is-collapsed a click"($el, e) {
    e.preventDefault();
  },

  /**
   * @this {CodeSnippetListItemController}
   */
  "a.js-show-fixture click"($el) {
    $el.closest(".panel").find(".code-snippet-fixture-details").show();
    $el.closest("li").hide();
  },

  /**
   * @this {CodeSnippetListItemController}
   */
  "a.js-show-run-result click"($el) {
    this.showRunResult($el);
    $el.closest("li").hide();
  },

  /**
   * @this {CodeSnippetListItemController}
   */
  ".run-result-link a click"($el) {
    const $link = $el.closest(".run-result-link").hide();
    this.showRunResult($link.next());
  },
};
