import { Controller } from "@stimulus/core";
import axios from "axios";
import Turbolinks from "turbolinks";

import { disableButton, enableButton } from "./utils";
import { notify } from "../app/utils";

export default class extends Controller {
  static values = {
    token: String,
  };

  async signOut(event) {
    event.preventDefault();
    event.stopPropagation();
    await axios.delete("/users/sign_out");
    Turbolinks.visit();
  }

  submit(event) {
    event.preventDefault();
    event.stopPropagation();
    const button = event.currentTarget;
    disableButton(button);
    axios
      .post(`/subscription/andela`)
      .then((res) => {
        notify(res.data.message, {
          variant: "success",
          duration: 3000,
        }).then(() => {
          Turbolinks.visit("/dashboard");
        });
      })
      .catch((error) => {
        if (error.response.status === 400) {
          notify(error.response.data.error, {
            variant: "warning",
            duration: 5000,
          });
          enableButton(button);
        }
      });
  }
}
