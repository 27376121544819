import $ from "jquery";
import axios from "axios";
import Turbolinks from "turbolinks";
import { serializeJSON } from "../../../vendor/serialize-json";

import { replaceHistoryState } from "../../utils";
import { wait } from "../../timer_utils";
import { Template, initTooltips } from "../../ui";
import { renderView } from "../../view";
import { Ide } from "../ide";
import { CodeSnippetListItemController } from "../code_snippet_list_item_controller";

export class CodeSnippetsEditController extends Ide {
  constructor(el, options) {
    super(el, options);
    this.fields = this._app.data.fields.code_snippet;

    // set defaults to be used by views
    this._app.data.activeCode = "code";
    this._app.data.activeFixture = "fixture";

    // select the first translation tab, if present
    $("#translation_details dd a:first").trigger("click");

    const listItem = new CodeSnippetListItemController(this.element, {
      _app: this._app,
    });
    this._controllers.push(listItem);
  }

  // ready is called after the rest of the app is done loading. Usually only needs to be called
  // when rendering the initial views
  ready() {
    // waiting prevents certain markdown editor display bugs from happening
    setTimeout(() => this.renderViews(), 0);
  }

  canSave() {
    if (!this.canRun()) return false;

    if (this._app.data.isTranslation) {
      return this.fields.code.value;
    } else {
      return !!this.fields.code.value;
    }
  }

  canRun() {
    return this.hasCode() && !this.running && !this.publishing;
  }

  isSupportedLanguage(language) {
    if (language == null) language = this.getLanguage();
    return this._app.data.supportedLanguages.indexOf(language) >= 0;
  }

  isTddLanguage() {
    return this._app.data.tddLanguages.indexOf(this.getLanguage()) >= 0;
  }

  isTddEnabled() {
    return this._app.data.tdd && this.isTddLanguage();
  }

  updateTags() {
    if (this.tags === this.fields.user_tags.value) return;

    const tags = (this.tags = this.fields.user_tags.value);
    if (this.mapTags != null) this.mapTags.abort();
    this.mapTags = wait(100, () => {
      this.mapTags = null;
      axios
        .post(this._app.route("map_topics"), { query: tags })
        .then((response) => {
          const json = response.data;
          this._app.data.mappedTags = json.tags;
          const $el = $("#tags");
          const $tmpl = $($el.data("template"));
          const template =
            $tmpl.data("template") || new Template($tmpl, { _app: this._app });
          template.render($el, $el.data("context"));
        });
    });
  }

  updateTitle() {
    renderView($("#title"));
  }

  updateButtons(force) {
    if (force || this.canSave() !== this._app.data.canSave) {
      this._app.data.canSave = this.canSave();
      renderView($("#buttons"));
    }
  }

  activeCodeEditor() {
    switch (this._app.data.activeCode) {
      case "code":
        return this.editor;
      case "setup":
        return this.setupCode;
      case "package":
        return this.package;
    }
  }

  toggleFocusedEditor() {
    if (this.activeCodeEditor().focused && this.fixture) {
      this.fixture.focus();
    } else {
      this.activeCodeEditor().focus();
    }
  }

  // loops through the panel tabs
  toggleTabs() {
    const $active = $("#panel_toggles li.is-active");
    let $next = $active.next();
    if ($next.length === 0) {
      $next = $active.siblings().eq(0);
    }

    $next.find("a").trigger("click");
  }

  configureEditor(editor, isSolution) {
    if (!editor) return;

    if (this._app.data.publishedForks) {
      editor.setReadOnly(true);
    }
    editor.options.stashGroup = this._app.data.id;
    editor.setOption("commands", { save: () => this.validate() });
    editor.setMode(this.getLanguage(), isSolution);
    editor.setExtraKeys({
      "Cmd-S": () => {
        if (this._app.data.published) {
          this.publish();
        } else {
          this.save();
        }
      },
      "Cmd-I": () => this.toggleTabs(),
      "Cmd-U": () => this.toggleFocusedEditor(),
    });

    this.editors.push([editor, isSolution]);
  }

  configureEditors() {
    this.editors = [];
    this.code = this.editor = $("#code_snippet_code_field").data("editor");

    if (this.editor) {
      // need a delay for SQL mode for some reason
      setTimeout(() => this.configureEditor(this.editor, true), 0);
      this.editor.focus();
    }

    this.fixture = $("#code_snippet_fixture_field").data("editor");

    if (this.fixture) {
      this.configureEditor(this.fixture);
      this.fixture.emptyCodeMsg = () =>
        "You are using TDD. You must provide test cases before you can run your code. You can turn TDD mode off using the switch in the upper right corner.";
    }

    this.package = $("#code_snippet_package_field").data("editor");
    this.configureEditor(this.package, true);

    this.setupCode = $("#code_snippet_setup_code_field").data("editor");
    this.configureEditor(this.setupCode, true);

    this.exampleFixture = $("#code_snippet_example_fixture_field").data(
      "editor"
    );
    this.configureEditor(this.exampleFixture);
  }

  render() {
    this.updateButtons(true);
    this.updateTitle();
    renderView($("#buttons, #edit, #editors, #options_bar, #language_info"));
    initTooltips();
  }

  setTestFramework(framework) {
    framework =
      framework ||
      this.fields.test_framework.value ||
      $("#test_framework dd").data("framework");
    if (!framework) return;

    // try to select the framework given or pre-set
    let $dd = $(`dd[data-framework='${framework}']`);
    // if no framework was found then select the first one in the list
    if ($dd.length === 0) $dd = $("dd[data-framework]").eq(0);
    $dd.trigger("click").trigger("click");
  }

  getLanguage() {
    return this.fields.language.value || this._app.data.language;
  }

  getLanguageVersion() {
    return this.fields.language_version.value;
  }

  getLanguageInfo(language) {
    if (language == null) language = this.getLanguage();
    return this._app.data.versionInfo[language];
  }

  setLanguageVersion(value, _value) {
    const currentLang = this.fields.language.value;

    // HACK: _value is a quick hack to prevent infinite recursion errors. Sometimes data gets messed up somehow and
    // this is here to try to correct for it
    if (value && value === _value) {
      if (value !== currentLang) {
        this.setLanguageVersion(currentLang, value);
      } else {
        this.setLanguageVersion(null, value);
      }
      return;
    }

    // if a language then try to figure it out
    if (this._app.data.versionInfo[value]) {
      // if the language is the originally loaded language
      if (value === this.fields.language.originalValue) {
        this.setLanguageVersion(
          this.fields.language_version.originalValue,
          value
        );
      } else {
        const info = this.getLanguageInfo(value);
        if (info) {
          const x = info.find((v) => v.default);
          this.setLanguageVersion(x && x.id, value);
        } else {
          this.setLanguageVersion(null, value);
        }
      }
      return;
    }

    // else a version so just set it directly
    if (value || !currentLang) {
      this.fields.language_version.value = value;
    } else {
      this.fields.language_version.value =
        this._app.data.versionInfo[currentLang] != null
          ? this._app.data.versionInfo[currentLang][0].id
          : undefined;
    }
  }

  setLanguage(language) {
    if ((language && this.getLanguage() !== language) || !this.languageSet) {
      this.languageSet = true;

      this.resetFixtures();

      this.fields.language.value = language;
      this.setLanguageVersion(language);
      this.setTestFramework();

      const mode = this.fields.language.value;
      this._app.data.language = mode;

      this.render();

      if (this.editors != null) {
        this.editors.forEach(([editor, isSolution]) =>
          editor.setMode(mode, isSolution)
        );
      }

      if (this.isSupportedLanguage()) {
        $("#output_btn").show();
      } else {
        if ($("#output_btn").hide().hasClass("is-active")) {
          $("#edit_btn a").trigger("click");
        }
      }
    }

    this.defaultFixtures();
  }

  // Override to do nothing. We do not need to use editor borders since we
  // only have one set of tests
  setEditorBorder(editor, active) {}

  hasCode() {
    return !!(this.editor && this.editor.getValue());
  }

  getCode() {
    if (this._app.data.activeCode === "setup" && !this.publishing) {
      return this.setupCode && this.setupCode.getValue();
    } else {
      return this.editor && this.editor.getValue();
    }
  }

  fixtureEditors() {
    return [this.exampleFixture, this.fixture];
  }

  resetFixtures() {
    if (!this.fixture) return;

    const language = this.getLanguage();
    this.fixtureEditors().forEach((editor) => {
      if (editor.getValue() === editor.defaultFixtureText(language)) {
        editor.setValue("");
      }
    });
  }

  defaultFixtures() {
    if (!this.fixture) return;

    const language = this.getLanguage();
    this.fixtureEditors().forEach((editor) => {
      if ((editor.getValue() || "") === "") {
        editor.setValue(editor.defaultFixtureText(language));
      }
    });
  }

  getFixture() {
    if (!this.fixture) return;

    if (this._app.data.activeFixture === "example_fixture") {
      return this.exampleFixture.getValue();
    } else if (this._app.data.isTranslation || this.isTddEnabled()) {
      return this.fixture.getValue();
    }
  }

  getPackage() {
    if (this.package) {
      return this.package.getValue();
    } else {
      return this.fields.package.value;
    }
  }

  getTestFramework() {
    return (
      this.fields.test_framework.value ||
      this._app.data.defaultFrameworks[this.getLanguage()]
    );
  }

  validate() {
    if (!this.isSupportedLanguage()) return;

    $("#output_btn").trigger("click");
    this.run({
      language: this.getLanguage(),
      languageVersion: this.getLanguageVersion() + "",
      setup: this.getPackage(),
      relayId: this._app.data.id,
    });
  }

  save(next) {
    this._app.alerts.working("Saving...");

    $("#code_snippet_language").val(this.getLanguage());
    $("#code_snippet_language_version").val(this.getLanguageVersion());

    axios({
      url: this._app.data.new
        ? this._app.route("create")
        : this._app.route("update", { id: this._app.data.id }),
      method: this._app.data.new ? "post" : "put",
      data: serializeJSON($("form"), { parseBooleans: true }),
    }).then((response) => {
      const json = response.data;
      this.dirty = false;
      this._app.alerts.hide();
      this._app.data.fields = json.fields;
      this._app.data.errors = json.errors;

      if (json.success) {
        if (json.id) this._app.data.id = json.id;
        if (this._app.data.new && json.url) {
          Turbolinks.visit(json.url);
        } else {
          if (json.url) replaceHistoryState(json.url);
          this.updateTitle();
          if (typeof next === "function") next();
        }
      } else {
        this.publishing = false;
        $("#edit_btn a").trigger("click");
      }

      // this.render();
      this.updateButtons(true);
      this.updateTitle();
      // Not rendering #edit for now because it seems to work without it and causes a bug that makes publishing stuck.
      // renderView($("#edit"));
      renderView($("#editors, #options_bar, #language_info"));
      initTooltips();
    });
  }

  publish() {
    this._app.alerts.working("Publishing...");
    if (this.editor) this.editor.messages.hide();
    if (this.fixture) this.fixture.messages.hide();

    $(".js-run").addClass("is-disabled");

    this.publishing = true;
    this.save(() => {
      this.validate();
    });
  }

  handleResponse() {
    this.updateButtons(true);
    if (!this.publishing) return;

    let data = this.outputPanel.runner.response;
    if (!data || !data.token) {
      this.running = false;
      this.publishing = false;
      this.updateButtons(true);
      window.alert("Something went wrong, please try republishing again");
      return;
    }

    this.showOutputWaiting("Publishing...");
    $(".js-run").removeClass("is-disabled");
    this.publishing = false;

    data = {
      token: data.token,
      run_result: JSON.stringify({
        response: {
          result: data.result,
        },
      }),
    };

    axios
      .post(this._app.route("publish", { id: this._app.data.id }), data)
      .then((response) => {
        const json = response.data;
        $("#publish_btn").removeClass("is-disabled");

        if (
          (this._app.data.allowFailedTests || json.completed) &&
          !json.message
        ) {
          Turbolinks.visit(this._app.route("show", { id: this._app.data.id }));
        } else {
          this._app.alerts.warn("Kumite was published with failed tests!");
          this.editor.messages.hide();
          this.showResults(json);
        }
      });
  }

  unpublish() {
    axios
      .post(this._app.route("unpublish", { id: this._app.data.id }))
      .then((response) => {
        if (response.data.success) Turbolinks.visit();
      });
  }
}

CodeSnippetsEditController.prototype.filters = {
  /** @this {CodeSnippetsEditController} */
  "tdd_language?"() {
    return this.isTddLanguage();
  },

  /** @this {CodeSnippetsEditController} */
  "tdd_enabled?"() {
    return this.isTddEnabled();
  },

  /** @this {CodeSnippetsEditController} */
  "active_language_supported?"() {
    return this.isSupportedLanguage();
  },

  /** @this {CodeSnippetsEditController} */
  "can_save?"() {
    return this.canSave();
  },

  /** @this {CodeSnippetsEditController} */
  "has_code?"() {
    return this.hasCode();
  },

  /** @this {CodeSnippetsEditController} */
  "can_run?"() {
    return this.canRun();
  },

  /** @this {CodeSnippetsEditController} */
  language_versions() {
    return this.getLanguageInfo();
  },

  /** @this {CodeSnippetsEditController} */
  active_language() {
    return this.getLanguage();
  },

  /** @this {CodeSnippetsEditController} */
  active_version() {
    return this.getLanguageVersion();
  },

  /** @this {CodeSnippetsEditController} */
  active_version_label() {
    const version = this.getLanguageVersion();
    let info = this.getLanguageInfo();
    if (!info) return;

    info = info.find((v) => v.id === version);
    return (info && info.label) || version;
  },
};

CodeSnippetsEditController.prototype.events = Object.assign(
  {},
  Ide.prototype.events,
  {
    /** @this {CodeSnippetsEditController} */
    "window beforeunload"(el, e) {
      if (this.dirty) {
        e.preventDefault();
        return "You have unsaved changes!";
      }
    },

    // TODO `#editors view:render` and `#edit view:render` are creating and destroying the output panel unnecessarily
    // whenever the view is re-rendered we need to reinit
    /** @this {CodeSnippetsEditController} */
    "#editors view:render"() {
      // this.configureIde();
      this.configureEditors();

      // set the initial language if it isn't set already
      this.setLanguage(this.getLanguage());
      this.setTestFramework();
    },

    /** @this {CodeSnippetsEditController} */
    "#edit view:render"() {
      this.configureIde();
    },

    /** @this {CodeSnippetsEditController} */
    "#languages dd click"($el) {
      const $item = $("#languages dd.is-active");
      this.setLanguage($item.data("language"));
    },

    /** @this {CodeSnippetsEditController} */
    "#versions dd click"($el) {
      this.setLanguageVersion($el.data("version"));
    },

    /** @this {CodeSnippetsEditController} */
    "#tdd_mode:not(.is-disabled) click"($el) {
      this._app.data.tdd = !this._app.data.tdd;
      renderView($("#options_bar, #editors"));
      initTooltips();
    },

    /** @this {CodeSnippetsEditController} */
    "#edit_btn click"() {
      setTimeout(() => renderView($("#edit")), 0);
    },

    /** @this {CodeSnippetsEditController} */
    "#save_btn click"() {
      this.save();
    },

    /** @this {CodeSnippetsEditController} */
    "#publish_btn:not(.is-disabled) click"() {
      this.publish();
    },

    /** @this {CodeSnippetsEditController} */
    "#unpublish_btn click"() {
      this.unpublish();
    },

    /** @this {CodeSnippetsEditController} */
    "#convert_btn click"() {
      this._app.confirmModal.show({
        titleHtml: "Are you sure you want to convert?",
        messageHtml:
          "This will create a new kata using the data in this kumite. The kumite will be unpublished/hidden.",
        cancelHtml: "Cancel",
        confirmHtml: "Do it!",
        cancel: () => {},
        confirm: () => {
          $("#buttons .btn").addClass("is-disabled");
          this.save(() => {
            $("#buttons .btn").addClass("is-disabled");
            window.requestAnimationFrame(() => {
              axios.post(this._app.route("convert")).then((response) => {
                const json = response.data;
                if (json.data && json.data.url) Turbolinks.visit(json.data.url);
              });
            });
          });
        },
      });
    },

    /** @this {CodeSnippetsEditController} */
    "texteditor.change"() {
      this.updateButtons(true);
      this.dirty = true;
    },

    /** @this {CodeSnippetsEditController} */
    "#code_snippet_title change, #code_snippet_title keyup"() {
      this.updateTitle();
    },

    /** @this {CodeSnippetsEditController} */
    "#code_snippet_user_tags change, #code_snippet_user_tags keyup"() {
      this.updateTags();
    },

    /** @this {CodeSnippetsEditController} */
    "#code_snippet_secret change, #code_snippet_secret click"() {
      this.updateTitle();
    },

    /** @this {CodeSnippetsEditController} */
    "texteditor.submit"() {
      if (this.hasCode()) this.validate();
    },

    /** @this {CodeSnippetsEditController} */
    "dd[data-framework] click"($el) {
      $("#code_snippet_test_framework")
        .val($el.data("framework"))
        .trigger("change");
    },

    /** @this {CodeSnippetsEditController} */
    "#code_container dd a click"($el) {
      this._app.data.activeCode = $el.data("tab");
      this.updateButtons(true);
    },

    /** @this {CodeSnippetsEditController} */
    "#fixture_container dd a click"($el) {
      this._app.data.activeFixture = $el.data("tab");
    },

    /** @this {CodeSnippetsEditController} */
    ".js-details-link click"() {
      $("#edit_btn a").trigger("click");
    },
  }
);
