import { Control } from "../control";
import { updateLayout } from "../ui";

export class TabsController extends Control {
  constructor(el, options) {
    super(el, options);
  }

  _resetTabs($el) {
    const $dlTabs = $el.closest("dl.tabs");
    let $tabs = $dlTabs.closest(".tabs:not(dl)");
    if ($tabs.length === 0) $tabs = $dlTabs.parent();
    if ($dlTabs.is(".is-vertical")) $tabs = $tabs.parent();
    const $content = $tabs.find("ul.tabs-content").first();

    $dlTabs.find("dd.is-active").removeClass("is-active");
    $content.find("> li.is-active").removeClass("is-active");
    const $tab = $el.closest("dd");

    // deferred so that other event handlers can process as if it wasn't already active
    setTimeout(() => $tab.addClass("is-active"), 0);
    return { $tab, $content };
  }
}

TabsController.prototype.events = {
  /**
   * @this {TabsController}
   */
  ".tabs dd:not(.is-active) a[data-tab]:not(.is-disabled) click"($el, e) {
    const tabs = this._resetTabs($el);
    e.preventDefault();
    tabs.$content
      .find(`[data-tab='${$el.data("tab")}']`)
      .first()
      .addClass("is-active");

    if ($el.closest(".tabs").data("updateLayout") !== "off") {
      updateLayout();
    }
  },
};
