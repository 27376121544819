import $ from "jquery";
import axios from "axios";
import Turbolinks from "turbolinks";
import { mountApp } from "../../vue";

import { Control } from "../../control";
import { usTimezones } from "./setup_utils";

export class PersonalTrainerSetupController extends Control {
  constructor(el, options) {
    super(el, options);
    const intl = getIntl();
    this.setup = mountApp("#setup", {
      bootcamps: this._app.data.bootcamps,
      experience: this._app.currentUser.experience,
      experienceYears: this._app.currentUser.experience_years,
      proSkills: this._app.data.pro_skills ?? [],
      proExperience: this._app.data.pro_experience ?? "",
      proExperienceYears: this._app.data.pro_experience_years ?? "",
      andelaInterest: (this._app.currentUser.andela_interest ?? "") + "",
      name: this._app.data.name ?? "",
      // Only used to accept input when it's unset and enrolled in bootcamps.
      company: "",
      phone: this._app.currentUser.phone || "",
      linkedinUrl: this._app.data.linkedin_url ?? "",
      bootcampInterest: this._app.currentUser["bootcamp_interest"],
      bootcampInterestSchools:
        this._app.data["bootcamp_interest_schools"] || [],
      careerPaths: this._app.currentUser["career_paths"] || [],
      weeklyNewsletter: (this._app.data.weekly_newsletter ?? "") + "",
      timezone: intl.timeZone,
      locale: intl.locale,
      baseLanguage: this._app.data.base_language ?? "",
      get isUSTimezone() {
        if (!this.timezone) return false;
        return !!usTimezones.some((tz) => this.timezone.startsWith(tz));
      },
      get recommendedBootcamps() {
        return this.bootcamps.filter((school) => {
          // if already selected, then show the school no matter what
          if (this.bootcampInterestSchools.includes(school.name)) {
            return true;
          }

          // Hide if the bootcamp requires a phone number and it's not provided.
          // Note that phone numbers are not validated because the format vary so much around the world.
          if (school.collect_phone_number && !this.phone.trim()) {
            return false;
          }

          // if the school doesn't support the career path
          if (
            this.careerPaths.length &&
            !this.careerPaths.some((cp) => (school.paths || []).includes(cp))
          ) {
            return false;
          }

          // items to consider as part of the include or exclude. Not that both include/exclude
          // are xor.
          const considerations = [
            this.experience,
            this.locale,
            this.timezone, // this way we can support both a whitelist and a blacklist
          ].join("|");

          if (school.exclude) {
            if (!!school.exclude.some((val) => considerations.includes(val))) {
              return false;
            }
          }

          if (school.include) {
            if (!school.include.some((val) => considerations.includes(val))) {
              return false;
            }
          }

          return true;
        });
      },
      selectAllSchools() {
        this.recommendedBootcamps.forEach((school) => {
          if (!this.bootcampInterestSchools.includes(school)) {
            this.bootcampInterestSchools.push(school.name);
          }
        });
      },
      toggleSchool(name) {
        if (this.bootcampInterestSchools.includes(name)) {
          this.bootcampInterestSchools = this.bootcampInterestSchools.filter(
            (n) => n !== name
          );
        } else {
          this.bootcampInterestSchools = [
            ...this.bootcampInterestSchools,
            name,
          ];
        }
      },
      isTopReq(index) {
        if (this.recommendedBootcamps.length === 1) return false;
        return (
          index === 0 || (index === 1 && this.recommendedBootcamps.length > 3)
        );
      },
    });
  }

  save() {
    // Note that the save button is disabled unless "experience" is set.
    if (!this.setup.experience) {
      this._app.alerts.fail("Please set your experience.");
      return;
    }

    const data = {
      timezone: this.setup.timezone,
      region_name: this.setup.regionName,
      weekly_newsletter: this.setup.weeklyNewsletter,
      experience: this.setup.experience,
      experience_years: Number.isNaN(this.setup.experienceYears)
        ? undefined
        : this.setup.experienceYears,
      pro_skills: this.setup.proSkills,
      pro_experience: this.setup.proExperience,
      pro_experience_years: this.setup.proExperienceYears,
      andela_interest: this.setup.andelaInterest,
      career_paths: this.setup.careerPaths,
      bootcamp_interest: this.setup.bootcampInterest,
      bootcamp_interest_schools: this.setup.bootcampInterestSchools,
      name: this.setup.name,
      phone: this.setup.phone,
      company: this.setup.company,
      linkedin_url: this.setup.linkedinUrl,
      base_language: this.setup.baseLanguage,
      languages: $(".icon-list a.is-active")
        .map((_, e) => $(e).data("language"))
        .toArray(),
    };
    axios.post(this._app.route("save"), data).then((response) => {
      const json = response.data;
      if (json.success) {
        this._app.alerts.success(
          '<i class="icon-moon-check"></i> Settings saved'
        );
        setTimeout(() => Turbolinks.visit(this._app.route("post_save")), 500);
      } else {
        this._app.alerts.fail("Failed to save settings. Please try again");
      }
    });
  }
}

PersonalTrainerSetupController.prototype.events = {
  /**
   * @this {PersonalTrainerSetupController}
   */
  ".js-save click"() {
    this.save();
  },

  /**
   * @this {PersonalTrainerSetupController}
   */
  ".icon-list a click"($el) {
    $el.toggleClass("is-active");
  },
};

function getIntl() {
  try {
    return Intl.DateTimeFormat().resolvedOptions();
  } catch {
    return {};
  }
}
