import $ from "jquery";

import { Control } from "../../control";

export class UsersRankingsController extends Control {
  constructor(el, options) {
    super(el, options);
    $(`[data-username='${this._app.currentUser.username}']`).addClass(
      "is-current-player"
    );
  }
}
