export const disableButton = (button) => {
  button.setAttribute("disabled", "");
  button.classList.add("cursor-not-allowed");
};

export const enableButton = (button) => {
  button.removeAttribute("disabled");
  button.classList.remove("cursor-not-allowed");
};

export const preserveScroll = () => {
  const scrollTop = document.scrollingElement.scrollTop;
  if (scrollTop > 0) {
    // Clear cache to prevent jumping when showing preview.
    Turbolinks.clearCache();
    Turbolinks.scrollTop = scrollTop;
  }
};

// HACK `sl-select`: Can't set array value from `value` attribute
// HACK `sl-select`: The value is a string after `Turbolinks.visit` for some reason
export const fixSelectValueArray = (el) => {
  if (Array.isArray(el.value) && el.value.length === 1) {
    el.value = el.value[0]?.split(",") || [];
  } else if (!el.value) {
    el.value = [];
  } else if (typeof el.value === "string") {
    el.value = el.value.split(",");
  }
};
