import $ from "jquery";
import axios from "axios";

import { Control } from "../control";
import { MarkdownDisplay } from "./markdown_display";
import { slideDown, slideUp } from "../animation";
import { mountApp } from "../vue";

export class CodeChallengeInfoAreas extends Control {
  constructor(el, options) {
    super(el, options);

    // This class is also created in SolutionGroup view where `vote_feedback` doesn't exist.
    if (document.querySelector("#vote_feedback")) {
      const data = this._app.data;
      this.voteFeedback = mountApp("#vote_feedback", {
        canVote: data.permissions?.vote,
        canAssessRank: data.permissions?.assess_rank,
        completedKata: data.completedKata,
        vote: data.vote,
        assessedRank: data.assessed_rank,
        setVote(vote) {
          this.vote = vote;
        },
        setAssessedRank(rank) {
          this.assessedRank = rank;
        },
      });
    }
  }

  // TODO: merge this functionality with that from the SocialButtons control
  postVote($el, vote) {
    $el
      .closest("ul")
      .addClass("is-disabled")
      .find("li.is-active")
      .removeClass("is-active");
    axios.post(this._app.route("challenge_vote"), { vote }).then((response) => {
      const json = response.data;
      $el.closest("ul").removeClass("is-disabled");
      if (json.success) {
        this._app.data.vote = json.vote;
        this.voteFeedback.setVote(this._app.data.vote);
      } else {
        this._app.alerts.fail(
          json.message || "Failed to cast vote, please try again later"
        );
      }
    });
  }

  isDiscussView() {
    return $("body").hasClass("discuss_view");
  }
}

CodeChallengeInfoAreas.prototype.events = {
  /**
   * @this {CodeChallengeInfoAreas}
   */
  "#kata-details-description sl-show"($el) {
    if (!this.descriptionMarkdown) {
      this.descriptionMarkdown = new MarkdownDisplay("#description", {
        _app: this._app,
        language: this._app.data.activeLanguage,
      });
      this.descriptionMarkdown.setMarkdown(this._app.data.description);
      this._controllers.push(this.descriptionMarkdown);
    }
  },

  /**
   * @this {CodeChallengeInfoAreas}
   */
  "#show_beta_about a click"() {
    slideUp($("#show_beta_about").get(0));
    slideDown($("#beta_about").get(0));
  },

  /**
   * @this {CodeChallengeInfoAreas}
   */
  "ul.vote-assessment li:not(.is-disabled) click"($el, e) {
    let vote = $el.data("value");
    if (
      vote === this._app.data.vote ||
      (vote === 1 && this._app.data.vote > 0)
    ) {
      vote = null;
    }

    this.postVote($el, vote);
  },

  /**
   * @this {CodeChallengeInfoAreas}
   */
  ".assessment-ranks .ranks li click"($el) {
    $el
      .closest(".assessment-ranks")
      .find("li.is-active")
      .removeClass("is-active");
    let rank = $el.data("rank");
    if (rank === this._app.data.assessed_rank) rank = null;

    axios.post(this._app.route("assess_rank"), { rank }).then((response) => {
      const json = response.data;
      if (json.success) {
        this._app.data.assessed_rank = rank;
        this.voteFeedback.setAssessedRank(this._app.data.assessed_rank);
      } else {
        this._app.alerts.fail(
          json.message || "Failed to cast vote, please try again later"
        );
      }
    });
  },
};
