import CodeMirror from "codemirror";

const Pos = CodeMirror.Pos;

function moveLines(cm, start, end, dist) {
  if (!dist || start > end) return 0;

  const from = cm.clipPos(Pos(start, 0)), to = cm.clipPos(Pos(end));
  const text = cm.getRange(from, to);

  if (start <= cm.firstLine())
    cm.replaceRange("", from, Pos(to.line + 1, 0));
  else
    cm.replaceRange("", Pos(from.line - 1), to);

  const target = from.line + dist;
  if (target <= cm.firstLine()) {
    cm.replaceRange(text + "\n", Pos(target, 0));
    return cm.firstLine() - from.line;
  }

  const targetPos = cm.clipPos(Pos(target - 1));
  cm.replaceRange("\n" + text, targetPos);
  return targetPos.line + 1 - from.line;
}

function moveSelectedLines(cm, dist) {
  const head = cm.getCursor("head");
  const anchor = cm.getCursor("anchor");
  cm.operation(() => {
    const moved = moveLines(cm, Math.min(head.line, anchor.line), Math.max(head.line, anchor.line), dist);
    cm.setSelection(Pos(anchor.line + moved, anchor.ch), Pos(head.line + moved, head.ch));
  });
}

CodeMirror.commands.moveLinesUp = cm => { moveSelectedLines(cm, -1); };
CodeMirror.commands.moveLinesDown = cm => { moveSelectedLines(cm, 1); };

CodeMirror.keyMap["default"]["Alt-Up"] = "moveLinesUp";
CodeMirror.keyMap["default"]["Alt-Down"] = "moveLinesDown";
