import { Controller } from "@stimulus/core";
import Turbolinks from "turbolinks";

export default class extends Controller {
  languageChanged(event) {
    const value = event.target.value;
    const url = new URL(window.location.href);
    if (value) {
      url.searchParams.set("language", value);
    } else {
      url.searchParams.delete("language");
    }
    Turbolinks.visit(url.toString());
  }
}
