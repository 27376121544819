import { Control } from "../../control";
import { CodeSnippetListItemController } from "../code_snippet_list_item_controller";

export class CodeSnippetsTranslationsController extends Control {
  constructor(el, options) {
    super(el, options);
    const listItem = new CodeSnippetListItemController(this.element, {
      _app: this._app,
    });
    this._controllers.push(listItem);
  }
}
