import { Controller } from "@stimulus/core";
import axios from "axios";
import Turbolinks from "turbolinks";

import { disableButton } from "./utils";

export default class extends Controller {
  static values = {
    id: String,
  };

  initialize() {
    this.solution = null;
  }

  disconnect() {
    this.solution = null;
  }

  /**
   * Invalidate the progress of specific solution project. Moderator only.
   * @param {MouseEvent} event
   * @returns {Promise<void>}
   */
  async invalidate(event) {
    disableButton(event.currentTarget);
    event.stopPropagation();
    await axios.post(
      `/api/v1/code-challenges/projects/${this.idValue}/invalidate`
    );
    Turbolinks.visit();
  }

  /**
   * Revalidate the progress of specific solution project. Moderator only.
   * @param {MouseEvent} event
   * @returns {Promise<void>}
   */
  async revalidate(event) {
    disableButton(event.currentTarget);
    event.stopPropagation();
    await axios.post(
      `/api/v1/code-challenges/projects/${this.idValue}/revalidate`
    );
    Turbolinks.visit();
  }

  /**
   * Remove vote for a beta challenge from the project. Moderator only.
   * @param {MouseEvent} event
   * @returns {Promise<void>}
   */
  async removeBetaVote(event) {
    disableButton(event.currentTarget);
    event.stopPropagation();
    await axios.delete(
      `/api/v1/code-challenges/projects/${this.idValue}/beta-vote`
    );
    Turbolinks.visit();
  }

  /**
   * Remove rank assessment from the project. Moderator only.
   * @param {MouseEvent} event
   * @returns {Promise<void>}
   */
  async removeRankAssessment(event) {
    disableButton(event.currentTarget);
    event.stopPropagation();
    await axios.delete(
      `/api/v1/code-challenges/projects/${this.idValue}/rank-assessment`
    );
    Turbolinks.visit();
  }

  /**
   * Show and hide solution.
   * @param {MouseEvent} event
   * @returns {void}
   */
  toggleSolution(event) {
    event.stopPropagation();

    const button = event.currentTarget;
    const infoRow = button.closest("tr");
    const toShow = infoRow.nextSibling;
    if (this.solution) {
      hide(this.solution);
      if (this.solution === toShow) {
        this.solution = null;
        return;
      }
    }

    this.solution = toShow;
    unhide(this.solution);
  }
}

const hide = (el) => el.classList.add("hidden");
const unhide = (el) => el.classList.remove("hidden");
