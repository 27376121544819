import $ from "jquery";
import axios from "axios";
import Turbolinks from "turbolinks";

import { Control } from "../../control";
import { rankColors } from "../../consts";
import { mountPromotedAdDisplay } from "../../mounts/promoted_ad_display";
import { InfiniteScroll } from "../infinite_scroll";
import { slideUp } from "../../animation";
import { visitRefreshed } from "../../turbolinks_utils";
import cable from "../../../channels/cable";
import UserStatsChannel from "../../../channels/user_stats";

export class UsersShowController extends Control {
  constructor(el, options) {
    super(el, options);
    this.infiniteScroll = new InfiniteScroll(".items-list", {
      _app: this._app,
    });
    this._controllers.push(this.infiniteScroll);
    mountPromotedAdDisplay("#partner-display", {
      type: "image",
      id: "user_profile_ad",
      user: this._app.currentUser,
    });

    // only try to show the chart if the information is available
    if (this._app.data.next_rank_color) {
      const container = $("#honor_chart");
      if (container.length && !container.attr("data-donut-chart")) {
        createDonutChart("#honor_chart", {
          value: Math.max(0, Math.min(99.9, this._app.data.rank_progress + 1)),
          radius: 200,
          thickness: 50,
          bg: "var(--color-donut-chart-bg)",
          color: rankColors[this._app.data.next_rank_color],
        });
        container.attr("data-donut-chart", true);
      }
    }

    if (this._app.data.stats_refreshing) {
      const channel = new UserStatsChannel({
        user_id: this._app.data.profile.id,
      });
      cable.subscribe(channel);
      channel.once("refresh", () => {
        visitRefreshed(window.location.pathname);
      });
      this._unregisters.push(() => channel.disconnect());

      // Check once and refresh or wait for the `refresh` event
      axios.get(this._app.route("check_stats")).then((response) => {
        if (response.data.ready) visitRefreshed(window.location.pathname);
      });
    }

    // Animate honor points breakdown bar chart
    $("[data-meter]").each((_, el) => {
      const $this = $(el);
      const $meter = $(
        '<div class="bg-[#d68e80] dark:bg-[#bb432c] absolute top-0 left-0 h-full inline-block transition-width ease-in-out duration-1500" style="width: 0">'
      );
      $this.prepend($meter);
      window.requestAnimationFrame(() => {
        $meter.width(`${$this.attr("data-meter")}%`);
      });
    });
  }
}

UsersShowController.prototype.events = {
  /**
   * @this {UsersShowController}
   */
  ".js-reload-action:not(.js-working) click"($el) {
    $el.text("Working...");
    $el.addClass("js-working");
    axios.post($el.data("href")).then((response) => {
      if (response.data.redirectPath) {
        Turbolinks.visit(response.data.redirectPath);
      } else {
        Turbolinks.visit();
      }
    });
  },

  /**
   * @this {UsersShowController}
   */
  "#tell_me_more_referrals click"($el) {
    document.getElementById("referral").style = "display: none !important;";
    $("#referrals").show();
  },

  /**
   * @this {UsersShowController}
   */
  "#toggle_relationship click"($el) {
    axios.post(this._app.route("toggle_relationship")).then((response) => {
      const json = response.data;
      if (json.success) {
        if (json.following) {
          $el.find("span").text("Unfollow");
          $el.addClass("is-red-text").removeClass("is-green-text");
        } else {
          $el.find("span").text("Follow");
          $el.addClass("is-green-text").removeClass("is-red-text");
        }
      }
    });
  },

  /**
   * @this {UsersShowController}
   */
  ".js-delete-collection click"($el) {
    this._app.confirmModal.show({
      titleHtml: "Are you sure you want to delete this collection?",
      messageHtml: "You will not be able to undo this operation. Are you sure?",
      confirmHtml: "Yes, delete it",
      confirm: () => {
        const route = this._app.route("collection", {
          id: $el.data("collectionId"),
        });
        axios.delete(route).then((_) => {
          slideUp($el.closest(".list-item-collection").get(0));
        });
      },
    });
  },
};

// Simplified version of https://github.com/simeydotme/donutty ISC
function createDonutChart(el, options) {
  const $wrapper = document.querySelector(el);
  const opts = Object.assign(
    {
      value: 50,
      radius: 50,
      thickness: 10,
      bg: "rgba(255,255,255,0.125)",
      color: "#333",
      transition: "all 1s cubic-bezier(0.57, 0.13, 0.18, 0.98)",
    },
    options || {}
  );
  const full = 2 * Math.PI * opts.radius;
  const fill = full - full * (opts.value / 100);
  const viewbox = 2 * opts.radius + opts.thickness;

  const $html = document.createDocumentFragment();
  const $svg = createSvg(viewbox);
  $svg.appendChild(createBg());
  $svg.appendChild(createDonut());
  $html.appendChild($svg);
  $wrapper.appendChild($html);
  // HACK Workaround `preserveAspectRatio` not working when set programatically.
  $wrapper.innerHTML = $wrapper.innerHTML;
  // Animate
  const $donut = $wrapper.querySelector(".donut-fill");
  $donut.style.transition = opts.transition;
  window.requestAnimationFrame(() => {
    setAttributes($donut, {
      "stroke-dasharray": full,
      "stroke-dashoffset": fill,
    });
    $donut.style.opacity = 1;
  });

  function createElementNSWithAttrs(name, attrs = {}) {
    return setAttributes(
      document.createElementNS("http://www.w3.org/2000/svg", name),
      attrs
    );
  }
  function setAttributes(el, attrs = {}) {
    for (const k of Object.keys(attrs)) el.setAttribute(k, attrs[k]);
    return el;
  }

  function createSvg(viewbox) {
    return createElementNSWithAttrs("svg", {
      class: "donut",
      xmlns: "http://www.w3.org/2000/svg",
      viewbox: `0 0 ${viewbox} ${viewbox}`,
      transform: "rotate(270)",
      preserveAspectRatio: "xMidYMid meet",
    });
  }

  function createBg() {
    return createElementNSWithAttrs("circle", {
      class: "donut-bg",
      cx: "50%",
      cy: "50%",
      r: opts.radius,
      fill: "transparent",
      stroke: opts.bg,
      "stroke-width": opts.thickness,
      "stroke-dasharray": full,
    });
  }

  function createDonut() {
    const $donut = createElementNSWithAttrs("circle", {
      class: "donut-fill",
      cx: "50%",
      cy: "50%",
      r: opts.radius,
      fill: "transparent",
      stroke: opts.color,
      "stroke-width": opts.thickness,
      "stroke-dashoffset": full,
      "stroke-dasharray": full,
    });
    $donut.style.opacity = 0;
    return $donut;
  }
}
