import $ from "jquery";

export const shareTwitter = (status) => {
  shareWin(
    `https://twitter.com/intent/tweet?text=${encodeURIComponent(status)}`
  );
};

export const shareFacebook = (options = {}) => {
  const imageUrl = encodeURIComponent(
    options.imageUrl || $('meta[property="og:image"]').attr("content")
  );
  const url = encodeURIComponent(
    options.url || $('meta[property="og:url"]').attr("content")
  );
  const title = encodeURIComponent(
    options.title || $('meta[property="og:title"]').attr("content")
  );
  const body = encodeURIComponent(
    options.body || $('meta[property="og:description"]').attr("content")
  );

  shareWin(
    `https://www.facebook.com/sharer/sharer.php?s=100&p[url]=${url}&p[images][0]=${imageUrl}&p[title]=${title}&p[summary]=${body}`,
    options
  );
};

const shareWin = (url, options = {}) => {
  const w = options.width || 550;
  const h = options.height || 450;
  const l = options.left || Math.round(screen.width / 2 - w / 2);
  const t = options.top || Math.round(screen.height / 2 - h / 2);

  window.open(
    url,
    "_blank",
    `left=${l},top=${t},width=${w},height=${h},personalbar=0,toolbar=0,scrollbars=1,resizable=1`
  );
};
