import $ from "jquery";
import axios from "axios";

import { Control } from "../control";
import {
  autoloadViews,
  highlightCodeBlocks,
  updateLayout,
  initTooltips,
} from "../ui";
import { automountComments } from "../mounts/comments";

export class InfiniteScroll extends Control {
  constructor(el, options) {
    super(el, options);
    this.waypoint();
    this.checkWaypoint();
  }

  checkWaypoint() {
    _checkWaypoint(this.$waypoint, (inView) => {
      if (inView) setTimeout(() => this.loadNext(), 0);
    });
  }

  waypoint() {
    this.$waypoint = this.element.find(this.options.waypointSelector);
  }

  // replaces current contents with a fresh load
  replaceLoad(url) {
    axios.get(url, { responseType: "text" }).then((response) => {
      this.element.html(response.data);
      this._updateUi();
      this.waypoint();
    });
  }

  loadNext() {
    const page = this.$waypoint.data("page");
    const uri = new URL(document.location);
    uri.searchParams.set("page", page);
    axios.get(uri.href, { responseType: "text" }).then((response) => {
      this.$waypoint.replaceWith(response.data);
      window.requestAnimationFrame(() => {
        this.waypoint();
        this._updateUi();

        if (this.options.loaded) {
          this.options.loaded(this);
        }
        $("#app").trigger("infinitescrollcontent");
      });
    });
  }

  _updateUi() {
    highlightCodeBlocks();
    autoloadViews(this._app);
    automountComments(this._app);
    updateLayout();
    initTooltips();
  }
}

InfiniteScroll.prototype.events = {
  /**
   * @this {InfiniteScroll}
   */
  "a.js-infinite-button click"($el) {
    this.$waypoint = $el;
    $el.html("Loading...").addClass("is-disabled");
    this.loadNext();
  },

  /**
   * @this {InfiniteScroll}
   */
  ".js-infinite-button a click"($el) {
    this.$waypoint = $el.closest(".js-infinite-button");
    $el.html("Loading...").addClass("is-disabled");
    this.loadNext();
  },

  /**
   * @this {InfiniteScroll}
   */
  "window scroll"() {
    this.checkWaypoint();
  },
};

InfiniteScroll.defaults = {
  waypointSelector: ".js-infinite-marker",
};

// Determines if waypoint view-state has changed and calls the callback if it has.
// Use in conjuction with a window scroll event
function _checkWaypoint($node, cb) {
  if ($node.height() && isInViewport($node.get(0))) {
    if (!$node.data("waypoint-in-view")) {
      cb(true, $node);
      $node.data("waypoint-in-view", true);
    }
  } else if ($node.data("waypoint-in-view")) {
    cb(false, $node);
    $node.data("waypoint-in-view", false);
  }
}

const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.bottom > 0 &&
    rect.right > 0 &&
    rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
    rect.top < (window.innerHeight || document.documentElement.clientHeight)
  );
};
