import $ from "jquery";

import { Control } from "../control";
import { sampleArray } from "../utils";
import { wait } from "../timer_utils";
import { fadeIn, fadeOut, slideDown, slideUp } from "../animation";

export class AlertMessageBox extends Control {
  constructor(el, options) {
    super(el, options);
    this.$box = this.element.find(".alert-box");
    // Holds the reference to object containing animation frame id
    this.currentAnimation = null;
    if (this.$box.length === 0) {
      if (this.element.is(".alert-box")) {
        this.$box = this.element;
      } else {
        this.$box = $("<div></div>")
          .addClass("alert-box")
          .appendTo(this.element);
      }
    }

    this.$msgContainer = this.$box.find(".message");
    if (this.$msgContainer.length === 0) {
      this.$msgContainer = this.$box;
    }
  }

  container() {
    if (this.options.boxContainer) {
      return this.$box;
    } else {
      this.$box.show(); // need to do this since the close button will sometimes hide it
      return this.element;
    }
  }

  sanitize(msg, state) {
    if (this.options.sanitize) {
      return this.options.sanitize(msg, state);
    } else {
      return msg;
    }
  }

  pickMessage(type) {
    const msg = this.options[`${type}Message`];
    if (Array.isArray(msg)) return sampleArray(msg);
    if (typeof msg === "function") return msg(this);
    return msg;
  }

  showResult(success, msg, duration) {
    if (success) {
      this.success(msg, duration);
    } else {
      this.fail(msg, duration);
    }
  }

  success(msg, duration) {
    msg = this.sanitize(msg, "success");

    this.$box
      .removeClass(this.options.failClassName)
      .removeClass(this.options.workingClassName)
      .removeClass(this.options.warnClassName)
      .addClass(this.options.successClassName);

    this.show(msg || this.pickMessage("success"), duration);
  }

  fail(msg, duration) {
    msg = this.sanitize(msg, "fail");
    this.$box
      .addClass(this.options.failClassName)
      .removeClass(this.options.successClassName)
      .removeClass(this.options.warnClassName)
      .removeClass(this.options.workingClassName);

    this.show(msg || this.pickMessage("fail"), duration);
  }

  warn(msg, duration) {
    msg = this.sanitize(msg, "fail");
    this.$box
      .addClass(this.options.warnClassName)
      .removeClass(this.options.failClassName)
      .removeClass(this.options.successClassName)
      .removeClass(this.options.workingClassName);

    this.show(msg, duration);
  }

  working(msg, duration) {
    msg = this.sanitize(msg, "working");

    this.$box
      .addClass(this.options.workingClassName)
      .removeClass(this.options.successClassName)
      .removeClass(this.options.failClassName)
      .removeClass(this.options.warnClassName);

    this.show(msg, duration);
  }

  show(msg, duration) {
    this.update(msg, duration);

    this.cancelWaitHide();

    if (!msg) return this.hide();

    const $el = this.container();
    if (duration === 0) {
      $el.show();
      return;
    }

    $el.hide();
    const finish = () => this.waitHide(duration);
    this.stopCurrentAnimation();
    this.currentAnimation = this.options.useSlide
      ? slideDown($el.get(0), { finish })
      : fadeIn($el.get(0), { finish });
  }

  hide() {
    const $el = this.container();
    this.stopCurrentAnimation();
    this.currentAnimation = this.options.useSlide
      ? slideUp($el.get(0))
      : fadeOut($el.get(0));
  }

  cancelWaitHide() {
    if (this._waitHide != null) this._waitHide.abort();
  }

  waitHide(duration) {
    if (duration > 0 || (duration !== 0 && this.options.displayDuration > 0)) {
      this.cancelWaitHide();
      this._waitHide = wait(duration || this.options.displayDuration, () =>
        this.hide()
      );
    }
  }

  update(msg, duration) {
    if (!msg) return;

    this.element.show();
    if (this.options.showClose) {
      msg += "<a class='close'>&times;</a>";
    }

    this.$msgContainer.html(msg);
    if (duration === 0) return;

    const $el = this.container();
    this.stopCurrentAnimation();
    this.currentAnimation = this.options.useSlide
      ? slideDown($el.get(0))
      : fadeIn($el.get(0));
  }

  stopCurrentAnimation() {
    if (this.currentAnimation) {
      cancelAnimationFrame(this.currentAnimation.id);
      this.currentAnimation = null;
    }
  }
}

AlertMessageBox.prototype.events = {
  /**
   * @this {AlertMessageBox}
   */
  "a.close click"(el, e) {
    e.preventDefault();
    e.stopPropagation();
    this.hide();
  },
};

AlertMessageBox.defaults = {
  displayDuration: 5000,
  successClassName: "success",
  warnClassName: "warning",
  failClassName: "alert",
  workingClassName: "working",
  successMessage: "",
  failMessage: "",
  showClose: false,
  boxContainer: true,
  // Use slideDown/slideUp instead of fadeIn/fadeOut
  useSlide: false,
  sanitize: null,
};
