import axios from "axios";
import { serialize } from "@shoelace-style/shoelace/dist/utilities/form.js";

import { mountApp, mountAppWithManualUnmount } from "../vue";
import { highlightCodeBlocks } from "../ui";
import { MarkdownEditor } from "../controls";
import { markdownWithLanguage } from "../controls/markdown_display";
import { notify } from "../utils";

export const mountComments = (sel, props) => {
  const labelFilter =
    window.location.hash.match(/^#label-(issue|enhancement|question)$/)?.[1] ||
    "all";
  // TODO Make this conditional. Doesn't make sense for solutions list page.
  const activeId = window.location.hash.match(/^#([a-f\d]{24})$/)?.[1];

  return mountApp(sel, {
    loading: true,
    activeId,
    labelFilter,
    // Defaulting to show resolved comments for now to make links from dashboard to work.
    // We can instead switch to showing resolved comment when the comment with activeId is resolved.
    // Need to sync the checkbox in that case.
    stateFilter: "all",
    ...props,
    onMounted() {
      this.emitCountsUpdatedEvent();
    },
    showResolved() {
      this.stateFilter = "all";
      this.emitCountsUpdatedEvent();
    },
    hideResolved() {
      this.stateFilter = "Open";
      this.emitCountsUpdatedEvent();
    },
    setLabelFilter(label) {
      this.labelFilter = label;
    },
    setStateFilter(state) {
      this.stateFilter = state;
    },
    get filteredComments() {
      return this.comments.filter((c) => {
        return (
          (this.labelFilter === "all" || this.labelFilter === c.label) &&
          (this.stateFilter === "all" || this.stateFilter === c.state_summary)
        );
      });
    },
    getActiveCounts() {
      return (this.stateFilter === "all") ? props.kindsCount.all : props.kindsCount.unresolved;
    },
    emitCountsUpdatedEvent() {
      const control = document.getElementById("view-control");
      if (!control) return;

      control.dispatchEvent(
        new CustomEvent("comments:counts-updated", {
          detail: this.getActiveCounts(),
        })
      );
    },
    onCommentCreated(event) {
      const comment = event.detail;
      this.comments.unshift(comment);
      // Update the filter to show the posted comment.
      if (this.labelFilter !== "all" && this.labelFilter !== comment.label) {
        this.labelFilter = comment.label;
      }
      this.emitCountsUpdatedEvent();
    },
    onCommentRemoved(event) {
      const i = this.comments.findIndex((c) => c.id === event.detail);
      if (i !== -1) {
        this.comments.splice(i, 1);
        this.emitCountsUpdatedEvent();
      }
    },
    onCommentUpdated(event) {
      const comment = event.detail;
      const i = this.comments.findIndex((c) => c.id === comment.id);
      if (i !== -1) {
        Object.assign(this.comments[i], comment);
        this.emitCountsUpdatedEvent();
      }
    },
    renderMarkdown(markdown, renderStyles = true) {
      return markdownWithLanguage(markdown, "", renderStyles);
    },
    NewComment,
    CommentItem,
    CommentEditor,
  });
};

export const getUserVotes = async (url) => {
  if (!url) return null;

  try {
    const response = await axios.get(url);
    const json = response.data;
    if (json.success) return json.votes;
    return null;
  } catch (err) {
    console.error("Request to get user's comment votes failed", err);
    return null;
  }
};

export const automountComments = async (app) => {
  const commentsComponents = document.querySelectorAll(
    "[v-scope].comments-list-component"
  );
  const pairs = await Promise.all(
    [...commentsComponents].map(async (comments) => {
      const viewData = comments.dataset.viewData;
      const data = viewData ? JSON.parse(viewData) : {};
      // Comments on solutions page doesn't have user's votes included in `app.data` and we need to fetch them separately.
      // TODO Try removing cache for comments and fetch data for the current user and include the votes in view_data.
      //      This fixes other various issues like not showing own spoiler comments.
      data.votes =
        (await getUserVotes(data.userVotesUrl)) || app.data.votes || {};
      return [comments, data];
    })
  );

  const mounts = [];
  for (const [comments, data] of pairs) {
    mounts.push(
      mountComments(comments, {
        _app: app,
        currentUser: app.currentUser,
        appData: app.data,
        getRoute: app.route.bind(app),
        alerts: app.alerts,
        ...data,
      })
    );
  }
  return mounts;
};

const NewComment = (props) => {
  return {
    $template: "#comment-new-template",
    active: false,
    user: null,
    ...props,
    // TODO Duplicated method
    get blockedNew() {
      if (!this.appData.created_by_id) return false;
      if (!this.currentUser?.blocked_by_user_ids) return false;

      return this.currentUser.blocked_by_user_ids.includes(
        this.appData.created_by_id
      );
    },
    // TODO Duplicated method
    get guestUserView() {
      const currentUser = this.currentUser;
      return !currentUser.id || currentUser.guest;
    },

    onCanceled() {
      this.active = false;
    },
    onCreated(event) {
      this.active = false;
      // Send to `CommentsList`
      this.$refs.self.dispatchEvent(
        new CustomEvent("comment:created", {
          detail: event.detail,
        })
      );
    },
  };
};

const CommentEditor = (props) => {
  return {
    $template: "#comment-editor-template",
    id: "",
    active: false,
    isPosting: false,
    user: null,
    // v-model
    markdown: "",
    // v-model
    markResolved: false,
    masked: false,
    nest_level: 0,
    parent: null,
    label: "",
    label_text: "No Label",
    ...props,
    editor: null,
    initEditor() {
      // init editor
      if (!this.guestUserView && !this.editor) {
        this.editor = new MarkdownEditor(this.$refs.markdown, {
          _app: this._app,
          styleActiveLine: false,
          editorHeight: 135,
          alwaysVisible: true,
        });
      }
      if (this.editor) {
        setTimeout(() => this.editor.focus(), 100);
      }
      this.active = true;
      this.$refs.markdown.scrollIntoView({
        block: "center",
        inline: "nearest",
      });
    },
    // TODO Duplicated method
    get blockedNew() {
      if (!this.appData.created_by_id) return false;
      if (!this.currentUser?.blocked_by_user_ids) return false;

      return this.currentUser.blocked_by_user_ids.includes(
        this.appData.created_by_id
      );
    },
    // TODO Duplicated method
    get guestUserView() {
      const currentUser = this.currentUser;
      return !currentUser.id || currentUser.guest;
    },
    get adminView() {
      return this.currentUser?.role === "admin";
    },
    get labelsAllowed() {
      return this.nest_level === 0 && this.allowedLabels?.length > 0;
    },
    get postLabel() {
      if (this.id) return "Update";
      if (this.nest_level === 1) {
        return "Reply";
      }
      return "Post";
    },
    get labelText() {
      return this.parent?.label_text?.toLowerCase();
    },
    canResolve() {
      if (!this.currentUser) return false;
      if (!this.parent?.is_resolvable) return false;
      return (
        this.parent?.allowed_resolver_ids?.includes(this.currentUser.id) ||
        this.adminView ||
        this.currentUser.can_resolve_comments
      );
    },

    cancel() {
      this.active = false;
      this.$refs.self.dispatchEvent(new CustomEvent("edit:canceled"));
    },
    post() {
      if (this.markResolved) {
        this.markCommentClosed();
      } else {
        this.saveComment();
      }
    },
    commentData() {
      return {
        markdown: this.markdown,
        masked: this.masked,
        label:
          this.$refs.dropdown?.querySelector("dd.is-active")?.dataset?.value,
        // HACK Tell the server to use the same spoiler option as when the page was rendered.
        hideSpoilers: this.hideSpoilers,
      };
    },
    markCommentClosed() {
      if (this.isPosting) return;

      // when a parent comment is marked closed, we call close on the parent comment, not the reply comment being created for it
      const route =
        this.getRoute(this.commentUrl, { comment_id: this.parentCommentId }) +
        "/close";

      if (this.markdown || this.adminView) {
        this.addNewComment(route, this.commentData());
      }
    },
    saveComment() {
      if (!this.markdown) return;
      if (this.isPosting) return;

      const data = this.commentData();
      if (this.parentCommentId) {
        data.parent_comment_id = this.parentCommentId;
      }

      const route = this.getRoute(this.commentUrl, {
        comment_id: this.id || "",
      });
      if (this.id) {
        this.updateComment(route, data);
      } else {
        this.addNewComment(route, data);
      }
    },
    updateComment(route, data) {
      this.isPosting = true;
      axios
        .put(route, data)
        .then((response) => {
          const json = response.data;
          if (json.success) {
            this.$refs.self.dispatchEvent(
              new CustomEvent("edit:updated", { detail: json.comment })
            );
          }
          this.isPosting = false;
        })
        .catch((error) => {
          this.isPosting = false;
          handleCommentErrorResponse.call(this, error);
        });
    },
    addNewComment(route, data) {
      this.isPosting = true;
      axios
        .post(route, data)
        .then((response) => {
          const json = response.data;
          if (json.success) {
            if (!this.parentCommentId) {
              // new comment created
              this.$refs.self.dispatchEvent(
                new CustomEvent("edit:created", {
                  detail: json.comment,
                })
              );
            } else {
              // reply comment created
              this.$refs.self.dispatchEvent(
                new CustomEvent("edit:replied", {
                  detail: json.comment,
                })
              );
            }
          } else {
            handleCommentErrorResponse.call(this, json)
          }
          this.isPosting = false;
        })
        .catch((error) => {
          this.isPosting = false;
          handleCommentErrorResponse.call(this, error);
        });
    },
  };
};

const CommentItem = (props) => {
  const expand = props.comments?.find((c) => c.id === props.activeId) != null;
  return {
    $template: "#comment-item-template",
    // Switch to the editor view
    editing: false,
    // Show and focus reply editor
    replying: false,
    parent: null,
    show_solutions: false,
    solutions: null,
    fetchSolutionsError: "",
    ...props,
    collapsed: props.collapsed && !expand,
    // Making vote request
    voting: false,
    showAbusive: false,
    CompareSolution,

    toggleCommenterSolutions() {
      if (this.show_solutions) {
        this.show_solutions = false;
        this.fetchSolutionsError = "";
      } else {
        if (!this.solutions) {
          axios
            .post(this.getRoute("user_solution", { userId: this.user_id }))
            .then((response) => {
              const json = response.data;
              this.solutions = [json];
              this.show_solutions = true;
              setTimeout(() => highlightCodeBlocks(), 50);
            })
            .catch((error) => {
              this.fetchSolutionsError = "Failed to get solutions";
              this.show_solutions = true;
            });
        } else {
          this.fetchSolutionsError = "";
          this.show_solutions = true;
          setTimeout(() => highlightCodeBlocks(), 50);
        }
      }
    },
    flagComment() {
      const route =
        this.getRoute(this.commentUrl, { comment_id: this.id }) + "/flag";
      axios
        .post(route, { type: "mask" })
        .then((response) => {
          const json = response.data;
          if (json.success) {
            this.alerts.success(`Successfully ${json.verb} comment`);

            if (json.comment) {
              Object.assign(this, json.comment);
            } else {
              this.masked = json.flagged;
            }
          } else {
            this.alerts.fail(
              json.reason ||
                json.message ||
                "Was unable to flag comment. Try again later."
            );
          }
        })
        .catch((error) => {
          handleCommentErrorResponse.call(this, error);
        });
      //
    },
    // View spoiler contents without unflagging. Admin only.
    // REVIEW Is this used? Don't remember seeing this.
    showSpoiler() {
      const route =
        this.getRoute(this.commentUrl, {
          comment_id: this.id,
        }) + "/content";

      this.markdown = "<i>Loading...</i>";
      axios
        .get(route)
        .then((response) => {
          const json = response.data;
          this.markdown = json.markdown;
          this.markdown_html = json.markdownHtml;
        })
        .catch((error) => {
          handleCommentErrorResponse.call(this, error);
        });
    },
    get masked_flag_title() {
      if (this.masked) {
        return "Un-flag this comment as having spoilers within it.";
      } else {
        return "Flag this comment as a spoiler. It will be hidden from users who have not yet solved the kata.";
      }
    },
    get isBlocked() {
      const blockedIds = this.currentUser?.blocked_user_ids || [];
      return blockedIds.includes(this.user_id);
    },
    get noMarkdown() {
      return !(this.masked || this.markdown);
    },
    get isAbusive() {
      return !!this.abuse_kind;
    },
    unhideAbusive() {
      this.showAbusive = true;
    },
    get abuseKindText() {
      switch (this.abuse_kind) {
        case "spam":
        case "abusive":
          return this.abuse_kind;
        case "other":
        default:
          return "harmful";
      }
    },
    get canReport() {
      return !this.ownComment && this.currentUser?.can_report_comments;
    },
    // true if content is hidden from user
    get hideContent() {
      return this.masked && !this.markdown;
    },
    get guestUserView() {
      const currentUser = this.currentUser;
      return !currentUser.id || currentUser.guest;
    },
    get adminView() {
      return this.currentUser?.role === "admin";
    },
    get moderatorView() {
      return (
        this.currentUser?.role === "admin" ||
        this.currentUser?.role === "moderator"
      );
    },
    get ownComment() {
      return this.user_id === this.currentUser.id;
    },
    get blockedNew() {
      if (!this.appData.created_by_id) return false;
      if (!this.currentUser?.blocked_by_user_ids) return false;

      return this.currentUser.blocked_by_user_ids.includes(
        this.appData.created_by_id
      );
    },
    get hasReplies() {
      return this.comments?.length > 0;
    },
    // Don't show reply button for replies.
    get replyAllowed() {
      return (
        !this.blockedNew &&
        this.nest_level === 0 &&
        (!this.hideContent ||
          this.adminView ||
          this.ownComment ||
          this.hasReplies)
      );
    },
    get canEdit() {
      return (this.ownComment && !this.hasReplies) || this.adminView;
    },

    get canRemove() {
      // for now you can only remove top level comments since nested comment removal causes data issues
      const hasPermission = this.nest_level === 0 && this.canEdit;
      return !this.type && (hasPermission || this.moderatorView);
    },
    get canRemoveThread() {
      return this.currentUser?.role === "admin" || this.currentUser?.role === "moderator"
    },

    get labelTagClass() {
      if (this.is_resolvable) {
        switch (this.label) {
          case "issue":
            return "issue-label";

          case "question":
            return "question-label";

          case "enhancement":
          default:
            return "bg-contrast-300";
        }
      } else {
        return "label-resolved";
      }
    },

    expand() {
      this.collapsed = false;
    },
    collapse() {
      this.collapsed = true;
    },
    reply() {
      this.collapsed = false;
      this.replying = true;
    },

    // Context to pass to child comments
    toChild() {
      return {
        id: this.id,
        label_text: this.label_text,
        allowed_resolver_ids: this.allowed_resolver_ids,
        is_resolvable: this.is_resolvable,
        comments: this.comments,
      };
    },

    onUpdated(event) {
      Object.assign(this, event.detail);
      this.editing = false;
    },

    onReplied(event) {
      // Response contains the entire comment
      Object.assign(this, event.detail);
      this.replying = false;
      this.$refs.self.dispatchEvent(
        new CustomEvent("comment:updated", { detail: event.detail })
      );
    },

    remove(removeReplies=false) {
      let message = "Are you sure you want to remove this comment?";
      if (removeReplies) {
        message += " This will also remove all replies.";
      }

      this._app.confirmModal.show({
        messageHtml: message,
        confirm: () => {
          axios
            .delete(this.getRoute(this.commentUrl, { comment_id: this.id }, { remove_replies: removeReplies }))
            .then((response) => {
              const json = response.data;

              if (json.success) {
                if (this.nest_level > 0) {
                  const i = this.parent.comments.findIndex(
                    (c) => c.id === this.id
                  );
                  this.parent.comments.splice(i, 1);
                } else {
                  this.$refs.self.dispatchEvent(
                    new CustomEvent("comment:removed", { detail: this.id })
                  );
                }
              }
            })
            .catch((error) => {
              handleCommentErrorResponse.call(this, error);
            });
        },
      });
    },

    report() {
      const route =
        this.getRoute(this.commentUrl, {
          comment_id: this.id,
        }) + "/report";

      const dialog = Object.assign(document.createElement("sl-dialog"), {
        label: "Report Comment",
        innerHTML: `
  <form class="-mt-4" action="${route}" method="post" @submit="onSubmit" v-scope>
    <div v-if="loading" class="text-center">
      <sl-spinner class="text-lg"></sl-spinner>
    </div>
    <div v-show="!loading">
      <sl-radio-group label="Kind" name="kind" v-sl-model="kind" required>
        <sl-radio value="spam">As spam</sl-radio>
        <sl-radio value="abusive">As abusive or harassing</sl-radio>
        <sl-radio value="other">As harmful in other way</sl-radio>
      </sl-radio-group>

      <sl-textarea class="mt-4" name="details" label="Additional Information"
        :placeholder="detailsPlaceholder"
        :required="detailsRequired"
        v-sl-model="details"
      ></sl-textarea>

      <div class="mt-4 text-right">
        <sl-button type="submit" variant="default" outline>Report</sl-button>
      </div>
    </div>
  </form>
    `,
      });
      document.body.append(dialog);
      const [state, unmount] = mountAppWithManualUnmount(dialog, {
        loading: true,
        kind: "",
        details: "",
        get detailsRequired() {
          return ["abusive", "other"].includes(this.kind);
        },

        get detailsPlaceholder() {
          return `Please explain${this.kind === "spam" ? " (optional)" : ""}`;
        },

        onSubmit: (e) => {
          e.preventDefault();
          const submitButton = dialog.querySelector('sl-button[type="submit"]');
          submitButton.loading = true;
          submitButton.disabled = true;
          axios
            .post(e.target.action, serialize(e.target))
            .then((response) => {
              const json = response.data;
              notify("Reported!", { variant: "success", duration: 2000 });
              submitButton.loading = false;
              dialog.hide();
              Object.assign(this, json.comment);
            })
            .catch((err) => {
              notify("Failed to report. Please try again.", {
                variant: "danger",
                duration: 2000,
              });
              console.error(err);
              submitButton.loading = false;
              submitButton.disabled = false;
            });
        },
      });
      dialog.addEventListener("sl-after-hide", () => {
        unmount();
        dialog.remove();
      });
      dialog.show();

      // Fill the form with previous report if any.
      // TODO Maybe just disable the form instead of letting them update.
      axios
        .get(route)
        .then((response) => {
          const json = response.data;
          state.kind = json.kind;
          state.details = json.details;
          state.loading = false;
        })
        .catch((err) => {
          console.error(err.response ? err.response : err);
        });
    },

    vote(value) {
      if (this.voting) return;

      this.voting = true;
      axios
        .post(this._app.route(this.voteUrl, { refId: this.id, value }))
        .then((response) => {
          const json = response.data;
          if (json.success) {
            this.votes[this.id] = value;
            this.votes_score = json.score;
          }
          this.voting = false;
        });
    },
  };
};

const CompareSolution = (props) => {
  return {
    $template: "#compare-solution-template",
    // Set default `solution` to `null` because surprising binding happens if we use this component
    // like `<div v-for="solution in solutions" v-scope="CompareSolution(solution)"></div>`, and
    // `solution.solution` is not defined.
    // `solution` inside this component references the `solution` in `v-for="solution in solutions"`!
    // Most likely a petite-vue bug.
    solution: null,
    denied: false,
    ...props,
  };
};

function handleCommentErrorResponse(error) {
  if (error.response) {
    if (error.response.status === 401) {
      this._app.alerts.fail(
        "Unauthorized: " +
          error.response.data.reason.replace(/^User .+:/, "").trim()
      );
    } else {
      this._app.alerts.fail(error.response.data.reason);
    }
  } else if (error.request) {
    this._app.alerts.fail("No response was received. Please try again.");
  } else {
    this._app.alerts.fail(error.message || "Unknown Error");
  }
}
