import { Controller } from "@stimulus/core";
import axios from "axios";
import swal from "sweetalert";

import { disableButton } from "./utils";

export default class extends Controller {
  static targets = ["tags"];
  static values = {
    id: String,
  };

  get tags() {
    return this.tagsTarget.value;
  }

  async addTags(event) {
    disableButton(event.currentTarget);
    await updateItemTags(this.idValue, this.tags, false);
  }

  async removeTags(event) {
    disableButton(event.currentTarget);
    await updateItemTags(this.idValue, this.tags, true);
  }
}

const updateItemTags = async (id, tags, remove) => {
  try {
    const res = await axios.post(`/api/v1/collections/${id}/item-tags`, {
      op: remove ? "remove" : "add",
      tags,
    });
    const json = res.data;
    if (json.success) {
      await swal({
        icon: "info",
        text: "Successfully enqueued a job. Try refreshing the page in a bit.",
      });
      return;
    }
  } catch (err) {
    console.error(err.message);
  }

  await swal({
    icon: "error",
    text: "Something went wrong. Check the console for any error messages, and let the admin know if the problem persists.",
  });
};
