import $ from "jquery";
import axios from "axios";
import Turbolinks from "turbolinks";
import * as Sentry from "@sentry/browser";

import CodeMirror from "../../../vendor/codemirror";

import { successMessages } from "../../consts";
import { mountPromotedAdDisplay } from "../../mounts/promoted_ad_display";
import {
  sampleArray,
  replaceHistoryState,
  pushHistoryState,
} from "../../utils";
import { Ide } from "../ide";
import { CodeEditor } from "../code_editor";
import { MarkdownDisplay } from "../markdown_display";
import { turbolinksRender } from "../../turbolinks_utils";
import { mountApp } from "../../vue";
import { highlightCodeBlocks } from "../../ui";

export class CodeChallengesPlayController extends Ide {
  constructor(el, options) {
    super(el, options);
    this.languages = {};

    if (window.gtag) {
      gtag("event", "challenge_train", {
        event_label: this._app.data.challengeName,
        event_category: this._app.data.activeLanguage,
      });
    }

    const adDisplay = mountPromotedAdDisplay("#partner-display", {
      type: "image",
      id: "train_ad",
      contrast: false,
      red: true,
      user: this._app.currentUser,
    });

    this.player = mountApp("#cc_play_view", {
      loading: true,
      language: this._app.data.languageInfo,
      completed: this._app.data.completedKata,
      progress: this._app.data.progress || {},
      _activeTab: "description",
      get activeTab() {
        return this._activeTab;
      },
      set activeTab(val) {
        // update the ad to a new one if switching back to the description tab
        if (this._activeTab !== "description" && val === "description") {
          adDisplay.refresh();
        }
        this._activeTab = val;
      },
      previousSolutions: this._app.data.previousSolutions,
      setLanguage(info) {
        this.language = info;
      },
      get activeLabel() {
        const vs = this.language?.languageVersions;
        if (!vs) return;

        const active = vs.find((lv) => this.language.activeVersion === lv.id);
        if (active?.label) return active.label;
        const last = vs[vs.length - 1];
        return last?.label;
      },
      onMounted() {
        // Syntax hightlight past solutions after some delay.
        setTimeout(() => highlightCodeBlocks(), 1000);
      },
      // Toggle between instructions and output.
      toggleTabs() {
        this.activeTab =
          this.activeTab === "description" ? "output" : "description";
      },
    });

    this.play();
  }

  get language() {
    return this.player.language;
  }

  set language(v) {
    this.player.language = v;
  }

  setLayout(layout) {
    switch (layout) {
      case "max":
        $("body").addClass("maximized").addClass("no-sidenav");
        break;

      case "min":
        $("body").removeClass("maximized").removeClass("no-sidenav");
        break;
    }

    this.layout = layout;
    setTimeout(() => $(window).trigger("resize"), 100);
  }

  validate(data = {}) {
    this.showOutputWaiting();
    this.fixture.messages.hide();
    this.run(
      Object.assign(
        {
          languageVersion: this.language.activeVersion,
          relayId: this.language.solutionId,
          ciphered: ["setup"],
        },
        data
      )
    );
  }

  attempt() {
    if (!this.getCode()) {
      this.editor.messages.fail(this.editor.emptyCodeMsg());
      return;
    }

    $("#attempt_btn").addClass("is-disabled");

    this.attempting = true;
    this.validate({
      ciphered: ["setup", "fixture"],
      fixture: this.language.fixture,
    });
  }

  handleResponse() {
    (this.fixture || this.editor).messages.hide();
    const runner = this.outputPanel.runner;

    const attempting = this.attempting;
    this.attempting = false;

    this.editor.messages.hide();

    let completed = false;
    let editor = null;

    if (runner.response && runner.response.type === "execution success") {
      completed = runner.response.result.completed;

      axios
        .post(
          this._app.route("notify", { solutionId: this.language.solutionId }),
          {
            token: runner.response.token,
            testFramework: runner.request.testFramework,
            code: runner.request.code,
            fixture: runner.request.fixture,
            languageVersion: this.language.activeVersion,
          }
        )
        .then(
          (response) => {
            const json = response.data;
            if (!json.success && json.reason === "Account restricted") {
              this.editor.messages.fail(
                `You cannot submit your solution because your account is restricted.`
              );
              return;
            }

            if (completed && json.success && attempting) {
              this.editor.markClean();
              if (this._app.data.published) {
                this.editor.messages.working(
                  `<i class='icon-moon-circle-check is-green-text is-nudged-down'></i> ${sampleArray(
                    successMessages
                  )} You may take your time to refactor/comment your solution. Submit when ready.`
                );
              } else {
                this.editor.messages.warn(
                  this._app.data.retired
                    ? `You cannot submit your solution because the kata is retired.`
                    : `You cannot submit your solution at the moment because the kata is not published.`
                );
              }

              if (this._app.data.published) {
                this.readyCode = runner.request.code;
                $("#submit_btn").removeClass("is-hidden");
                $("#attempt_btn").hide();
              }
            }
          },

          (error) => {
            window.alert(
              "There was a server issue while sending the request. Please try again."
            );

            Sentry.withScope((scope) => {
              scope.setTags({ attempting: attempting + "" });
              scope.setExtras({
                description: "Error while posting play notification",
                error,
                attempting,
                solutionId: this.language.solutionId,
              });
              Sentry.captureMessage("Failed to notify response");
            });
          }
        );

      editor = this.editor;
      if (!attempting) {
        if (this.getFixture() && this.getFixture !== "") {
          editor = this.fixture;
        }
      }
    }

    this.showResults(completed, editor);
  }

  submit() {
    if (!this.readyCode || this.readyCode !== this.editor.getValue()) return;

    this.editor.messages.working("Submitting your final kata solution...");
    axios
      .post(
        this._app.route("finalize", { solutionId: this.language.solutionId })
      )
      .then((response) => {
        const json = response.data;
        if (json.success) {
          // the solution grouping is calculating in the background so give it a chance to do its thing
          // note this is not an ideal solution. Probably the most ideal is to trigger a web socket event
          // once the solution has been grouped by a background job
          setTimeout(() => {
            Turbolinks.visit(
              this._app.route("solutions", {
                language: this._app.data.activeLanguage,
              })
            );
          }, 1500);
        } else if (json.reason === "Account restricted") {
          this.editor.messages.fail(
            `You cannot submit your solution because your account is restricted.`
          );
        } else {
          this.editor.messages.fail(
            json.debug ||
              "There was an issue submitting your final kata solution"
          );
        }
      });
  }

  skip() {
    if (this._app.data.published) {
      axios
        .post(this._app.route("skip", this.language), {
          language_name: this._app.data.activeLanguage,
        })
        .then((response) => {
          Turbolinks.visit(response.data.route);
        });
    }
  }

  backToEditor() {
    Turbolinks.visit(
      this._app.route("editor", { language: this._app.data.activeLanguage })
    );
  }

  surrender() {
    this._app.confirmModal.show({
      titleHtml: "Unlock Solutions",
      messageHtml:
        "This will cause you to forfeit your ability to earn honor/rank for this kata. Are you sure?",
      confirmHtml: "Yes, show me the solutions",
      confirm: () => {
        axios
          .post(this._app.route("forfeit"), {}, { responseType: "text" })
          .then((response) => {
            turbolinksRender(response.data);
            const url = response.headers["turbolinks-location"];
            if (url) pushHistoryState(url);
          });
      },
    });
  }

  getSetupCode() {
    const language = this._getLanguage();
    return (language && language.package) || "";
  }

  play() {
    this.isPlaying = true;
    this._playLanguage();
  }

  _playLanguage(language) {
    if (language == null) language = this._app.data.activeLanguage;

    // if the language is already loaded then just set it
    if (this.languages[language]) {
      this._setLanguage(language);
      return;
    }

    // otherwise we need to tell the server that the user started playing the language so that it can
    // update stats and return the language config.
    axios
      .post(this._app.route("session", { language }))
      .then((response) => {
        if (!this.started) this.started = true;
        this.languages[language] = response.data;
        this._setLanguage(language);
      })
      .catch((_) => {
        this._app.alerts.fail(
          "The kata failed to load. Please try reloading the page."
        );
      });
  }

  _getLanguage(language) {
    if (language == null) language = this._app.data.activeLanguage;
    return this.languages[language];
  }

  _setLanguage(language) {
    const info = this.languages[language];
    info.views = info.views || 0;
    info.views++;
    info.newPlay = false;

    this.language = info;
    this.languageName = language;
    this.render();
  }

  render() {
    this.configureEditors();
    this.configureIde();
    this.initMarkdown();

    setTimeout(() => this.afterRender(), 100);
  }

  afterRender() {
    const useLocalStorage =
      (!this.language.solutionId && this.language.recently_attempted) ||
      this.language.views > 1;

    // set the value. Should prefer a saved stash if not in practice mode or it has been viewed within this session already
    this.editor.setValue(
      this.language.workingCode || this.language.setup,
      `${this.language.resultId}-${this.languageName}`,
      useLocalStorage
    );
    setTimeout(
      () => this.editor.setMode(this._app.data.activeLanguage, true),
      0
    );
    this.editor.focus();

    const fixture =
      this.language.workingFixture ||
      this.language.exampleFixture ||
      this.defaultFixtureText(this.languageName);
    this.fixture.setValue(
      fixture,
      `${this.language.resultId}-${this.languageName}-fixture`,
      useLocalStorage
    );
    this.fixture.setMode(this._app.data.activeLanguage);
    this.player.loading = false;
  }

  configureEditors() {
    if (this.editors) return;

    const cmd =
      CodeMirror.keyMap.default === CodeMirror.keyMap.macDefault
        ? "Cmd"
        : "Ctrl";
    const extraKeys = {};
    extraKeys[`${cmd}-'`] = () => this.validate();
    extraKeys[`${cmd}-Alt-Enter`] = () => this.validate();
    extraKeys[`${cmd}-;`] = () => this.attempt();
    extraKeys[`${cmd}-I`] = () => this.player.toggleTabs();
    extraKeys[`${cmd}-Alt-I`] = () => this.player.toggleTabs();
    extraKeys[`${cmd}-U`] = () => this.toggleFocusedEditor();
    extraKeys[`${cmd}-Alt-U`] = () => this.toggleFocusedEditor();

    this.editor = new CodeEditor("div#code", {
      _app: this._app,
      mode: this._app.data.activeLanguage,
      stashGroup: this._app.data.id,
      extraKeys,
      commands: { save: () => this.validate() },
    });

    this.fixture = new CodeEditor("div#fixture", {
      _app: this._app,
      mode: this._app.data.activeLanguage,
      stashGroup: this._app.data.id,
      extraKeys,
      commands: { save: () => this.validate() },
    });

    this.editors = [this.editor, this.fixture];
  }

  initMarkdown() {
    if (!this.markdownDisplay) {
      this.markdownDisplay = new MarkdownDisplay("#description", {
        _app: this._app,
        language: this._app.data.activeLanguage,
        markdown: this._app.data.description,
      });
    }
    this.markdownDisplay.setLanguage(this.languageName);
  }

  showOutput() {
    this.player.activeTab = "output";
  }
}

CodeChallengesPlayController.prototype.events = Object.assign(
  {},
  Ide.prototype.events,
  {
    /**
     * @this {CodeChallengesPlayController}
     */
    "#language_dd dd click"(el, e) {
      if (this._app.data.activeLanguage === el.data("value")) return;

      replaceHistoryState(el.data("href"));
      this._app.data.activeLanguage = el.data("value");

      // HACK Switching languages in player started from "Refactor" link.
      //      The session route contains the solution id.
      if (this._app.routes?.session?.endsWith("session") === false) {
        this._app.routes.session = this._app.routes.session.replace(
          /\/[\da-f]+$/i,
          ""
        );
      }
      if (this.isPlaying) this._playLanguage();
    },

    /**
     * @this {CodeChallengesPlayController}
     */
    "#play_btn click"() {
      this.play();
    },

    /**
     * @this {CodeChallengesPlayController}
     */
    "#skip_btn click"() {
      this.skip();
    },

    /**
     * @this {CodeChallengesPlayController}
     */
    "#edit_draft_btn click"() {
      this.backToEditor();
    },

    /**
     * @this {CodeChallengesPlayController}
     */
    "div#code texteditor.submit"() {
      if (this.readyCode && this.readyCode === this.editor.getValue()) {
        this.submit();
      } else {
        this.attempt();
      }
    },

    // if the code is modified after it is set as ready for submission than unready it
    /**
     * @this {CodeChallengesPlayController}
     */
    "div#code texteditor.change"() {
      if (!this.readyCode) return;

      setTimeout(() => {
        if (this.readyCode === this.editor.getValue()) {
          $("#attempt_btn").hide();
          $("#submit_btn").removeClass("is-hidden");
        } else {
          $("#submit_btn").addClass("is-hidden");
          $("#attempt_btn").show();
          if (!this.attempting) this.editor.messages.hide();
        }
      }, 0);
    },

    /**
     * @this {CodeChallengesPlayController}
     */
    "#reset_btn:not(.is-disabled) click"() {
      this.reset();
    },

    /**
     * @this {CodeChallengesPlayController}
     */
    "#attempt_btn:not(.is-disabled) click"() {
      this.attempt();
    },

    /**
     * @this {CodeChallengesPlayController}
     */
    "#submit_btn:not(.is-disabled) click"() {
      this.submit();
    },

    /**
     * @this {CodeChallengesPlayController}
     */
    "#surrender_btn click"() {
      this.surrender();
    },

    /**
     * @this {CodeChallengesPlayController}
     */
    "#language_version dd.is-active click"($el) {
      this.language.activeVersion = $el.data("version").toString();
    },
  }
);
