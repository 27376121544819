import { Channel } from "@anycable/web";

// User private channel.
// Only a signed in user subscribes.
// Currently used for the following model updates:
// - Honor updates
// - Total stars
// - Total collections
// - Collections item count
export default class PrivateChannel extends Channel {
  static identifier = "PrivateChannel";

  receive(message, _meta) {
    if (message.event === "model_update") return this.onModelUpdate(message);

    super.receive(message);
  }

  onModelUpdate({ id, updates }) {
    if (!id || !updates) return;

    for (let [field, val] of Object.entries(updates)) {
      if (typeof val === "number") {
        val = new Intl.NumberFormat("en-US").format(val);
      }
      const selector = `[data-rt='${id}:${field}']`;
      for (const el of document.querySelectorAll(selector)) {
        el.textContent = val + "";
      }
    }
  }
}
