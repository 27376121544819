import axios from "axios";

const isProgress = (data) =>
  typeof data === "object" && data !== null && data._type === "progress";

export class DjaxRequest {
  static find(dmid) {
    return this.requests.find((r) => r.dmid === dmid);
  }

  static remove(request) {
    const i = this.requests.indexOf(request);
    if (i >= 0) this.requests.splice(i, 1);
  }

  constructor({ url, method, data, timeout, deferred }) {
    DjaxRequest.requests.push(this);
    if (typeof deferred !== "function") {
      throw new TypeError("deferred must be a function");
    }

    this.start = Date.now();
    this.timeout = timeout;
    this.handler = deferred;
    this.pollDuration = 2500;

    axios({
      url,
      method,
      data,
    }).then((response) => {
      const json = response.data;
      // if the response has a deferred message that will be returned separately than handle it
      if (json.dmid) {
        this.dmid = json.dmid;
        // the data may have already been returned by the server
        const data = DjaxRequest.data[json.dmid];
        // if the pubsub response already returned then use the response data now
        if (data) {
          this.handleData(data);
        } else {
          // otherwise start polling as a backup plan
          this.status = "polling";
          this.poll();
        }
      } else {
        // otherwise even though djax was triggered on the client-side, the server has decided to not use it so just
        // call the deferred callback now
        this.handleData(json);
      }
    });
  }

  // this method handles the response from both a websocket response
  handleData(json) {
    if (isProgress(json)) return;
    if (this.status === "complete") return;

    const handler = this.handler;
    const cb = (json) => {
      handler(json);
      this.status = "complete";
      delete DjaxRequest.data[this.dmid];
      DjaxRequest.remove(this);
    };
    if (json && json.cached) {
      this.getCache(cb);
    } else {
      cb(json);
    }
  }

  // polling is used as a backup to websockets. Polling will check the server to
  // see if a cached response with the specified dmid exists.
  poll() {
    setTimeout(() => {
      if (this.status !== "polling") return;

      this.getCache((json) => {
        if (isProgress(json)) {
          if (Date.now() - this.start < this.timeout) {
            this.poll();
          } else {
            // for timeouts we just call handle data without arguments
            // TODO Note that even though it exceeded the `timeout` limit, the server hasn't stopped. This leads to inconsistency.
            this.handleData();
          }
        } else {
          this.handleData(json);
        }
      });
    }, this.pollDuration);
  }

  cancel() {
    this.status = "cancelled";
  }

  getCache(success) {
    if (!this.dmid) return;
    if (this.status === "complete") return;

    axios.get(`/api/v1/deferred/${this.dmid}`).then((response) => {
      const data = response.data;
      if (data) success(data);
    });
  }
}

DjaxRequest.data = {};
DjaxRequest.requests = [];
