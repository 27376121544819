import defaultFixtureText from "../../data/default_fixture_text.toml";

import { sampleArray } from "../utils";
import { TextEditor } from "./text_editor";

export class CodeEditor extends TextEditor {
  constructor(el, options) {
    super(el, options);
  }

  emptyCodeMsg() {
    return sampleArray([
      "Code is missing!",
      "Where is the code?",
      "Why you no send me any code?",
      "Present must your code be before run it we can",
      "Code is missing that is why you fail",
      "Code you must!",
      "Feed me code!",
      "Where did the code go?",
      "Some code would be nice...",
      "There seems to be a lack of code going on here",
      "The idea here would be to code something...",
      "Well this isn't going very well. We need some code...",
    ]);
  }

  defaultFixtureText(language) {
    return defaultFixtureText[language];
  }
}

CodeEditor.prototype.events = Object.assign({}, TextEditor.prototype.events);
CodeEditor.defaults = Object.assign({}, TextEditor.defaults);
