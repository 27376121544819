import { Control } from "../control";
import { highlightCodeBlocks, hideTooltips } from "../ui";
import { slideUp, slideDown } from "../animation";

const escapeHtml = (s) =>
  s.replace(
    /[&<>]/g,
    (c) => `&${c === "&" ? "amp" : c === "<" ? "lt" : "gt"};`
  );

export class CompareSolutionsController extends Control {
  constructor(el, options) {
    super(el, options);
  }
}

CompareSolutionsController.prototype.events = {
  /**
   * @this {CompareSolutionsController}
   */
  ".js-compare-solutions click"($el) {
    const $compare = $el
      .closest(".js-result-group, .js-solution-group")
      .find(".js-compare");

    if ($compare.is(":visible")) {
      slideUp($compare.get(0));
      $el.removeClass("is-red-text");
    } else {
      $el.addClass("is-red-text");
      if (!$compare.html()) {
        let content;
        if (this._app.data.solution) {
          const solution = escapeHtml(this._app.data.solution);
          const activeLanguage = this._app.data.activeLanguage;
          content = `<pre class="p-2 overflow-x-auto"><code data-language=${activeLanguage}>${solution}</code></pre>`;
        } else {
          content = `<p class="bg-k-40 mb-0 p-2"><i class="icon-moon-info"></i>There is no solution to show</p>`;
        }
        $compare.html(`<div class="my-4">${content}</div>`);
        highlightCodeBlocks($compare);
      }
      slideDown($compare.get(0));
    }

    hideTooltips();
  },
};
