import CodeMirror from "codemirror";

// Custom `CodeMirror.runMode` that doesn't replace tabs.
// Note that Marked replaces tabs with 4 spaces, so tabs inside Markdown code blocks are still lost.
// https://github.com/markedjs/marked/issues/644
CodeMirror.runMode = function(string, modespec, callback, options) {
  var mode = CodeMirror.getMode(CodeMirror.defaults, modespec);

  // Create a tokenizing callback function if passed-in callback is a DOM element.
  if (callback.appendChild) {
    var ie = /MSIE \d/.test(navigator.userAgent);
    var ie_lt9 = ie && (document.documentMode == null || document.documentMode < 9);
    var node = callback, col = 0;
    node.textContent = "";
    callback = function(text, style) {
      if (text === "\n") {
        // Emitting LF or CRLF on IE8 or earlier results in an incorrect display.
        // Emitting a carriage return makes everything ok.
        node.appendChild(document.createTextNode(ie_lt9 ? '\r' : text));
        col = 0;
        return;
      }

      var content = text;
      col += text.length;
      // Create a node with token style and append it to the callback DOM element.
      if (style) {
        var sp = node.appendChild(document.createElement("span"));
        sp.className = "cm-" + style.replace(/ +/g, " cm-");
        sp.appendChild(document.createTextNode(content));
      } else {
        node.appendChild(document.createTextNode(content));
      }
    };
  }

  var lines = CodeMirror.splitLines(string), state = (options && options.state) || CodeMirror.startState(mode);
  for (var i = 0, e = lines.length; i < e; ++i) {
    if (i) callback("\n");
    var stream = new CodeMirror.StringStream(lines[i], null, {
      lookAhead: function(n) { return lines[i + n] },
      baseToken: function() {}
    });
    if (!stream.string && mode.blankLine) mode.blankLine(state);
    while (!stream.eol()) {
      var style = mode.token(stream, state);
      callback(stream.current(), style, i, stream.start, state, mode);
      stream.start = stream.pos;
    }
  }
};
