// From animate.css's README
export function animateCSS(selector, animationName, callback) {
  const node = document.querySelector(selector);
  node.classList.add("animated", animationName);
  node.addEventListener("animationend", function handleAnimationEnd() {
    node.classList.remove("animated", animationName);
    node.removeEventListener("animationend", handleAnimationEnd);
    if (typeof callback === "function") callback(node);
  });
}

// https://javascript.info/js-animation
export const animatedScrollTo = (el, to, duration) => {
  if (!el) return;

  const original = el.scrollTop;
  return animate({
    step: (progress) => {
      el.scrollTop = progress * (to - original) + original;
    },
    duration,
  });
};

// https://stackoverflow.com/a/20533102
export const fadeIn = (el, { duration = 400, finish } = {}) => {
  if (!el) return;

  el.style.opacity = 0;
  el.style.display = "inline-block";
  el.style.visibility = "visible";
  return animate({
    step: (progress) => {
      el.style.opacity = progress;
    },
    finish,
    duration,
  });
};

export const fadeOut = (el, { duration = 400, finish } = {}) => {
  if (!el) return;

  return animate({
    step: (progress) => {
      el.style.opacity = 1 - progress;
    },
    finish: () => {
      el.style.display = "none";
      el.style.visibility = "hidden";
      if (typeof finish === "function") finish();
    },
    duration,
  });
};

export const slideDown = (el, { duration = 400, finish } = {}) => {
  if (!el) return;

  el.style.height = 0;
  el.style.display = "block";
  beforeSlide(el);
  const to = el.scrollHeight;
  return animate({
    step: (progress) => {
      el.style.height = `${(to * progress).toFixed(2)}px`;
    },
    finish: () => {
      el.style.display = "block";
      el.style.height = null;
      afterSlide(el);
      if (typeof finish === "function") finish();
    },
    duration,
  });
};

export const slideUp = (el, { duration = 400, finish } = {}) => {
  if (!el) return;

  const from = el.scrollHeight;
  // REVIEW Use the current value?
  el.style.display = "block";
  beforeSlide(el);
  return animate({
    step: (progress) => {
      el.style.height = `${(from * (1 - progress)).toFixed(2)}px`;
    },
    finish: () => {
      el.style.display = "none";
      el.style.height = null;
      afterSlide(el);
      if (typeof finish === "function") finish();
    },
    duration,
  });
};

const beforeSlide = (el) =>
  Object.assign(el.style, {
    overflow: "hidden",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: "0",
    paddingBottom: "0",
  });

// TODO Restore original values?
const afterSlide = (el) =>
  Object.assign(el.style, {
    overflow: null,
    marginTop: null,
    marginBottom: null,
    paddingTop: null,
    paddingBottom: null,
  });

const swing = (p) => 0.5 - Math.cos(p * Math.PI) / 2;

const animate = ({ step, finish, timing = swing, duration = 400 }) => {
  if (typeof step !== "function")
    throw new TypeError("step must be a function");
  if (typeof timing !== "function")
    throw new TypeError("timing must be a function");

  const start = performance.now();
  // Return the id to cancel animation frame
  const anim = { id: 0 };
  anim.id = requestAnimationFrame(function next(time) {
    const progress =
      duration === 0 ? 1 : Math.max(Math.min((time - start) / duration, 1), 0);
    step(timing(progress));
    if (progress < 1) {
      anim.id = requestAnimationFrame(next);
    } else {
      if (typeof finish === "function") finish();
    }
  });
  return anim;
};
