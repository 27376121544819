// UTM parameters are stored in session storage so we can find them when signing up.
// Not using local storage because the parameters may become outdated.
// The parameters from session storage are merged with the parameters from the current URL.
const UTM_KEY = "$codewars$utm";

export const getUtmProps = () => {
  const props = utmFromParams(utmFromStorage());
  window.sessionStorage.setItem(UTM_KEY, JSON.stringify(props));
  return props;
};

const utmFromStorage = () => {
  try {
    return JSON.parse(window.sessionStorage.getItem(UTM_KEY) || "{}");
  } catch (err) {
    return {};
  }
};

const utmFromParams = (utmProps = {}) => {
  try {
    const url = new URL(document.location);
    url.searchParams.forEach((val, key) => {
      if (val && key.startsWith("utm_")) {
        if (val === "null") {
          delete utmProps[key];
        } else {
          utmProps[key] = val;
        }
      }
    });
    // Make sure `utm_referrer` is set by falling back to `document.referrer`.
    // Only store if the value has a host different from the current one.
    if (!utmProps.utm_referrer && document.referrer) {
      const referrer = new URL(document.referrer);
      if (referrer.host !== url.host) {
        utmProps.utm_referrer = document.referrer;
      }
    }
  } catch (err) {
    /* ignore errors */
  }
  return utmProps;
};
