import tippy, { roundArrow } from "tippy.js";

const SEL = "[data-tippy-content]";

export const initTooltips = () => {
  for (const el of document.querySelectorAll(SEL)) {
    if (!el._tippy) {
      tippy(el, {
        delay: [400, 0],
        theme: "codewars",
        arrow: roundArrow,
      });
    }
  }
};

export const hideTooltips = () =>
  window.requestAnimationFrame(() => {
    for (const el of document.querySelectorAll(SEL)) {
      if (el._tippy) el._tippy.hide();
    }
  });

export const destroyTooltips = () => {
  for (const el of document.querySelectorAll(SEL)) {
    if (el._tippy) el._tippy.destroy();
  }
};
