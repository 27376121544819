import { Controller } from "@stimulus/core";
import axios from "axios";
import Turbolinks from "turbolinks";
import swal from "sweetalert";

import { disableButton } from "./utils";

// TODO Move other actions here and improve actions (buttons layout, feedback)
export default class extends Controller {
  static values = {
    id: String,
  };

  /**
   * Delete the user. Admins only.
   * @param {MouseEvent} event
   * @returns {Promise<void>}
   */
  async deleteUser(event) {
    disableButton(event.target);
    event.stopPropagation();
    // Prevent accidentally requesting delete to `/users`.
    if (!this.idValue) return;

    const value = await swal({
      title: "Delete User",
      text: "This action is not reversible. Are you sure to delete this user?",
      icon: "warning",
      buttons: {
        cancel: "No",
        confirm: "Yes",
      },
    });
    const path = `/users/${this.idValue}`;
    if (value !== true) return;

    try {
      const response = await axios.delete(path);
      const json = response.data;
      if (json.success) {
        await successMessage("User deleted");
        Turbolinks.visit(path);
        return;
      }
    } catch (err) {
      console.error(err.message);
    }
    await failureMessage();
  }
}

const successMessage = async (text = "") => {
  await swal({
    // NOTE swal's success has animation that looks terrible on dark mode and not worth trying to make it work
    icon: "info",
    text: text || "Action Successful",
  });
};

const failureMessage = async (text = "") => {
  await swal({
    icon: "error",
    text:
      text ||
      "Something went wrong. Check the console for any error messages, and report a bug if the problem persists.",
  });
};
