import { Controller } from "@stimulus/core";
import axios from "axios";
import Turbolinks from "turbolinks";
import swal from "sweetalert";

import { disableButton } from "./utils";

export default class extends Controller {
  static values = {
    projectId: String,
  };

  /**
   * Retry solution grouping for a finalized solution. Moderator only.
   * @param {MouseEvent} event
   * @returns {Promise<void>}
   */
  async group(event) {
    disableButton(event.currentTarget);
    event.stopPropagation();
    const solutionId = event.currentTarget.dataset.solutionId;
    try {
      const res = await axios.post(
        `/api/v1/code-challenges/projects/${this.projectIdValue}/solutions/${solutionId}/group`
      );
      await swal({
        // NOTE swal's success has animation that looks terrible on dark mode and not worth trying to make it work
        icon: "info",
        text: res.data.message,
      });
    } catch (e) {
      let text = "Failed!";
      if (e.response) {
        text = e.response.data.reason;
      }
      await swal({ icon: "error", text });
    }

    Turbolinks.visit();
  }
}
