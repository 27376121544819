import $ from "jquery";
import axios from "axios";
import Turbolinks from "turbolinks";

import { Control } from "../../control";
import { slideUp } from "../../animation";
import { mountPromotedAdDisplay } from "../../mounts/promoted_ad_display";

export class CollectionsShowController extends Control {
  constructor(el, options) {
    super(el, options);
    this.activateCurrentOrFirst();
    if (this._app.data.isAuthor) {
      $(".for-collection-creator").removeClass("is-hidden");
      $("body").addClass("creator-view");
    }

    mountPromotedAdDisplay("#partner-display", {
      type: "image",
      id: "collection_show_ad",
      contrast: true,
      user: this._app.currentUser,
    });
  }

  totalItems() {
    return $("[data-items-count]").data("itemsCount");
  }

  moveBy($el, delta) {
    const id = $el.closest(".list-item-kata").prop("id");
    axios.post(this._app.route("move", { id }), { delta }).then((_) => {
      Turbolinks.clearCache();
      Turbolinks.visit();
    });
  }

  activeLanguage() {
    const $el = $("dd[data-language].is-active");
    if ($el.length) {
      return { id: $el.data("language"), name: $el.text() };
    }
  }

  activeLanguageItems() {
    const active = this.activeLanguage();
    if (active) {
      return $(`a[data-language='${active.id}']`).length;
    } else {
      return 0;
    }
  }

  activateCurrentOrFirst() {
    let $el = $(
      `dd[data-language='${this._app.currentUser.current_language}']`
    );
    if (!$el.length) $el = $("dd[data-language]");
    $el.trigger("click").trigger("click");
  }

  trainNow(language, trainAll) {
    axios
      .post(this._app.route("train"), { all_kata: trainAll, language })
      .then((response) => {
        const json = response.data;
        if (json.route) Turbolinks.visit(json.route);
      });
  }
}

CollectionsShowController.prototype.events = {
  /**
   * @this {CollectionsShowController}
   */
  ".js-train click"() {
    const lang = this.activeLanguage();

    if (
      this.totalItems() === 1 ||
      this.activeLanguageItems() === this.totalItems()
    ) {
      this.trainNow(lang.id, false);
    } else {
      this._app.confirmModal.show({
        titleHtml: "Which kata do you want to train on?",
        messageHtml: [
          `You prefer to train on ${lang.name}.`,
          `Not all kata in this collection are available in that language.`,
          `Which kata should we add to your training queue?`,
        ].join(" "),
        confirmHtml: `${lang.name} Kata Only`,
        cancelHtml: "All Kata",
        confirm: () => this.trainNow(lang.id, true),
        cancel: () => this.trainNow(lang.id, false),
      });
    }
  },

  /**
   * @this {CollectionsShowController}
   */
  ".js-remove click"($el) {
    this._app.confirmModal.show({
      titleHtml: "Are you sure you want to remove?",
      messageHtml:
        "You can add it again later by finding it within the library.",
      confirmHtml: "Yes, remove",
      confirm: () => {
        const $item = $el.closest(".list-item-kata");
        this._app.collectionModal.removeChallenge(
          this._app.data.collection,
          $item.prop("id"),
          () => {
            slideUp($item.get(0));
          }
        );
      },
    });
  },

  /**
   * @this {CollectionsShowController}
   */
  ".js-move-up click"($el) {
    this.moveBy($el, -1);
  },

  /**
   * @this {CollectionsShowController}
   */
  ".js-move-down click"($el) {
    this.moveBy($el, 1);
  },

  /**
   * @this {CollectionsShowController}
   */
  ".js-delete-collection click"() {
    // TODO Add confirmation
    axios.delete(this._app.route("collection")).then((_) => {
      Turbolinks.visit("/collections");
    });
  },
};
