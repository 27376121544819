import $ from "jquery";
import Turbolinks from "turbolinks";
import { Control } from "../../control";
import { createDisplayManager } from "../../services/display_manager";
import { CodeSnippetListItemController } from "../code_snippet_list_item_controller";

export class CodeSnippetsIndexController extends Control {
  constructor(el, options) {
    super(el, options);
    const listItem = new CodeSnippetListItemController(this.element, {
      _app: this._app,
    });
    this._controllers.push(listItem);
    createDisplayManager({
      id: "kumite_list",
      user: this._app.currentUser,
      partner: {
        type: "image",
        contrast: true,
      },
    });
  }

  filterUrl(search) {
    const params = {
      q: search || $("#search_input").val(),
      personal: document.querySelector("#personal").checked,
      secrets: document.querySelector("#secrets")?.checked,
      language: $("#language_filter").val(),
    };

    // clean out all of the empty params, except for q which we need so that the filters stay active
    Object.keys(params).forEach(function (k) {
      if (!params[k] && k !== "q") delete params[k];
    });

    return this._app.route("search", null, params);
  }

  search(value) {
    if (value == null) value = $("#search_input").val();
    this.filter(value);
  }

  filter(search) {
    Turbolinks.clearCache();
    Turbolinks.visit(this.filterUrl(search));
  }
}

CodeSnippetsIndexController.prototype.events = {
  /** @this {CodeSnippetsIndexController} */
  "#search click, #search_input sl-clear"() {
    this.search();
  },

  /** @this {CodeSnippetsIndexController} */
  "#personal sl-change, #secrets sl-change, #language_filter sl-change"() {
    this.search();
  },

  /** @this {CodeSnippetsIndexController} */
  "#search_form submit"(_, e) {
    e.preventDefault();
    this.search();
  },
};
