import $ from "jquery";

import { View } from "../view";
import { FormView } from "../views";

export const autoloadViews = (app) =>
  $("view").each((_, el) => {
    const $view = $(el);
    if (!$view.data("view") && $view.closest("template").length === 0) {
      const V = getViewConstructor($view.data("view-type"));
      new V($view, { _app: app });
    }
  });

const getViewConstructor = (type) => {
  switch (type) {
    case "FormView":
      return FormView;
    default:
      return View;
  }
};
