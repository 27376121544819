import $ from "jquery";
import Turbolinks from "turbolinks";
import { createDisplayManager } from "../../services/display_manager";

import { Control } from "../../control";
import { InfiniteScroll } from "../infinite_scroll";
import { fixSelectValueArray } from "../../../controllers/utils.js";

export class CodeChallengesListController extends Control {
  constructor(el, options) {
    super(el, options);
    fixSelectValueArray(document.getElementById("ranks_filter"));
    fixSelectValueArray(document.getElementById("tags_filter"));

    this.infiniteScroll = new InfiniteScroll("section.items-list", {
      _app: this._app,
    });
    this._controllers.push(this.ranksRange, this.infiniteScroll);

    createDisplayManager({
      id: "kata_catalog",
      partner: {
        type: "image",
        red: true,
        contrast: true,
      },
      user: this._app.currentUser,
      brazeTargets: ["kata_search_1", "kata_search_2"],
    });
  }

  filterUrl(search) {
    const url = new URL(location.href);
    const params = {
      q: search || document.getElementById("search_input").value,
      // array of strings
      r: document.getElementById("ranks_filter").value,
      tags: (document.getElementById("tags_filter").value || []).join(","),
      xtags: url.searchParams.get("xtags"),
      xids: $("#ids_filter").val(),
      beta: $("#beta_filter").val(),
      beta_state: $("#beta_state_filter").val(),
      order_by: $("#order_by").val(),
      untagged: url.searchParams.get("untagged") !== null,
      outdated: url.searchParams.get("outdated") !== null,
      sample: url.searchParams.get("sample") !== null,
    };

    // clean out all of the empty params, except for q which we need so that the filters stay active
    Object.keys(params).forEach(function (k) {
      if (!params[k] && k !== "q") delete params[k];
    });

    return this._app.route(
      "search",
      { language: $("#language_filter").val() },
      params
    );
  }

  search(value) {
    if (value == null) value = document.getElementById("search_input").value;
    this.filter(value);
  }

  filter(search) {
    Turbolinks.clearCache();
    Turbolinks.visit(this.filterUrl(search));
  }

  sample(sample) {
    const url = new URL(this.filterUrl());
    if (sample) {
      url.searchParams.set("sample", "true");
    } else {
      url.searchParams.delete("sample");
    }
    Turbolinks.clearCache();
    Turbolinks.visit(url.toString());
  }
}

CodeChallengesListController.prototype.events = {
  /**
   * @this {CodeChallengesListController}
   */
  "#search click, #search_input sl-clear"() {
    this.search();
  },

  "#search_form submit"(_, e) {
    e.preventDefault();
    this.search();
  },

  "#sample click"() {
    this.sample(true);
  },

  "#unsample click"() {
    this.sample(false);
  },

  /**
   * @this {CodeChallengesListController}
   */
  ".suggestions a.keyword-tag click"($el) {
    this.search($el.text());
  },

  "#order_by sl-change, #language_filter sl-change, #beta_filter sl-change, #beta_state_filter sl-change, #ids_filter sl-change"() {
    this.filter();
  },

  "#ranks_filter sl-focus, #tags_filter sl-focus"($el) {
    const el = $el.get(0);
    el[FOCUSED] = true;
    storeArrayValue(el);
  },

  "#ranks_filter sl-blur, #tags_filter sl-blur"($el) {
    const el = $el.get(0);
    el[FOCUSED] = false;
    ifArrayValueChanged(el, () => {
      this.filter();
    });
  },

  "#ranks_filter sl-clear, #tags_filter sl-clear"() {
    this.filter();
  },

  "#ranks_filter sl-change, #tags_filter sl-change"($el) {
    const el = $el.get(0);
    const dropdown = el.shadowRoot.querySelector("sl-dropdown");
    // Filter on `sl-change` if the dropdown isn't open.
    // This happens when user removes an item using the item's `x` button which emits
    // `sl-focus` + `sl-change`, but not `sl-blur`.
    // `FOCUSED` is also required to avoid triggering this when the value is set programmatically
    // with `fixSelectValueArray`.
    if (el[FOCUSED] && !dropdown?.open) this.filter();
  },
};

const OLD_VALUE = Symbol("oldValue");
const FOCUSED = Symbol("focused");

const storeArrayValue = (el) => {
  el[OLD_VALUE] = el.value.slice();
};

const ifArrayValueChanged = (el, cb) => {
  if (arrayValuesChanged(el[OLD_VALUE], el.value.slice())) {
    cb();
  }
};

const arrayValuesChanged = (a, b) => {
  if (a.length !== b.length) return true;

  a = a.slice().sort();
  b = b.slice().sort();
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return true;
  }
  return false;
};
