import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";

import Turbolinks from "turbolinks";

import "jquery";

// Expose as window.App
import { App } from "../app";
window.App = App;
import "../controllers";

Turbolinks.start();
