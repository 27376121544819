import $ from "jquery";

import CodeMirror from "../../vendor/codemirror";

import { Stashable } from "./stashable";
import { AlertMessageBox } from "./alert_message_box";
import { toUpperFirst } from "../utils";
import { codeMirrorMode, codeMirrorTabSize } from "../ui";
import { successMessages } from "../consts";

const defaultKeyMap = "default";

// quick way to map commands to their configured options values. Right now each possible
// command needs to be manually mapped.
const runCommand = function (name, cm) {
  if (cm.options.commands != null && cm.options.commands[name]) {
    cm.options.commands[name]();
    return true;
  } else {
    return false;
  }
};

CodeMirror.commands.save = (cm) => runCommand("save", cm);
CodeMirror.commands.test = (cm) => runCommand("test", cm);

const onTab = (cm) => {
  if (cm.somethingSelected()) {
    cm.indentSelection("add");
  } else if (cm.getOption("indentWithTabs")) {
    cm.replaceSelection("\t", "end", "+input");
  } else {
    cm.execCommand("insertSoftTab");
  }
};

const onShiftTab = (cm) => {
  cm.indentSelection("subtract");
};

CodeMirror.keyMap["vim-insert"]["Tab"] = onTab;
CodeMirror.keyMap["vim-insert"]["Shift-Tab"] = onShiftTab;

// Agda input for Agda mode
const autocompleteKeyMap = {
  "\\"(cm) {
    cm.replaceSelection("\\");
    cm.execCommand("autocomplete");
  },
};

const hintOptions = {
  extraKeys: {
    Space(cm) {
      const cA = cm.state.completionActive;
      if (cA) {
        cA.widget.pick();
        cm.replaceSelection(" ");
      }
    },
  },
  closeCharacters: /[\s]/,
  // Disable auto closing even if there's only one choice.
  completeSingle: false,
};

const toggleInputHelper = function (cm, enable) {
  if (enable) {
    cm.addKeyMap(autocompleteKeyMap);
    cm.setOption("hintOptions", hintOptions);
  } else {
    cm.removeKeyMap(autocompleteKeyMap);
    cm.setOption("hintOptions", null);
  }
};

// Show \ as λ in LC mode
const toggleLambda = (cm, enable) => {
  if (enable) {
    cm.setOption("specialChars", /\\/);
    cm.setOption("specialCharPlaceholder", () => {
      const elem = document.createElement("span");
      elem.setAttribute("cm-text", "\\");
      elem.innerHTML = "λ";
      return elem;
    });
  } else {
    // Default
    cm.setOption("specialChars", CodeMirror.defaults.specialChars);
    cm.setOption(
      "specialCharPlaceholder",
      CodeMirror.defaults.specialCharPlaceholder
    );
  }
};

// Wraps CodeMirror functionality with additional features and a simplified API
export class TextEditor extends Stashable {
  constructor(el, options) {
    super(el, options);
    if (!this.options.mode) {
      this.options.mode = this._app.data.language;
    }
    // mono_industrial, chrome, tomorrow, tomorrow_night, dawn
    this.keyMap =
      this.options.keyMap ||
      this._app.localStorage.get("editorKeyMap") ||
      defaultKeyMap;
    this.options.theme =
      this.options.theme || this._app.data.editorTheme || "codewars";
    this.width = this.options.width;
    this.height = this.element.height();
    this.element.addClass("text-editor-container");

    this.textarea = this.element.find("textarea");
    if (this.textarea.length === 1) {
      this.options.value = this.textarea.val();
      this.textarea.hide();
    }

    this.container = this.element.find(".js-editor");
    // HACK A quick hack to make editor configurable with JSON. Used in `setMode`.
    this.config = getCustomConfig();

    const tabSize = this.options.tabSize;
    // configure editor instance
    this.editor = new CodeMirror(this.container.get(0), {
      value: this.options.value || "",
      mode: this.options.mode,
      tabSize,
      indentUnit: tabSize,
      smartIndent: this.options.smartIndent ?? true,
      autoCloseBrackets: this.options.autoCloseBrackets ?? true,
      indentWithTabs: false,
      readOnly: this.options.readOnly,
      lineNumbers: this.options.lineNumbers,
      lineWrapping: this.options.lineWrapping,
      foldGutter: this.options.foldGutter,
      styleActiveLine: this.options.styleActiveLine,
      electricChars: false,
      gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
      keyMap: this.keyMap,
      extraKeys: this.extraKeys(),
      commands: this.options.commands,
      matchBrackets: true,
      // The maximum number of undo levels that the editor stores including selection changes.
      // The default is 200.
      undoDepth: 600,
    });
    if (this.options.mode === "agda" || this.options.mode === "julia") {
      toggleInputHelper(this.editor, true);
    }
    if (this.options.mode === "lambdacalc") {
      toggleLambda(this.editor, true);
    }

    this.setTheme(this.options.theme);

    // map events
    this.editor.on("change", () => this.element.trigger("texteditor.change"));

    this.editor.on("focus", () => {
      this.focused = true;
      this.element.trigger("texteditor.focus");
    });

    this.editor.on("blur", () => {
      this.focused = false;
      this.element.trigger("texteditor.blur");
    });

    this.editor.on("scroll", () => this.element.trigger("texteditor.scroll"));

    // set initial value
    if (this.options.value || this.options.stashId) {
      this.setValue(
        this.options.value,
        this.options.stashId,
        this.options.preferStash
      );
    }

    setTimeout(() => {
      this.resize();
      if (this.keyMap !== defaultKeyMap) {
        this.setBindings(this.keyMap);
      }
    }, 0);

    const $msgEl = this.element.find(".editor-msg-container");
    $msgEl.appendTo(this.container);
    this.messages = new AlertMessageBox($msgEl, {
      boxContainer: false,
      showClose: true,
      displayDuration: 0,
      successMessage: this.options.successMessage,
      sanitize(msg, state) {
        if (msg) {
          return msg.replace("Test::Error: ", "");
        } else {
          return msg;
        }
      },
    });
    this._controllers.push(this.messages);

    this.minHeight = this.element.height();
  }

  extraKeys() {
    const cmd =
      CodeMirror.keyMap.default === CodeMirror.keyMap.macDefault
        ? "Cmd"
        : "Ctrl";

    const keys = {
      [`${cmd}-Enter`]: () => this.onSubmit(),
      [`${cmd}-/`]: () => this.editor.toggleComment(),
      ["Tab"]: onTab,
      ["Shift-Tab"]: onShiftTab,
    };

    if (this.keyMap !== "vim") {
      keys["Esc"] = () => {
        if (this.options.esc) {
          this.options.esc(this);
        } else {
          this.toggleFullScreen();
        }
      };
    }

    if (this.options.extraKeys) {
      return Object.assign(keys, this.options.extraKeys);
    } else {
      return keys;
    }
  }

  setExtraKeys(extraKeys) {
    this.options.extraKeys = extraKeys;
    this.editor.setOption("extraKeys", this.extraKeys());
  }

  toggleBindings(mode) {
    this.setBindings(this.keyMap !== mode ? mode : defaultKeyMap);
  }

  setBindings(mode) {
    if (mode === "default") mode = "sublime";
    mode = mode || defaultKeyMap;
    this.keyMap = mode;
    this.editor.setOption("keyMap", mode);
    this.editor.setOption("extraKeys", this.extraKeys());
    this._app.localStorage.set("editorKeyMap", this.keyMap);
  }

  setOption(name, value) {
    this.options.name = value;
    const method = `set${toUpperFirst(name)}`;
    if (this[method]) {
      this[method](value);
    } else {
      this.editor.setOption(name, value);
    }
  }

  onSubmit() {
    const value = this.getValue();
    this.element.get(0).dispatchEvent(
      new CustomEvent("texteditor:submit", {
        detail: value,
      })
    );
    this.element.trigger("texteditor.submit", this, value);
  }

  getCursorLineStart() {
    return this.editor.getCursor("from").line;
  }

  getCursorLineEnd() {
    return this.editor.getCursor("to").line;
  }

  // selects all of the currently selected lines, useful for when only a portion of a line may be selected
  // and you want to ensure that all of the lines are completely selected
  selectAllCurrentLines() {
    this.editor.setSelection(
      CodeMirror.Pos(this.getCursorLineStart(), 0),
      this.editor.getCursor("end")
    );
  }

  setTheme(theme) {
    this.editor.setOption("theme", theme);
  }

  setMode(language, isSolution) {
    switch (language) {
      case "riscv":
      case "nasm":
        if (!isSolution) language = "c";
        break;
      case "bf":
      case "lambdacalc":
      case "solidity":
        if (!isSolution) language = "javascript";
        break;
      case "shell":
      case "sql":
        if (!isSolution) language = "ruby";
        break;
    }

    const smartIndent = getConfig(this.config, language, "smartIndent", true);
    const autoCloseBrackets = getConfig(
      this.config,
      language,
      "autoCloseBrackets",
      true
    );
    const indentWithTabs = getConfig(
      this.config,
      language,
      "indentWithTabs",
      false
    );

    const tabSize = getConfig(
      this.config,
      language,
      "tabSize",
      codeMirrorTabSize(language)
    );

    const mode = codeMirrorMode(language);
    toggleInputHelper(this.editor, mode === "agda" || mode === "julia");
    toggleLambda(this.editor, mode === "lambdacalc");
    this.editor.setOption("mode", mode);
    this.editor.setOption("tabSize", tabSize);
    this.editor.setOption("indentUnit", tabSize);
    this.editor.setOption("smartIndent", smartIndent);
    this.editor.setOption("autoCloseBrackets", autoCloseBrackets);
    this.editor.setOption("indentWithTabs", indentWithTabs);
  }

  setReadOnly(readOnly) {
    this.editor.setOption("readOnly", readOnly);
  }

  setCursor(line = 0, ch = 0) {
    this.editor.setCursor(CodeMirror.Pos(line, ch));
  }

  scrollToLine(lineNumber = 0) {
    this.editor.scrollIntoView(CodeMirror.Pos(lineNumber, 0));
  }

  focus() {
    this.editor.focus();
  }

  getValue() {
    return this.editor.getValue();
  }

  // this method is called internally by stashable.setValue(value, stashId, preferStash)
  _setValue(value) {
    // hack to fix updates when element is hidden
    if (value === "") this.editor.setValue(" ");
    return this.editor.setValue(value || "");
  }

  refresh() {
    // hacky workaround to get around an issue with offline storage and hidden editors being updated.
    // TODO: clean this up - it causes a flicker and is ugly
    const value = this.getValue();
    this.editor.setValue("");
    this._setValue(value);
  }

  resize() {
    const $parent = this.element.parent();
    let height = this.fullScreenEnabled
      ? $(window).height() - 20
      : $parent.height();

    this.container.siblings().each(function (i, el) {
      const $el = $(el);
      if ($el.css("position") === "static" && $el.is(":visible")) {
        height -= $el.outerHeight(true);
      }
    });

    if (height > 0) {
      this.setHeight(height);
    }
  }

  setHeight(height, min) {
    if (height < 1) {
      throw "height cannot be less than 1";
    }

    if (this._setHeightWait != null) {
      this._setHeightWait.reject();
    }

    if (min) {
      if (min === true) min = this.minHeight;
      if (height < min) return;
    }

    this.height = height;
    this.container.height(height);
    this.editor.setSize({
      width: "100%",
      height: convertUnit(this.height),
    });
  }

  save() {
    this.textarea.val(this.getValue());
    if (this.options.emptyStashOnSave) this.emptyStash();
    if (typeof this.options.save === "function") this.options.save(this);
  }

  change(fn) {
    this.editor.on("change", () => fn(this));
  }

  enterFullScreen() {
    if (this.fullScreenEnabled) return;

    this.fullScreenEnabled = true;

    // allow the actual UI changes to be patched
    if (this.options.enterFullScreen) {
      this.options.enterFullScreen();
    } else {
      this.originalScroll = $(window).scrollTop();
      this.originalParent = this.element.parent();

      this.element.addClass("is-full-screen");
      this.element.appendTo("#app");
      this.resize();
    }

    this.element.find("li.shrink").show();
    this.element.find("li.expand").hide();

    $("#uvTab, #shell, #main_header, #main_footer").hide();

    $("html").addClass("has-full-screen-control");
    this.focus();
  }

  exitFullScreen() {
    if (!this.fullScreenEnabled) return;

    this.fullScreenEnabled = false;

    if (this.options.exitFullScreen) {
      this.options.exitFullScreen();
    } else {
      this.element.removeClass("is-full-screen");
      this.element.appendTo(this.originalParent);
      this.element.height("");
    }

    setTimeout(() => $(window).trigger("resize"), 0);

    this.element.find("li.expand").show();
    this.element.find("li.shrink").hide();
    $("#uvTab, #shell, #main_header, #main_footer").show();

    $(window).scrollTop(this.originalScroll);
    $("html").removeClass("has-full-screen-control");
    this.focus();
  }

  toggleFullScreen() {
    if (this.fullScreenEnabled) {
      this.exitFullScreen();
    } else {
      this.enterFullScreen();
    }
  }

  setBorder(color) {
    const $el = this.element
      .find(".CodeMirror")
      .removeClass(`has-${this.currentBorder}-border`);
    this.currentBorder = color;
    if (color) {
      $el.addClass(`has-${color}-border`);
    }
  }
}

TextEditor.prototype.events = {
  /**
   * @this {TextEditor}
   */
  "texteditor.change"() {
    this.updateStash();
    if (this.options.change) {
      this.options.change(this);
    }
    // TODO This shouldn't be necessary (also not checking for existence)
    this.textarea.text(this.editor.getValue());

    // update the related text area if there is one
    if (this.textarea != null && this.textarea.length > 0) {
      this.textarea.val(this.getValue());
      // Dispatch native event so that `v-model` updates
      this.textarea.get(0).dispatchEvent(new Event("input"));
    }
  },

  /**
   * @this {TextEditor}
   */
  "li.expand click"() {
    this.enterFullScreen();
  },

  /**
   * @this {TextEditor}
   */
  "li.shrink click"() {
    this.exitFullScreen();
  },

  /**
   * @this {TextEditor}
   */
  "window resize"() {
    this.resize();
  },
};

TextEditor.defaults = {
  value: null,
  allowFullScreen: true,
  tabSize: 2,
  indentUnit: 2,
  theme: null,
  mode: null,
  lineNumbers: true,
  readOnly: false,
  highlightActiveLine: true,
  emptyStashOnSave: false,
  save: null,
  change: null,
  stashGroup: "code_editor",
  successMessage: successMessages,
  foldGutter: true,
  lineWrapping: false,
  styleActiveLine: true,
  width: "100%",
  height: null,
  esc: null,
};

// HACK A quick hack to make editor configurable without UI
const getCustomConfig = () => {
  try {
    const item = localStorage.getItem("editorOptions");
    return item ? JSON.parse(item) : {};
  } catch (_) {
    return {};
  }
};

// Custom config can have overrides for each language id.
// - opts[`[${language}]`][key]
// - opts[key]
// - default
const getConfig = (opts, language, key, def) => {
  const lang = opts[`[${language}]`];
  if (lang && key in lang) return lang[key];
  if (key in opts) return opts[key];
  return def;
};

const convertUnit = (unit) => (typeof unit === "number" ? `${unit}px` : unit);

const preserveSelection = function (cm, offset, cb) {
  let start = cm.getCursor("start");
  const end = cm.getCursor("end");

  cb();

  start = CodeMirror.Pos(start.line, Math.max(0, start.ch + offset));
  if (start.line !== end.line) {
    cm.setSelection(start, end);
  } else {
    cm.setCursor(start);
  }
};
