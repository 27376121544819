import $ from "jquery";

import { Control } from "../control";

export class ConfirmModal extends Control {
  constructor(el, options) {
    super(el, options);

    const opts = Object.assign(
      { backdrop: true, keyboard: true, show: true },
      this.element.data(),
      this.options
    );
    this.modal = new Modal(this.element, opts);
    this.element.data("modal", this.modal);
    if (opts.show) this.modal.show();
  }

  destroy() {
    this.hide();
    super.destroy();
  }

  messageEl() {
    return this.element.find(".modal-body");
  }

  show(options = {}) {
    options = Object.assign({}, this.options, options);

    // if the options are not setup to be used as a confirm message than just set the cancel button text to "OK"
    if (!options.cancelHtml && !options.confirm && !options.altConfirm) {
      options.cancelHtml = "OK";
    }

    if (options.titleHtml) {
      this.element.find("h4").html(options.titleHtml);
    }

    this.view = null;

    if (options.view) {
      const $viewEl = $('<div class="view"></div>').appendTo(
        this.messageEl().html("")
      );
      this.view = options.view($viewEl);
    } else if (options.messageHtml) {
      this.messageEl().html(options.messageHtml);
    }

    if (options.cancelHtml) {
      this.element.find("li.cancel a").html(options.cancelHtml);
    }
    if (options.confirm) {
      if (options.confirmHtml) {
        this.element
          .find("li.confirm")
          .show()
          .find("a")
          .html(options.confirmHtml);
      }
    } else {
      this.element.find("li.confirm").hide();
    }

    if (options.altConfirm && options.altConfirmHtml) {
      this.element
        .find("li.alt-confirm")
        .show()
        .find("a")
        .addClass(options.altConfirmClass)
        .html(options.altConfirmHtml);
    } else {
      this.element
        .find("li.alt-confirm")
        .hide()
        .find("a")
        .attr("class", "button");
    }

    const $actions = this.element.find(".modal-footer, .modal-header .close");
    if (options.hideActions) {
      $actions.hide();
    } else {
      $actions.show();
    }

    this.showOptions = options;
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }
}

ConfirmModal.prototype.events = {
  /**
   * @this {ConfirmModal}
   */
  '*[data-dismiss="modal"] click'(el, e) {
    if (this.showOptions != null && this.showOptions.cancel) {
      this.showOptions.cancel(e);
    }
  },

  /**
   * @this {ConfirmModal}
   */
  "li.alt-confirm a click"(btn, e) {
    this.modal.hide();
    if (this.showOptions != null && this.showOptions.altConfirm) {
      this.showOptions.altConfirm(e);
    }
  },

  /**
   * @this {ConfirmModal}
   */
  '*[data-confirm="modal"] click, li.confirm a click'(btn, e) {
    this.modal.hide();
    if (this.showOptions != null && this.showOptions.confirm) {
      this.showOptions.confirm(e);
    }
  },
};

ConfirmModal.defaults = {
  backdrop: true,
  show: false,
  keyboard: false,
  confirm: null,
  cancel: null,
  titleHtml: null,
  messageHtml: null,
  cancelHtml: "Cancel",
  confirmHtml: "Confirm",
};

// Based on bootstrap-modal.js v2.0.2
class Modal {
  constructor(content, options) {
    this.options = options;
    this.$element = $(content).on(
      "click.dismiss.modal",
      '[data-dismiss="modal"]',
      this.hide.bind(this)
    );
  }

  toggle() {
    return !this.isShown ? this.show() : this.hide();
  }

  show() {
    if (this.isShown) return;

    $("body").addClass("modal-open");
    this.isShown = true;
    this.$element.trigger("show");
    escape.call(this);
    backdrop.call(this, () => {
      const transition = this.$element.hasClass("fade");

      if (!this.$element.parent().length) this.$element.appendTo(document.body); //don't move modals dom position

      this.$element.show();

      if (transition) this.$element.get(0).offsetWidth; // force reflow

      this.$element.addClass("in");

      if (transition) {
        this.$element.one("transitionend", () => {
          this.$element.trigger("shown");
        });
      } else {
        this.$element.trigger("shown");
      }
    });
  }

  hide(e) {
    e && e.preventDefault();
    if (!this.isShown) return;

    this.isShown = false;
    $("body").removeClass("modal-open");

    escape.call(this);

    this.$element.trigger("hide").removeClass("in");

    if (this.$element.hasClass("fade")) {
      hideWithTransition.call(this);
    } else {
      hideModal.call(this);
    }
  }
}

// Private methods
function hideWithTransition() {
  const timeout = setTimeout(() => {
    this.$element.off("transitionend");
    hideModal.call(this);
  }, 500);

  this.$element.one("transitionend", () => {
    clearTimeout(timeout);
    hideModal.call(this);
  });
}

function hideModal() {
  this.$element.hide().trigger("hidden");
  backdrop.call(this);
}

function backdrop(callback) {
  if (this.isShown && this.options.backdrop) {
    const animate = this.$element.hasClass("fade") ? "fade" : "";
    this.$backdrop = $(
      `<div class="modal-backdrop ${animate}"></div>`
    ).appendTo(document.body);

    if (this.options.backdrop != "static") {
      this.$backdrop.on("click", this.hide.bind(this));
    }

    if (animate) this.$backdrop.get(0).offsetWidth; // force reflow

    this.$backdrop.addClass("in");

    if (animate) {
      this.$backdrop.one("transitionend", callback);
    } else {
      callback();
    }
  } else if (!this.isShown && this.$backdrop) {
    this.$backdrop.removeClass("in");

    if (this.$element.hasClass("fade")) {
      this.$backdrop.one("transitionend", removeBackdrop.bind(this));
    } else {
      removeBackdrop.call(this);
    }
  } else if (callback) {
    callback();
  }
}

function removeBackdrop() {
  this.$backdrop.remove();
  this.$backdrop = null;
}

function escape() {
  if (this.isShown && this.options.keyboard) {
    $(document).on("keyup.dismiss.modal", (e) => e.which === 27 && this.hide());
  } else if (!this.isShown) {
    $(document).off("keyup.dismiss.modal");
  }
}
