import $ from "jquery";

import {
  Template,
  hideTooltips,
  updateLayout,
  autoloadViews,
  highlightCodeBlocks,
} from "./ui";

export class View {
  constructor(el, options = {}) {
    this.options = Object.assign({}, options);
    /** @type {App} */
    this._app = this.options._app;
    delete this.options._app;
    this.element = $(el);
    this.placeholder = this.element.find(".view-placeholder").eq(0);
    this.$templates = this.element.children('script[type="template"]');
    this.template = new Template(
      this.options.template ? $(this.options.template) : this.$templates.eq(0),
      { _app: this._app }
    );

    if (this.options.target) {
      this.target = this.options.target;
    } else {
      // use the view element as the container
      this.target = this.element.find(".view").eq(0);
      // create the view element if it doesn't exist
      if (this.target.length === 0) {
        this.target = $('<div class="view"></div>').appendTo(this.element);
      }
    }

    // initial data can be set via attribute
    this.data = this.options.data || this.element.data("view-data");

    this.filters = this.filters || this.options.filters || {};

    if (this.options.render) {
      this.on("render", this.options.render);
    }

    /** @type {Control[]} */
    this._controllers = [];

    this.element.data("view", this);
    document.addEventListener(
      "turbolinks:visit",
      () => {
        this.destroy();
      },
      { once: true }
    );
  }

  destroy() {
    if (this.destroyed) return;

    if (this._bindings) {
      this._bindings.forEach((unbind) => {
        unbind();
      });
    }

    while (this._controllers.length > 0) {
      const c = this._controllers.pop();
      if (c && !c.destroyed) c.destroy();
    }

    this.element.data("view", null);
    this.destroyed = true;
  }

  render(options) {
    if (options == null) options = {};
    this.setFilters(options.filters);
    if (options.data) this.data = options.data;

    hideTooltips();

    this.template.render(
      this.target,
      this.data || this.target.data("context"),
      this.filters,
      options
    );
    this.target.show();
    this.target.data("view", this);
    this.placeholder.hide();

    this._updateUi();

    // loop through any child templates that may have been rendered and call their event callbacks
    this._callChildPartialRenderEvents({ partial: false });

    if (this.renderEvents.view) {
      this.renderEvents.view.call(this, { options });
    }

    this.trigger("view:render", { options });

    this.rendered = true;
    this.element.addClass("js-rendered");
    return this;
  }

  trigger(event, data) {
    data = data || {};
    data.view = this;
    this.element.trigger(event, data);
  }

  on(event, handler) {
    event = `view:${event}`;
    this._bindings = this._bindings || [];
    this._bindings.push(() => this.element.off(event, null, handler));

    return this.element.on(event, null, handler);
  }

  renderPartial(selector, template, options) {
    // template is optional and options can be passed as the 2nd argument
    if (typeof template === "string") {
      options = options || {};
    } else if (!(template instanceof Template)) {
      options = template || {};
    }
    options = options || {};

    this.setFilters(options.filters);

    hideTooltips();

    let $el =
      typeof selector === "string" ? this.element.find(selector) : $(selector);

    $el = replaceRender.call(
      this,
      $el,
      $el.data("context"),
      template,
      this.filters,
      options
    );
    template = $el.data("template").name;

    const event = { element: $el, template, partial: true, options };
    // since we called replaceRender we need to specifically call the event on this element
    this._callPartialRenderEvent($el, event);
    // loop through any child templates that may have been rendered and call their event callbacks
    this._callChildPartialRenderEvents(event);

    if (this.renderEvents.partial) {
      this.renderEvents.partial.call(this, event);
    }

    this.trigger("view:render", event);

    this._updateUi();

    $el.attr("data-context", "partial");
    // returned for chaining
    return $el;
  }

  setFilters(filters) {
    if (filters) this.filters = Object.assign({}, this.filters, filters);
  }

  _callChildPartialRenderEvents(e) {
    this.target.find("[data-template-selector]").each((_, el) => {
      this._callPartialRenderEvent($(el), e);
    });
  }

  _callPartialRenderEvent($el, e) {
    const event = Object.assign({}, e);
    if ($el.data("template-selector")) {
      event.template = $el.data("template-selector").substr(1);
    }

    const renderEvent = this.renderEvents[event.template];
    if (renderEvent) {
      event.element = $el;
      renderEvent.call(this, event);
    }
  }

  _updateUi() {
    highlightCodeBlocks();
    autoloadViews(this._app);
    updateLayout();
  }
}

View.prototype.renderEvents = {};

/**
 * Convenience method for quickly rendering the view that the element belongs to.
 *
 * @param {jQuery} jq
 * @param {object} [data]
 * @param {object} [filters]
 */
export const renderView = (jq, data, filters) => {
  jq.each((_, el) => {
    const $el = $(el);
    const view = $el.data("view") || $el.closest("view").data("view");
    if (view) {
      if (filters) view.setFilters(filters);
      view.render({ data });
    }
  });
};

/**
 * Renders the contents of the template and replaces this element with those contents.
 * @this {View}
 */
function replaceRender($el, data, template, filters, options = {}) {
  template = template || $el.data("template") || $el.data("template-selector");
  if (!template) return $el;

  if (!(template instanceof Template)) {
    const $tmpl = $(template).eq(0);
    template =
      $tmpl.data("template") || new Template($tmpl, { _app: this._app });
  }

  data = data || $el.data("context") || {};
  const $temp = $("<div></div>");
  template.render($temp, data, filters, options);
  const $children = $temp.children();
  $children.data("template", template);
  $children.data("context", data);
  $el.replaceWith($children);
  return $($children);
}
