import $ from "jquery";
import axios from "axios";

import { Control } from "../../control";
import { createDisplayManager } from "../../services/display_manager";
import { CodeChallengesSocialButtons } from "./social_buttons";
import { CompareSolutionsController } from "../compare_solutions_controller";
import { CodeChallengeInfoAreas } from "../code_challenge_info_areas";
import { InfiniteScroll } from "../infinite_scroll";
import { turbolinksRender } from "../../turbolinks_utils";

import { automountComments } from "../../mounts/comments";
import { LabelVotesController } from "../label_votes_controller";

export class CodeChallengesSolutionsController extends Control {
  constructor(el, options) {
    super(el, options);
    automountComments(this._app).then(() => {
      this._controllers.push(
        new LabelVotesController($("#app"), { _app: this._app })
      );
    });
    const infoAreas = new CodeChallengeInfoAreas(this.element, {
      _app: this._app,
    });
    const socialButtons = new CodeChallengesSocialButtons(
      this.element.find(".js-train-social"),
      { _app: this._app }
    );
    const compareSolutions = new CompareSolutionsController(this.element, {
      _app: this._app,
    });
    this.infiniteScroll = new InfiniteScroll("#solutions_list", {
      _app: this._app,
    });
    createDisplayManager({
      user: this._app.currentUser,
      id: "solutions",
      partner: {
        type: "image",
        contrast: true,
        red: true,
      },
      brazeTargets: ["kata_solutions_1", "kata_solutions_2"],
    });

    this._controllers.push(
      infoAreas,
      socialButtons,
      compareSolutions,
      this.infiniteScroll
    );

    this.initViewValues();
  }

  initViewValues() {
    const viewControl = document.getElementById("view-control");
    if (!viewControl) return;

    const data = new FormData(viewControl);
    this.filter = data.get("filter");
    this.sort = data.get("sort");
    this.invalids = data.get("invalids") === "true";
  }

  filterUpdated() {
    const data = new FormData(document.getElementById("view-control"));
    const filter = data.get("filter");
    const sort = data.get("sort");
    const invalids = data.get("invalids") === "true";

    if (
      filter !== this.filter ||
      sort !== this.sort ||
      invalids !== this.invalids
    ) {
      const url = new URL(location.href);
      // Fix the URL if it's the old format
      const oldPath = `/${this.filter}/${this.sort}`;
      if (url.pathname.endsWith(oldPath)) {
        url.pathname = url.pathname.slice(0, -oldPath.length);
      }

      this.filter = filter;
      this.sort = sort;
      this.invalids = invalids;
      url.searchParams.set("filter", filter);
      url.searchParams.set("sort", sort);
      url.searchParams.set("invalids", invalids);
      Turbolinks.clearCache();
      Turbolinks.visit(url.toString());
    }
  }
}

CodeChallengesSolutionsController.prototype.events = {
  /**
   * @this {CodeChallengesSolutionsController}
   */
  ".js-admin-action click"($el) {
    axios.post($el.data("href")).then((response) => {
      const json = response.data;
      alert(`Action ${json.success ? "successfully queued" : "failed"}`);
    });
  },

  /** @this {CodeChallengesSolutionsController} */
  ".js-unlock-solutions click"($el) {
    axios
      .post(this._app.route("unlock_solutions"), {}, { responseType: "text" })
      .then((response) => {
        turbolinksRender(response.data);
      });
  },

  /** @this {CodeChallengesSolutionsController} */
  ".js-unlock-test-cases click"($el) {
    axios
      .post(this._app.route("unlock_test_cases"), {}, { responseType: "text" })
      .then((response) => {
        turbolinksRender(response.data);
      });
  },

  /** @this {CodeChallengesSolutionsController} */
  "#view-control sl-radio-group sl-change"(_) {
    this.filterUpdated();
  },
  /** @this {CodeChallengesSolutionsController} */
  "#view-control sl-checkbox sl-change"(_) {
    this.filterUpdated();
  },
};
