import { Controller } from "@stimulus/core";
import DOMPurify from "dompurify";
import axios from "axios";

import { slideDown, slideUp } from "../app/animation";
import { highlightCode } from "../app/ui";

const SHOW = "Show Variations";
const HIDE = "Hide Variations";

export default class extends Controller {
  static targets = ["container"];
  static values = {
    reviewId: String,
    groupId: String,
  };

  initialize() {
    this.fetched = false;
  }

  /**
   * Toggle solution variations.
   */
  toggle(event) {
    event.stopPropagation();
    const el = event.currentTarget;
    const container = this.containerTarget;

    if (el.textContent === SHOW) {
      if (!this.fetched) {
        const msg = document.createElement("p");
        msg.textContent = "Fetching variations...";
        container.appendChild(msg);
        slideDown(container);
        const reviewId = this.reviewIdValue;
        const groupId = this.groupIdValue;
        axios
          .get(`/kata/reviews/${reviewId}/groups/${groupId}/variations`)
          .then((response) => {
            const data = response.data;
            if (data.variations.length > 0) {
              const language = data.language;
              for (const v of data.variations) {
                const pre = document.createElement("pre");
                pre.setAttribute("lang", language);
                pre.classList.add("p-2");
                pre.innerHTML = DOMPurify.sanitize(
                  `<code>${highlightCode(v, language)}</code>`
                );
                container.appendChild(pre);
              }
            }
            this.fetched = true;
            container.removeChild(msg);
            el.textContent = HIDE;
          });
      } else {
        slideDown(container);
        el.textContent = HIDE;
      }
    } else {
      slideUp(container);
      el.textContent = SHOW;
    }
  }
}
