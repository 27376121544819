import $ from "jquery";
import axios from "axios";
import Turbolinks from "turbolinks";

import { Control } from "../../control";
import { locationParams, param } from "../../utils";
import { visitRefreshed } from "../../turbolinks_utils";
import cable from "../../../channels/cable";
import UsersComparisonsChannel from "../../../channels/users_comparisons";

export class UsersComparisonsShowController extends Control {
  constructor(el, options) {
    super(el, options);
    if (this._app.data.refreshing) {
      const channel = new UsersComparisonsChannel({
        compared_user_id: this._app.data.compared_user_id,
      });
      cable.subscribe(channel);
      channel.once("refresh", (_message) => {
        visitRefreshed(window.location.pathname);
      });
      this._unregisters.push(() => channel.disconnect());

      // Check once and refresh or wait for the `refresh` event
      axios.get(this._app.route("check")).then((response) => {
        const json = response.data;
        if (json.ready) visitRefreshed(window.location.pathname);
      });
    }
  }

  search() {
    const params = Object.assign({}, locationParams(), {
      r: $("#rank").val(),
      l: $("#language").val(),
      q: $("#search-input").val(),
      page: null,
    });
    for (const k of Object.keys(params)) {
      if (params[k] === null) delete params[k];
    }
    Turbolinks.clearCache();
    Turbolinks.visit(`${window.location.pathname}?${param(params)}`);
  }
}

UsersComparisonsShowController.prototype.events = {
  /** @this {UsersComparisonsShowController} */
  "#search click"() {
    this.search();
  },

  /** @this {UsersComparisonsShowController} */
  "#rank change"() {
    this.search();
  },

  /** @this {UsersComparisonsShowController} */
  "#language change"() {
    this.search();
  },

  /** @this {UsersComparisonsShowController} */
  "#search-input keypress"(_, e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.search();
    }
  },
};
