import {
  initialize,
  changeUser,
  openSession,
  requestContentCardsRefresh,
} from "@braze/web-sdk";

export const initBraze = (braze) => {
  initialize(braze.key, {
    baseUrl: braze.url,
    enableSdkAuthentication: true,
  });
  changeUser(braze.id, braze.jwt);
  // Always refresh content cards when initializing SDK for `getCachedContentCards()`.
  // By default, content cards only refreshes when opening a new session with a subscription.
  // Sessions remain open for at least 30 minutes of inactivity.
  requestContentCardsRefresh(
    () => {
      console.debug("braze: requested content cards refresh");
    },
    () => {
      console.error("braze: failed request content cards refresh");
    }
  );
  openSession();
  console.debug("braze: opened session");
};
