import $ from "jquery";
import Turbolinks from "turbolinks";

import { Control } from "../control";

export class DropdownsController extends Control {
  constructor(el, options) {
    super(el, options);
  }

  toggle($dropdown) {
    const focus = $dropdown && !$dropdown.is(".is-focused");
    $(".dropdown.is-focused").removeClass("is-focused");
    if (focus) $dropdown.addClass("is-focused");
  }
}

DropdownsController.prototype.events = {
  /**
   * @this {DropdownsController}
   */
  ".dropdown click"($el) {
    this.toggle($el);
  },
  /**
   * @this {DropdownsController}
   */
  ".dropdown dl dd .info mouseenter"($el) {
    $el.closest("dd").addClass("show-info");
  },
  ".dropdown dl dd .info mouseleave"($el) {
    $el.closest("dd").removeClass("show-info");
  },

  /**
   * @this {DropdownsController}
   */
  click($el, e) {
    if ($(e.target).closest(".dropdown").length === 0) this.toggle();
  },

  /**
   * @this {DropdownsController}
   */
  ".dropdown dd:not(.is-disabled) click"($el) {
    const $dropdown = $el.closest(".dropdown");
    $dropdown.children("span").html($el.find("> span").html() || $el.html());
    $dropdown.find("dd.is-active").removeClass("is-active");
    $el.addClass("is-active");

    if ($el.data("load") !== undefined && $el.data("href")) {
      Turbolinks.visit($el.data("href"));
    }
  },
};
