import $ from "jquery";
import axios from "axios";
import Turbolinks from "turbolinks";
import { connectToChild, ErrorCode } from "penpal";
import * as Sentry from "@sentry/browser";
import swal from "sweetalert";

import { Control } from "../control";

const FRAME_TIMEOUT_SEC = 20;

export class RunnerIFrame extends Control {
  constructor(el, options) {
    super(el, options);

    this.src = "";
    this.connection = null;
    this.connectionDestroyed = false;
    this.darkMode = this._app.darkMode;
    // We use `data-src` to load the iframe at the right timing for Penpal.
    // This also allows us to load the frame with the correct theme.
    const dataSrc = this.element.data("src");
    if (dataSrc) {
      this.connect(
        this.darkMode ? dataSrc : dataSrc.replace("theme=dark", "theme=day")
      );
    }
  }

  destroy() {
    // Disconnect messaging channel.
    this.destroyConnection();

    Turbolinks.clearCache();
    super.destroy();
  }

  connect(src) {
    this.src = src;
    const original = this.element.get(0);
    const iframe = original.cloneNode();
    iframe.classList.remove("invisible");
    this.element = $(iframe);
    this.element.attr("src", src);
    this.connection = connectToChild({
      timeout: FRAME_TIMEOUT_SEC * 1000,
      iframe,
      methods: {
        onReady: () => {},
        onReceipt: (data) => {
          this.element.trigger(`message.notifyReceipt`, data);
        },
        authorize: async () => {
          try {
            const res = await axios.post("/api/v1/runner/authorize");
            return res.data.token;
          } catch (e) {
            Sentry.captureException(e, {
              response: e.response && e.response.data,
            });
            this._app.alerts.fail("Authorization Failed");
          }
        },
      },
    });
    this.connectionDestroyed = false;
    // Append iframe to DOM after calling `connectToChild` to ensure the correct timing.
    original.parentNode.replaceChild(iframe, original);
  }

  async run(data) {
    if (!this.connection) return;

    this.request = data;

    const child = await this.getChild();
    if (!child) return;

    this.element.show();
    try {
      const response = await child.run(data);
      if (response.result) {
        this.response = response.result;
        this.element.trigger(`message.notifyResponse`, this.response);
      } else if (response.error) {
        Sentry.captureMessage(response.error);
      }
    } catch (e) {
      if (e.code === ErrorCode.ConnectionDestroyed && this.connectionDestroyed)
        return;

      Sentry.captureException(e);
      console.error(e);
    }
  }

  async display(data) {
    if (!this.connection) return;

    const child = await this.getChild();
    if (!child) return;

    try {
      child.display(data);
    } catch (e) {
      if (e.code === ErrorCode.ConnectionDestroyed && this.connectionDestroyed)
        return;

      Sentry.captureException(e);
      console.error(e);
    }
  }

  expand() {
    if (this.expanded) return;
    const dsrc = this.element.data("expand");
    if (!dsrc) return;

    this.connect(dsrc);
    this.expanded = true;
  }

  async setTheme(isDark) {
    if (!this.connection) return;
    if (this.darkMode === isDark) return;

    this.element.show();

    const child = await this.getChild();
    if (!child) return;

    try {
      child.setOptions({ theme: isDark ? "night" : "day" });
      this.darkMode = isDark;
    } catch (e) {
      if (e.code === ErrorCode.ConnectionDestroyed && this.connectionDestroyed)
        return;

      Sentry.captureException(e);
      console.error(e);
    }
  }

  async getChild() {
    if (!this.connection) return null;

    try {
      return await this.connection.promise;
    } catch (e) {
      if (e.code === ErrorCode.ConnectionDestroyed) {
        if (!this.connectionDestroyed) {
          Sentry.captureException(e);
          console.error(e);
        }

        return null;
      }

      if (e.code === ErrorCode.ConnectionTimeout) {
        if (this.connectionDestroyed) return null;

        Sentry.captureMessage("Runner frame connection timed out");
        const url = new URL(this.src);
        const runnerUrl = `${url.protocol}//${url.host}`;
        return this.onConnectionFailure({
          title: "Connection Timed Out",
          content: [
            `<p>Timed out while loading the runner frame.</p>`,
            `<p>Please verify that nothing is preventing access to`,
            `<a class="underline" href="${runnerUrl}" target="_blank" rel="noopener">${runnerUrl}</a>,`,
            `such as ad-blockers or firewall rules.</p>`,
          ].join(" "),
        });
      }

      Sentry.captureException(e);
      console.error(e);
      return this.onConnectionFailure({
        title: "Unknown Failure",
        content: [
          "<p>Unexpected error occured while connecting to the runner frame.",
          `If the problem persists, please let us know any details by opening an issue on`,
          `<a class="underline" href="https://github.com/codewars/codewars.com" target="_blank" rel="noopener">GitHub</a>.</p>`,
        ].join(" "),
      });
    }
  }

  destroyConnection() {
    if (!this.connection) return;

    try {
      // Swallow any error from the promise because it rejects even if it's on purpose.
      // https://github.com/Aaronius/penpal/issues/51#issuecomment-642037002
      this.connection.promise.catch(() => {});
      this.connection.destroy();
    } catch (error) {
      if (error.code !== ErrorCode.ConnectionDestroyed) throw error;
    }
    this.connection = null;
    this.connectionDestroyed = true;

    this.unloadFrame();
  }

  unloadFrame() {
    // Need to replace the connected iframe with iframe without `src` because
    // Penpal requires to call `connectToChild` before the child calls `connectToParent`,
    // and the DOM persists across this class' lifecycle.
    const node = this.element.get(0);
    node.removeAttribute("src");
    const clone = node.cloneNode();
    clone.classList.add("invisible");
    node.parentNode.replaceChild(clone, node);
    this.element = $(clone);
  }

  reconnect() {
    this.destroyConnection();
    this.connect(this.src);
  }

  async onConnectionFailure({ title, content }) {
    const div = document.createElement("div");
    div.innerHTML = content;
    const value = await swal({
      title,
      content: div,
      icon: "error",
      buttons: {
        reconnect: true,
      },
    });

    switch (value) {
      case "reconnect":
        this.reconnect();
        return this.getChild();

      default:
        return null;
    }
  }
}
