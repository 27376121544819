import { Control } from "../control";

export class FormsController extends Control {
  constructor(el, options) {
    super(el, options);
  }
}

FormsController.prototype.events = {
  /**
   * @this {FormsController}
   */
  ".form-field input focus"(el) {
    el.closest(".form-field").addClass("active");
  },

  /**
   * @this {FormsController}
   */
  ".form-field input blur"(el) {
    el.closest(".form-field").removeClass("active");
  },

  /**
   * @this {FormsController}
   */
  ".form-field .prefix i click"(el) {
    const field = el.closest(".form-field").find("input").get(0);
    if (field != null) field.focus();
  },
};
