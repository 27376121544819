import $ from "jquery";
import Turbolinks from "turbolinks";

import { Control } from "../control";

const DARK_MODE_KEY = "dark-mode";
const TRANSITION_CLS = "theme-transition";

export class ThemeController extends Control {
  constructor(el, options) {
    super(el, options);

    this.isDark = false;
    this.initDarkMode();
  }

  initDarkMode() {
    // HACK Never enable dark mode on landing for now
    if (document.getElementById("landing")) {
      return;
    }

    const stored = localStorage.getItem(DARK_MODE_KEY);
    if (stored !== null) return this.toggleDarkMode(stored === "true");

    // Not using `window.matchMedia` for now and default to dark mode.
    // if (window.matchMedia) {
    //   return this.toggleDarkMode(
    //     window.matchMedia("(prefers-color-scheme: dark)").matches
    //   );
    // }
    this.toggleDarkMode(true);
  }

  toggleDarkMode(enableDark) {
    this.isDark = !!enableDark;
    const classes = ["dark", "sl-theme-dark"];
    if (this.isDark) {
      document.documentElement.classList.add(...classes);
    } else {
      document.documentElement.classList.remove(...classes);
    }
    localStorage.setItem(DARK_MODE_KEY, this.isDark + "");

    this._app.darkMode = this.isDark;
    $("#app").trigger("dark-mode-toggled", [this.isDark]);
  }
}

ThemeController.prototype.events = {
  /** @this {ThemeController} */
  ".js-toggle-dark-mode click"($el) {
    document.documentElement.classList.add(TRANSITION_CLS);
    const isDark = document.documentElement.classList.contains("dark");
    this.toggleDarkMode(!isDark);
    Turbolinks.clearCache();
    setTimeout(() => {
      document.documentElement.classList.remove(TRANSITION_CLS);
    }, 1000);
  },
};
