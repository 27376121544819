import $ from "jquery";
import Turbolinks from "turbolinks";

import { Control } from "../control";

export class Header extends Control {
  constructor(el, options) {
    super(el, options);
    this.adjustMaxHeight();
  }

  adjustMaxHeight() {
    $(".menu-body").css(
      "max-height",
      $(window).height() - $("#main_header").height()
    );
  }
}

Header.prototype.events = {
  /**
   * @this {Header}
   */
  "window resize"() {
    this.adjustMaxHeight();
  },

  /**
   * @this {Header}
   */
  "#main_header .has-menu click"($el) {
    if ($(window).width() < 768) {
      $el.toggleClass("active");
    }
  },

  /**
   * @this {Header}
   */
  "#header_profile_link click"($el) {
    // Open the menu instead of navigating to the profile page when narrow screen
    if ($(window).width() < 768) {
      $el.parent(".has-menu").toggleClass("active");
      return false;
    }
  },

  /**
   * @this {Header}
   */
  "body .sidenav-nubbin click"() {
    $("#app").toggleClass("shift-right");
  },

  /** @this {Header} */
  ".js-sign-out click"(_) {
    Turbolinks.clearCache();
    // Handle this the same way as jquery-ujs.
    const form = $('<form method="post" action="/users/sign_out"></form>');
    const csrfParam = $("meta[name=csrf-param]").attr("content");
    const csrfToken = $("meta[name=csrf-token]").attr("content");
    const metadataInput = [
      `<input name="_method" value="DELETE" type="hidden" />`,
      `<input name="${csrfParam}" value="${csrfToken}" type="hidden" />`,
    ].join("");
    form.hide().append(metadataInput).appendTo("body");
    form.trigger("submit");
  },
};
