// Deeply gets the value at path from source.
// This also adds any intermediate objects for backwards compatibility.
// TODO Can this return undefined earlier instead?
export const ns = (path, source = {}) => {
  const parts = path.split(".");
  const n = parts.length;
  return parts.reduce((result, part, i) => {
    // Ignore "App" part of the path if it is passed in
    if (part === "App" && i === 0) return result;

    // Create unless this is the last one
    if (i < n - 1 && !result[part]) result[part] = {};
    return result[part];
  }, source);
};

export const token = () => Math.random().toString(36).substr(2);

const queryParams = (url) => {
  const params = {};
  for (const [k, v] of new URL(url).searchParams.entries()) {
    if (k.endsWith("[]")) {
      // Array
      const key = k.slice(0, -2);
      if (!params[key]) params[key] = [];
      params[key].push(v);
    } else {
      params[k] = v;
    }
  }
  return params;
};

export const locationParams = () => queryParams(window.location.href);

// Need to use `window.history.state` because it contains `turbolinks: {}` that's necessary
// to make Turbolinks aware of this replaced state.
// https://github.com/turbolinks/turbolinks/issues/219#issuecomment-278320607
// https://github.com/turbolinks/turbolinks/issues/219#issuecomment-376973429
export const replaceHistoryState = (url) => {
  window.history.replaceState(window.history.state, document.title, url);
};

export const pushHistoryState = (url) => {
  window.history.pushState(window.history.state, document.title, url);
};

export const toUpperFirst = (str) => str[0].toUpperCase() + str.substr(1);
export const toLowerFirst = (str) => str[0].toLowerCase() + str.substr(1);
export const toDash = (str) => toTransformedCamel(str, "-");

const toTransformedCamel = (str, prefix) => {
  const v = str.replace(/([A-Z])/g, (m) => prefix + m.toLowerCase());
  return v.startsWith(prefix) ? v.substr(1) : v;
};

export const escapeRegExp = (str) =>
  str.replace(/([\\/'*+?|()\[\]{}.^$])/g, "\\$1");

export const escapeHtml = (html) => {
  const div = document.createElement("div");
  div.textContent = html;
  return div.innerHTML;
};

export const randomizedArray = (xs) => {
  const arr = xs.slice();
  let i = arr.length;
  let j, x;
  while (i) {
    j = (Math.random() * i) | 0;
    x = arr[--i];
    arr[i] = arr[j];
    arr[j] = x;
  }
  return arr;
};

export const sampleArray = (xs, num) => {
  const arr = randomizedArray(xs);
  return typeof num == "number" ? arr.slice(0, num) : arr[0];
};

export const debounce = (fn, ctx = null) => {
  let timeout;
  return (...args) => {
    if (timeout) window.cancelAnimationFrame(timeout);
    timeout = window.requestAnimationFrame(() => {
      fn.call(ctx, ...args);
    });
  };
};

export const param = (obj = {}) =>
  Object.keys(obj)
    .map((key) => {
      const val = obj[key];
      if (!Array.isArray(val))
        return encodeURIComponent(key) + "=" + encodeURIComponent(val);

      const kvs = [];
      for (let i = 0; i < val.length; i++) {
        kvs.push(
          encodeURIComponent(key + "[]") + "=" + encodeURIComponent(val[i])
        );
      }
      return kvs.join("&");
    })
    .filter(Boolean)
    .join("&");

export const notify = (
  message,
  { variant = "primary", duration = 3000, icon } = {}
) => {
  if (!icon) {
    switch (variant) {
      case "success":
        icon = "check2-circle";
        break;
      case "neutral":
        icon = "gear";
        break;
      case "warning":
        icon = "exclamation-triangle";
        break;
      case "danger":
        icon = "exclamation-octagon";
        break;
      case "primary":
      default:
        icon = "info-circle";
        break;
    }
  }

  const alert = Object.assign(document.createElement("sl-alert"), {
    variant,
    closable: true,
    duration: duration,
    innerHTML: `
        <sl-icon name="${icon}" slot="icon"></sl-icon>
        ${escapeHtml(message)}
      `,
  });
  document.body.append(alert);
  return alert.toast();
};
