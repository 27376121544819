import delay from "delay";

export const wait = (duration, cb) => {
  const abortController = new AbortController();

  (async () => {
    try {
      await delay(duration, { signal: abortController.signal });
      if (cb) cb();
    } catch (error) {
      if (error.name === "AbortError") return;
      throw error;
    }
  })();

  return abortController;
};
