import $ from "jquery";
import axios from "axios";

import { Control } from "../control";
import { mountAppWithManualUnmount } from "../vue";

import cable from "../../channels/cable";
import SyncChannel from "../../channels/sync";

export class CollectionModal extends Control {
  constructor(el, options) {
    super(el, options);

    const getRoute = this._app.route.bind(this._app);
    const hide = this.hide.bind(this);
    const [modal, unmount] = mountAppWithManualUnmount(
      "#collection-modal-view",
      {
        canAdd: this._app.currentUser.honor >= 20,
        newCollectionName: "",
        current: {},
        collections: [],
        loading: true,
        adding: false,
        isInCollection(collection) {
          const challengeId = this.current?.id;
          if (!collection.code_challenges) return false;

          return (
            collection.code_challenges.find(
              (c) => c.code_challenge_id === challengeId
            ) != null
          );
        },
        addChallenge(collection) {
          const challengeId = this.current?.id;
          const route = getRoute("collection_code_challenge", {
            collectionId: collection.id,
            id: "",
          });
          axios.post(route, { id: challengeId });
        },
        removeChallenge(collection) {
          const challengeId = this.current?.id;
          const route = getRoute("collection_code_challenge", {
            collectionId: collection.id,
            id: challengeId,
          });
          axios.delete(route);
        },
        createCollection() {
          const name = this.newCollectionName;
          const challengeId = this.current.id;
          axios
            .post(getRoute("collections"), {
              name,
              code_challenge_id: challengeId,
            })
            .then((_) => {
              this.newCollectionName = "";
              this.hide();
            });
        },
        hide,
      }
    );
    this.modal = modal;
    this.unmount = unmount;

    this.store = ApiSyncStore.get("personal_collections", {
      url: this._app.route("collections"),
      realtime: "collection",
    });
  }

  destroy() {
    this.unmount();
    this.hide();
    super.destroy();
  }

  disableBtns() {
    this.element.find("a").addClass("is-disabled");
  }

  show(challengeToAdd) {
    this.modal.current = challengeToAdd;
    this.modal.loading = true;
    this.modal.collections = [];
    requestAnimationFrame(() => {
      this.element.show();
      this.refreshCollections();
    });
  }

  refreshCollections() {
    this.store.getAll((items) => {
      this.modal.collections = items;
      this.modal.adding = false;
      this.modal.loading = false;
    });
  }

  hide() {
    this.element.hide();
  }

  // TODO Extract API and share with petite-vue
  // Used by `CollectionsShowController`
  removeChallenge(collection, challengeId, success) {
    const route = this._app.route("collection_code_challenge", {
      collectionId: collection.id,
      id: challengeId,
    });
    axios.delete(route).then((_) => {
      if (typeof success === "function") success();
    });
  }
}

CollectionModal.prototype.events = {
  /** @this {CollectionModal} */
  "#app store.personal_collections.changed"() {
    // HACK Refresh view
    this.modal.collections = [];
    window.requestAnimationFrame(() => {
      this.refreshCollections();
    });
  },

  /** @this {CollectionModal} */
  "body .js-add-to-collection click"($el) {
    this.show($el.data("challenge"));
  },
};

// Very simple store implementation for loading results from the server and then
// counting on realtime updates to keep it in sync.
const named = {};
class ApiSyncStore {
  static get(name, options) {
    options.name = name;
    let existing = named[name];
    if (!existing) {
      existing = named[name] = new ApiSyncStore(options);
    }
    return existing;
  }

  constructor(options = {}) {
    this.options = options;
  }

  getAll(callback) {
    if (this.items) {
      callback(this.items);
    } else {
      axios.get(this.options.url).then((response) => {
        const json = response.data;
        this.response = json;
        this.items = json.items;
        callback(this.items);

        if (this.options.realtime) {
          const channel = new SyncChannel();
          cable.subscribe(channel);
          channel.on(
            `${this.options.realtime}_saved`,
            this.handleModelSaved.bind(this)
          );
          channel.on(
            `${this.options.realtime}_destroyed`,
            this.handleModelDestroyed.bind(this)
          );
        }
      });
    }
  }

  handleModelSaved(data) {
    if (this.items && data.id) {
      const existing = this.items.find((item) => item.id === data.id);

      if (existing) {
        for (const key of Object.keys(existing)) {
          if (!data.hasOwnProperty(key)) delete existing[key];
        }
        Object.assign(existing, data);
        this.storeChanged("updated", existing);
      } else {
        this.items.push(data);
        this.storeChanged("added", data);
      }
    }
  }

  handleModelDestroyed(data) {
    const index = this.items.findIndex((item) => item.id === data.id);

    if (index !== -1) {
      const existing = this.items[index];
      this.items.splice(index, 1);
      this.storeChanged("destroyed", existing);
    }
  }

  storeChanged(_event, model) {
    const name = this.options.name || this.options.realtime;
    $("#app").trigger(`store.${name}.changed`, { store: this, model });
  }
}
