import $ from "jquery";
import axios from "axios";
import Turbolinks from "turbolinks";

import { Control } from "../../control";
import { createDisplayManager } from "../../services/display_manager";
import { InfiniteScroll } from "../infinite_scroll";
import { replaceHistoryState } from "../../utils";
import { markdownWithLanguage } from "../markdown_display";
import { mountApp } from "../../vue";

export class DashboardIndexController extends Control {
  constructor(el, options) {
    super(el, options);
    this.discourseScroll = new InfiniteScroll(".comments-list", {
      _app: this._app,
    });
    this._controllers.push(this.discourseScroll);

    this.nextChallenge = mountApp("#trainer-next-challenge", {
      loading: true,
      name: "",
      href: "",
      rankingHtml: "",
      description: "",
      systemTags: [],
      setValues(json) {
        this.name = json.name;
        this.href = json.href;
        this.rankingHtml = json.rankingHtml;
        this.systemTags = json.systemTags;
        this.description = markdownWithLanguage(
          json.description,
          json.language
        );
      },
      clear() {
        this.name = "";
        this.href = "";
        this.rankingHtml = "";
        this.description = "";
        this.systemTags = [];
      },
    });

    this.loadTrainerChallenge();
    createDisplayManager({
      user: this._app.currentUser,
      id: "dashboard",
      partner: {
        type: "image",
      },
      brazeTargets: ["dashboard_1", "dashboard_2"],
    }).then((dm) => (this.displayManager = dm));

    const survey = $("#survey");
    if (survey.length) {
      // TODO: update as we roll out different surveys
      const surveyName = "2022survey";
      if (!window.localStorage.getItem(surveyName)) {
        survey.show();
        window.handleSurveySubmitted = () => {
          window.localStorage.setItem(surveyName, true);
          survey.hide();
        };
      }
    }
  }

  reloadAd() {
    if (!this.adWaiting) {
      this.displayManager.refresh();
      // show ads for at least 5 seconds
      setTimeout(() => (this.adWaiting = false), 5000);
      this.adWaiting = true;
    }
  }

  loadDiscourse(view) {
    const url = this._app.route("discourse", { view_id: view });
    replaceHistoryState(url);
    this.discourseScroll.replaceLoad(url);

    $("#discourse_filters .is-active").removeClass("is-active");
    $(`#discourse_filters [data-view-id='${view}']`).addClass("is-active");
  }

  trainerRouteParams() {
    return {
      language:
        $("#trainer_language dd.is-active").data("language") ||
        this._app.currentUser.current_language,
      strategy: $("#trainer_strategy dd.is-active").data("strategy"),
    };
  }

  train(skipAutoLoad) {
    if (this.trainerData) {
      const params = this.trainerRouteParams();
      params.id = this.trainerData.id;
      if (params.id) {
        Turbolinks.clearCache();
        Turbolinks.visit(this._app.route("train", params));
      }
    } else if (!skipAutoLoad) {
      // if for some reason the data is not loaded then do so now
      this.loadTrainerChallenge(true, true);
    }
  }

  loadTrainerChallenge(dequeue, train) {
    const params = this.trainerRouteParams();
    const path = this._app.route("peek", params);
    this.nextChallenge.loading = true;
    axios.get(path, { params: { dequeue: !!dequeue } }).then((response) => {
      this.nextChallenge.loading = false;
      const json = response.data;
      if (json.name) {
        if (this.rankColor !== json.rankColor) {
          $("#trainer").removeClass(`bg-gradient-${this.rankColor}-belt`);
        }
        this.rankColor = json.rankColor;
        $("#trainer").addClass(`bg-gradient-${json.rankColor}-belt`);
        this.nextChallenge.setValues(json);
      } else {
        this.nextChallenge.clear();
      }
      this.trainerData = json;
      if (train) this.train(false);
    });
  }
}

DashboardIndexController.prototype.events = {
  /**
   * @this {DashboardIndexController}
   */
  ".personal-trainer .dropdown dd:not(.is-active) click"($el) {
    this.loadTrainerChallenge();
  },

  /**
   * @this {DashboardIndexController}
   */
  "#personal-trainer-play click"($el) {
    this.train();
  },

  /**
   * @this {DashboardIndexController}
   */
  "#personal-trainer-skip click"($el) {
    this.loadTrainerChallenge(true);
    this.reloadAd();
  },

  /**
   * @this {DashboardIndexController}
   */
  "#tell_me_more_referrals click"($el) {
    document.getElementById("referral").style = "display: none !important;";
    $("#referrals").show();
  },

  /**
   * @this {DashboardIndexController}
   */
  "#discourse_filters a:not(.is-active) click"($el, e) {
    this.loadDiscourse($el.data("view-id"));
  },
};
