import { Channel } from "@anycable/web";

export default class UserStatsChannel extends Channel {
  static identifier = "UserStatsChannel";

  receive(message, meta) {
    if (message.event === "ready") return this.emit("refresh", message);

    super.receive(message, meta);
  }
}
