import $ from "jquery";
import { marked } from "marked";
import DOMPurify from "dompurify";
import katex from "katex";

import { Control } from "../control";
import { highlightCode } from "../ui";

export class MarkdownDisplay extends Control {
  constructor(el, options) {
    super(el, options);
    this.emptyText = this.options.emptyText || this.element.html() || "";
    this.setLanguage(
      this.options.lang ||
        this.options.language ||
        this.element.data("language") ||
        this._app.data.language
    );
    this.setMarkdown(this.options.markdown || this.element.data("markdown"));
  }

  setMarkdown(markdown) {
    if (markdown == null) markdown = this.markdown;
    this.markdown = markdown;
    this.element.html(
      markdownWithLanguage(markdown || this.emptyText, this.lang)
    );
    this._processLinks();
  }

  setLanguage(lang) {
    this.lang = lang;
    if (this.markdown) this.setMarkdown();
  }

  _processLinks() {
    this.element.find("a").each((i, el) => {
      el.target = "_blank";
      $(el).on("click", (e) => e.stopPropagation());
    });
  }
}

export const markdownWithLanguage = (markdown, language, renderStyles) => {

  const forbidTags = renderStyles ? [] : ["style"];

  const html = DOMPurify.sanitize(
    marked(
      markdown,
      Object.assign({}, markedOptions, {
        renderer: new CustomRenderer({ language }),
      }),
    ), { FORBID_TAGS: forbidTags }
  );

  const tmp = document.createElement("div");
  tmp.innerHTML = html;
  // Disable Turbolinks so `:target` selector works when linked to comments
  for (const link of tmp.querySelectorAll("a")) {
    link.setAttribute("data-turbolinks", "false");
  }
  const examples = [];
  const languages = [];
  for (const pre of tmp.querySelectorAll("pre")) {
    // loop through each pre that is paired with another pre.
    // this way if there is only one pre or they don't immediately follow each other
    // then we will ignore them
    if (
      pre.nextElementSibling?.tagName?.toUpperCase() === "PRE" ||
      pre.previousElementSibling?.tagName?.toUpperCase() === "PRE"
    ) {
      const code = pre.querySelector("code[class^=language-]");
      if (code) {
        const lang = code.className.replace(/language-/, "");
        languages.push(lang);
        examples.push([lang, pre]);
      }
    }
  }
  if (languages.length === 0) return tmp.innerHTML;

  const fallbackLanguage = languages[0];
  const hasExample = languages.includes(language);
  for (const [lang, pre] of examples) {
    if (lang === language) continue;
    // if the language being filtered for isn't actually including in the list of examples, then
    // we will use the fallback
    // otherwise if the language is available in the list of possible languages, we will filter it out if its not current
    if (hasExample || lang !== fallbackLanguage) pre.style.display = "none";
  }
  return tmp.innerHTML;
};

const matchIfBlockLanguage = (text, language) =>
  text
    .replace(/^if(-not)?:/, "")
    .split(",")
    .includes(language);

const markedOptions = {
  // Use GFM. Default: true
  gfm: true,
  // Add <br> on single line break. Requires `gfm`. Default: false
  breaks: false,
  // Use smarter list behavior than those found in markdown.pl. Default: false
  smartLists: true,
  // Returns HTML to be inserted inside `<pre><code class="language-LANG"></code></pre>`
  highlight: (code, lang) => highlightCode(code, lang),
};

// Extend marked to do custom rendering.
// See https://marked.js.org/#/USING_PRO.md#renderer
class CustomRenderer extends marked.Renderer {
  constructor(options = {}) {
    let language = "";
    if (options.language) {
      language = options.language;
      delete options.language;
    }
    super(options);
    this._language = language;
  }

  codespan(code) {
    if (code && code.length > 2 && code.startsWith("$") && code.endsWith("$")) {
      const math = processKaTeX(code.slice(1, -1));
      if (math) return math;
    }
    return super.codespan(code);
  }

  code(code, infostring, escaped) {
    if (infostring) {
      // handle if/if-not blocks
      if (/^if:/.test(infostring)) {
        return matchIfBlockLanguage(infostring, this._language)
          ? marked(code, Object.assign({}, this.options, { renderer: this }))
          : "";
      }

      if (/^if-not:/.test(infostring)) {
        return matchIfBlockLanguage(infostring, this._language)
          ? ""
          : marked(code, Object.assign({}, this.options, { renderer: this }));
      }

      // Handle special % prefixed UI/alert blocks.
      // These blocks wrap the contents inside of a div.
      // Useful for info, warn, default, etc.
      if (infostring[0] === "%") {
        return (
          `<div class="block block--${infostring.substr(1)}">` +
          marked(code, Object.assign({}, this.options, { renderer: this })) +
          `</div>`
        );
      }

      // Render Math type setting with KaTex.
      // If KaTex is not loaded, it's highlighted as LaTex
      if (infostring === "math") {
        const math = processKaTeX(code);
        if (math) return `<div>${math}</div>`;
      }
    }

    try {
      return super.code(code, infostring, escaped);
    } catch (ex) {
      console.warn("Failed to highlight markdown code block", ex.message);
      return `<pre><code>${code}</code></pre>`;
    }
  }
}

const processKaTeX = (code) =>
  katex.renderToString(code, { throwOnError: false });
