import Shepherd from "shepherd.js";

export const startDemo = async () => {
  const tour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
      scrollTo: { behavior: "smooth", block: "center" },
      classes: "codewars",
      arrow: false,
      buttons: [
        {
          text: "Back",
          action() {
            return this.back();
          },
        },
        {
          text: "Next",
          action() {
            return this.next();
          },
        },
      ],
    },
    steps: [
      {
        title: "Getting Started",
        text: "Let's go through what each component is for.",
        buttons: [
          {
            text: "OK",
            action() {
              return this.next();
            },
          },
        ],
      },
      {
        title: "Name",
        text: `\
The name is used to uniquely identify a kata.
The best practice is to use a name that gives other users a good idea of what type of challenge they will be getting themselves into.`,
        attachTo: {
          element: '[data-tour="name"]',
          on: "bottom-start",
        },
        buttons: [
          {
            text: "Next",
            action() {
              return this.next();
            },
          },
        ],
      },
      {
        title: "Discipline",
        text: `
<div class="prose">
<p>The discipline is the category that the kata belongs to.
You should pick the category that best describes what the kata is intended to focus on.</p>
<ul>
<li>Fundamentals: Focus on core language and API knowledge</li>
<li>Algorithms: Focus on the logic required to complete the task</li>
<li>Bug Fixes: Focus on fixing the code</li>
<li>Refactoring: Focus on transforming and improving the code</li>
<li>Puzzles: Focus on creativity and solving puzzles</li>
</ul>
</div>
`,
        attachTo: {
          element: '[data-tour="discipline"]',
          on: "auto",
        },
      },
      {
        title: "Description",
        text: "Use this editor to add description of the kata in Markdown.",
        attachTo: {
          element: `[data-tour="description"]`,
          on: "auto",
        },
      },
      {
        title: "Language",
        text: "Select the language you want to use here.",
        attachTo: {
          element: '[data-tour="language"]',
          on: "bottom-start",
        },
      },
      {
        title: "Complete Solution",
        text: "A working solution for your kata. Make sure to write a correct and maintainable code.",
        attachTo: {
          element: '[data-tour="complete-solution"]',
          on: "bottom-start",
        },
      },
      {
        title: "Initial Solution",
        text: "The code users start from when training on your kata.",
        attachTo: {
          element: '[data-tour="initial-solution"]',
          on: "bottom-start",
        },
      },
      {
        title: "Preloaded",
        text: "Optional code that can be provided for different purposes. For example, reducing boilerplate.",
        attachTo: {
          element: '[data-tour="preloaded"]',
          on: "bottom-start",
        },
      },
      {
        title: "Test Cases",
        text: "The test cases are the set of tests that users need to pass in order to complete your kata.",
        attachTo: {
          element: '[data-tour="test-cases"]',
          on: "bottom-end",
        },
      },
      {
        title: "Example Test Cases",
        text: "The example test cases are the set of tests that users can edit while solving your kata.",
        attachTo: {
          element: '[data-tour="sample-tests"]',
          on: "bottom-start",
        },
      },
      {
        title: "Examples",
        text: "Try inserting an example to get you started.",
        attachTo: {
          element: '[data-tour="example"]',
          on: "bottom-end",
        },
        buttons: [
          {
            text: "Back",
            action() {
              return this.back();
            },
          },
          {
            text: "OK",
            action() {
              return this.next();
            },
          },
        ],
      },
    ],
  });
  tour.start();
};
