import axios from "axios";
import { Control } from "../../control";
import { createDisplayManager } from "../../services/display_manager";
import { CodeChallengeInfoAreas } from "../code_challenge_info_areas";
import { CodeChallengesSocialButtons } from "./social_buttons";
import { turbolinksRender } from "../../turbolinks_utils";
import { automountComments } from "../../mounts/comments";

export class CodeChallengesDiscussController extends Control {
  constructor(el, options) {
    super(el, options);
    const infoAreas = new CodeChallengeInfoAreas(this.element, {
      _app: this._app,
    });
    this.initComments();
    const socialButtons = new CodeChallengesSocialButtons(
      this.element.find(".js-train-social"),
      { _app: this._app }
    );
    this._controllers.push(infoAreas, socialButtons);
    createDisplayManager({
      user: this._app.currentUser,
      id: "discuss",
      partner: {
        type: "image",
        contrast: true,
        red: true,
      },
    });
  }

  async initComments() {
    // HACK This doesn't work when added to `events` for some reason
    const updateCounts = (e) => {
      for (const k of Object.keys(e.detail)) {
        const count = e.detail[k];
        const badge = document.querySelector(`sl-radio[value="${k}"] sl-badge`);
        if (!badge) continue;

        badge.textContent = count > 0 ? count + "" : "";
        badge.classList.toggle("hidden", count === 0);
      }

      if (this.comments) {
        // Update the label filter control if changed.
        updateViewRadioGroup(control, this.comments.labelFilter);
      }
    };
    const control = document.getElementById("view-control");
    control.addEventListener("comments:counts-updated", updateCounts);
    this._bindings.push(() => {
      control.removeEventListener("comments:counts-updated", updateCounts);
    });

    const mounted = await automountComments(this._app);
    this.comments = mounted[0];
    // This is awkward, but we need to update the control when linked from other pages
    // with a hash specifying the filter because it's not part of the component.
    if (this.comments.labelFilter !== "all") {
      updateViewRadioGroup(control, this.comments.labelFilter);
    }
  }
}

const updateViewRadioGroup = (control, value) => {
  const labelFilter = control.querySelector('sl-radio-group[name="view"]');
  if (labelFilter.value !== value) labelFilter.value = value;
};

CodeChallengesDiscussController.prototype.events = {
  /** @this {CodeChallengesDiscussController} */
  ".js-unlock-test-cases click"($el) {
    axios
      .post(this._app.route("unlock_test_cases"), {}, { responseType: "text" })
      .then((response) => {
        turbolinksRender(response.data);
      });
  },
  "#view-control sl-radio-group sl-change"($el) {
    const data = new FormData(document.getElementById("view-control"));
    const filter = data.get("view");
    if (this.comments.labelFilter !== filter) {
      this.comments.setLabelFilter(filter);
    }
  },
  "#show-resolved sl-change"($el) {
    if ($el.get(0).checked) {
      this.comments.showResolved();
    } else {
      this.comments.hideResolved();
    }
  },
};
