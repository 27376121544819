import Turbolinks from "turbolinks";

// Handles the response from `turbolinks_render` which is a JS to render the page with Turbolinks
export const turbolinksRender = (text) => {
  const script = document.createElement("script");
  script.innerHTML = text;
  document.body.appendChild(script);
};

// Add `X-Refreshed` header so that the server doesn't check the status again.
// Not using `?refreshed=true` anymore because it messes with the browser history.
export const visitRefreshed = (url) => {
  document.addEventListener(
    "turbolinks:request-start",
    (event) => {
      const xhr = event.data.xhr;
      xhr.setRequestHeader("X-Refreshed", "true");
    },
    { once: true }
  );
  Turbolinks.visit(url);
};
