import $ from "jquery";

import { ns, token, toDash, toLowerFirst, escapeRegExp } from "../utils";
import { filters as templateFilters } from "./template_filters";

export class Template {
  constructor(id, options = {}) {
    this.options = Object.assign({}, options);
    // Reference to the global App.
    this._app = this.options._app;
    delete this.options._app;
    this.$template = $(id);
    this.$template.data("template", this);
    this.name = this.$template.attr("class") || this.$template.attr("id");
  }

  context() {
    if (this.contexts) {
      return this.contexts[this.contexts.length - 1];
    } else {
      return null;
    }
  }

  parentContext(ancestor) {
    if (this.contexts) {
      return this.contexts[this.contexts.length - ((ancestor || 1) + 1)];
    } else {
      return null;
    }
  }

  render(target, data, filters, options) {
    if (data == null) data = {};
    if (options == null) options = {};
    this._initFilters(filters);

    this.contextMap = options.contextMap || {};
    this.includeData = options.includeData || {};
    this.debug = !!options.debug;

    const $template = $(this.$template.html());
    this.data = options.rootData || data;

    this._initContexts(data, options);

    const html = [];
    $template.each((i, el) => {
      const $el = $(el);
      this._processNode($el);
      html.push(nodeHtml($el));
    });
    const $target = $(target);
    $target.html(html.join(""));
    $target.attr("data-context", "root");
    $target.data("context", this.data);

    // map data contexts to elements for easy data/template mapping
    if (!options.contextMap) {
      $target.find("[data-context-key]").each((i, el) => {
        const $el = $(el);
        data = this.contextMap[$el.data("context-key")];
        if (data) $el.data("context", data);
      });
    }
  }

  _initFilters(filters) {
    this.filters = this.options.filters || templateFilters;
    if (filters) this.filters = Object.assign({}, this.filters, filters);

    // lazy load controller filters
    if (!this.filters._controller && this._app.controller) {
      this.filters._controller = this._app.controller;
    }
  }

  _initContexts(data, options) {
    if (options.contexts) {
      this.contexts = options.contexts;
    } else {
      const parentContext = options.parentContext || this.parentContext();
      this.contexts = parentContext ? [parentContext, data] : [data];
    }
  }

  _processNode($node, ignoreEach) {
    if ($node.is("script")) return;

    const nodeData = $node.data();
    if (nodeData && Object.keys(nodeData).length > 0) {
      // process bind-each bindings
      if (nodeData.bindEach && !ignoreEach) {
        this._processBindEach($node, nodeData.bindEach);
        return;
      }

      // if the element points to another template then render that template now.
      // Note: if the element is an 'include' element then the nodeHtml method will take care of
      // replacing the node html instead of injecting html inside of it
      if (nodeData.template) {
        if (nodeData.bindTo) {
          this._withChildContext(
            this._processBindingValue(nodeData.bindTo),
            () => {
              if (this.context()) {
                this._processTemplate($node, nodeData);
              }
            }
          );
        } else {
          this._processTemplate($node, nodeData);
        }
      }

      // Loop through and process each "bind-" attribute
      Object.keys(nodeData).forEach((k) => {
        this._processBinding($node, k, nodeData);
      });
    }

    $node.children().each((i, el) => {
      if (nodeData.bindTo) {
        this._withChildContext(
          this._processBindingValue(nodeData.bindTo),
          () => {
            this._processNode($(el));
          }
        );
      } else {
        this._processNode($(el));
      }
    });

    // reset the case value if the current case node is done processing
    if ($node === this.$caseNode) this.caseValue = this.prevCaseValue;
    if ($node.is("include")) $node.replaceWith(nodeHtml($node));
  }

  _processBindEach($node, bindEach) {
    const eachItems = this._processBindingValue(bindEach);
    if (eachItems && Array.isArray(eachItems)) {
      const htmls = [];
      eachItems.forEach((item) => {
        this._withChildContext(item, () => {
          const $clone = $node.clone();
          $clone.removeAttr("data-bind-each");
          this._processContextMapping($clone);
          this._processNode($clone, true);
          htmls.push(nodeHtml($clone));
        });
      });

      const html = htmls.join("");
      // HACK: first we replace, in case replaceWith doesnt work - which it sometimes doesn't
      $node.html(html);
      $node.replaceWith(html);
    } else {
      $node.remove();
    }
  }

  _withChildContext(childContext, fn) {
    this.contexts.push(childContext);
    fn();
    this.contexts.pop();
  }

  _processTemplate($node, nodeData) {
    const root = this.$template.parent();
    // nodeData.template is truthy, get an instance of Template.
    const template = getTemplate.call(this, $node, nodeData.template, root);
    template.render(
      $node,
      this.context() || $node.data("context"),
      this.filters,
      {
        root,
        contextMap: this.contextMap,
        includeData: nodeData,
        contexts: this.contexts,
      }
    );
    $node.attr("data-template-selector", nodeData.template);
    this._processContextMapping($node);
  }

  _processContextMapping($node) {
    if (!$node.data("context-key")) {
      // create a token that will be added to the element html as an attribute,
      // this token will be mapped after its html is copied into the target innerHtml,
      // so that the context data can be assigned to the element
      const _token = token();
      this.contextMap[_token] = this.context();
      $node.attr("data-context-key", _token);
    }
  }

  _processBinding($node, key, nodeData) {
    this.$node = $node;
    this._currentBinding = `data-${toDash(key)}`;
    if (!key.match(/bind[A-Z]/)) return;

    let value;
    const bindKey = key.substr(4);
    if (bindKey !== "When") {
      value = this._processBindingValue(nodeData[key]);
    }
    $node.removeAttr(this._currentBinding);

    const hasValue = value != null;
    switch (bindKey) {
      case "Each":
      case "To":
        // ignore since we already processed
        break;

      case "Class":
        if (hasValue) $node.addClass(value);
        break;

      case "Text":
        if (hasValue) $node.text(value);
        break;

      case "Html":
        if (hasValue) $node.html(value);
        break;

      case "ReplaceWith":
        if (hasValue) $node.replaceWith(value);
        break;

      case "Value":
        if (hasValue) {
          // for some reason val() is not working so we do it manually
          if ($node.is("textarea")) $node.html(value);
          else $node.attr("value", value);
        }
        break;

      case "Case":
        this.prevCaseValue = this.caseValue;
        this.caseValue = (value || "").toString();
        this.$caseNode = $node;
        break;

      // for when statements we use the literal value of the attribute
      case "When":
        // need to handle the case where both a bind case and a bind when are used on the same node
        const caseValue = $node.data("bind-case")
          ? this.prevCaseValue
          : this.caseValue;
        if (caseValue !== (nodeData[key] || "").toString()) {
          removeNode($node);
        }
        break;

      case "ShowIf":
        if (value) {
          $node.show();
        } else {
          $node.hide();
        }
        break;

      case "HideIf":
        if (value) {
          $node.hide();
        } else {
          $node.show();
        }
        break;

      case "ShowUnless":
        if (value) {
          $node.hide();
        } else {
          $node.show();
        }
        break;

      case "HideUnless":
        if (value) {
          $node.show();
        } else {
          $node.hide();
        }
        break;

      case "If":
        if (!value) removeNode($node);
        break;

      case "Unless":
        if (value) removeNode($node);
        break;

      default:
        if (bindKey.match(/^Data[A-Z]/)) {
          if (hasValue) {
            $node.data(toLowerFirst(bindKey.substr(4)), value);
            $node.attr(toDash(bindKey), value);
          }
        } else {
          if (hasValue) {
            const key = toDash(bindKey);
            if (key === "checked") {
              $node.prop("checked", value);
              // The above doesn't seem to be enough to make the checkbox checked.
              if (value) $node.attr("checked", true);
            } else {
              $node.attr(key, value);
            }
          }
        }
        break;
    }
  }

  _processBindingValue(value) {
    if (!value) return null;

    value = value.toString();
    // support embedded strings with data tokens
    if (value.includes("{{")) {
      while (value.includes("{{")) {
        value = this._processNextBindingToken(value);
      }
      if (!value.includes("::") && !value.includes("|")) return value;
    }

    return this._processValuePaths(value);
  }

  _processNextBindingToken(value) {
    const sndx = value.indexOf("{{") + 2;
    const endx = value.indexOf("}}");
    const path = value.substr(sndx, endx - sndx);
    const repl = this._processValuePaths(path) || "";
    return value.replace(new RegExp(`{{${escapeRegExp(path)}}}`, "g"), repl);
  }

  _processValuePaths(paths) {
    // ORs take precedence. If there are ORs we will break up the paths and return the
    // first one that has a truthy value.
    const ors = paths.split("||");
    if (ors.length > 1) {
      let value = null;
      for (const path of ors) {
        value = this._processValuePaths(path.trim());
        if (value) break;
      }
      return value;
    }

    // ANDs are processed after ORs.
    const ands = paths.split("&&");
    if (ands.length > 1) {
      // if && are used we want to check that they are all truthy. If they are all true we will return
      // the last value so that it can be used as a display value if needed.
      // In the following example, class will only be set to first_item if index == 1
      // data-bind-class="@index | eq::1 && 'first_item'"
      let value = null;
      for (const path of ands) {
        value = this._processValuePath(path.trim());
        if (!value) return false;
      }
      return value;
    }

    return this._processValuePath(paths.trim());
  }

  _processValuePath(fullPath) {
    if (!fullPath) return;

    const [valuePath, ...filters] = fullPath.split("|");
    const value = this._getValue(valuePath.trim(), fullPath);
    return filters.reduce((v, f) => this._processFilter(f, v, fullPath), value);
  }

  _getValue(valuePath, fullPath) {
    switch (valuePath) {
      // if the entire current context data is to be processed
      case "@":
        return this.context();
      case "@@":
        return this.parentContext();
      case "true":
        return true;
      case "false":
        return false;
      case ".":
        // .: root data
        return this.data;

      default:
        // A number
        const num = parseInt(valuePath, 10);
        if (!isNaN(num)) return num;

        // @@ parent context path
        if (valuePath.startsWith("@@")) {
          return this._processValue(
            ns(valuePath.substr(2), this.parentContext())
          );
        }
        // @ context path
        if (valuePath.startsWith("@")) {
          return this._processValue(ns(valuePath.substr(1), this.context()));
        }
        // % include data path. This is only available when a template is being included by a parent template
        if (valuePath.startsWith("%")) {
          return this._processValue(
            ns(valuePath.substr(1), this.includeData || {})
          );
        }

        // = a literal string
        if (valuePath.startsWith("=")) return valuePath.substr(1);
        // ' a literal string
        if (valuePath.startsWith('"')) return valuePath.split('"')[1];
        // " a literal string
        if (valuePath.startsWith("'")) return valuePath.split("'")[1];

        if (valuePath.startsWith("App.")) {
          return this._processValue(ns(valuePath, this._app));
        }
        // $ App.data
        if (valuePath.startsWith("$")) {
          return this._processValue(ns(valuePath.substr(1), this._app.data));
        }

        if (valuePath.startsWith("!")) {
          return !this._processValuePath(valuePath.substr(1));
        }

        // else the string is an absolute path to the root data
        let value = this._processValue(ns(valuePath, this.data));
        // if the value is undefined then perhaps the shorthand filter syntax was used
        if (value === undefined && this.filters[valuePath.split("::")[0]]) {
          value = this._processFilter(valuePath, undefined, fullPath);
        }
        return value;
    }
  }

  _processValue(val) {
    return typeof val === "function" ? val(this.context()) : val;
  }

  // note we pass in a params object so that we can maintain the same reference to value
  _processFilter(filter, value, valuePath) {
    const [f, ...args] = filter.split("::");
    const name = f.trim();
    if (!name || !this.filters[name]) return value;

    const params = {
      value,
      valuePath,
      data: this.data,
      parentContext: this.parentContext(),
      context: this.context(),
      includeData: this.includeData,
      element: this.$node,
      binding: this._currentBinding,
      template: this,
      appData: this._app.data,
      currentUser: this._app.currentUser,
      args: args.map((p) => this._processValuePath(p.trim())),
      // allows params to chain filters together within its own definition
      filter: (name) => this.filters[name](params),
      expr: (path) => this._processValuePath(path),
    };
    return params.filter(name);
  }
}

const removeNode = function ($node) {
  $node.remove();
  $node.data("template-removed", true);
};

const nodeHtml = function ($node) {
  if ($node.is("include")) {
    // if the node has a context token then it needs to be moved to the children
    if ($node.attr("data-context-key")) {
      $node.children().attr("data-context-key", $node.attr("data-context-key"));
    }

    // point the children to their original template so that they can easily be
    // re-rendered later on
    $node
      .children()
      .attr("data-template-selector", $node.attr("data-template-selector"));

    return $node.html();
  }

  return $node.data("template-removed") ? "" : outerHtml($node);
};

const outerHtml = ($node) => $("<div></div>").append($node).html();

/**
 * @this {Template}
 */
function getTemplate($el, template, root) {
  if (template instanceof Template) return template;

  let $tmpl;
  if (root) {
    $tmpl = root.find(template);
    if ($tmpl.length === 0) $tmpl = $(template);
  } else {
    $tmpl = $(template);
  }
  $tmpl = $tmpl.eq(0);
  return $tmpl.data("template") || new Template($tmpl, { _app: this._app });
}
