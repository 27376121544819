const cache = {};

export const stash = (ns) => {
  if (!cache[ns]) cache[ns] = new Kizzy(ns);
  return cache[ns];
};

function _writeThrough(ns, val) {
  try {
    const v = JSON.stringify(val);
    if (v === "{}") {
      window.localStorage.removeItem(ns);
    } else {
      // this can throw if full
      window.localStorage.setItem(ns, v);
    }
    return true;
  } catch (_) {
    return false;
  }
}

function jsonParse(str) {
  try {
    return JSON.parse(str);
  } catch (ex) {
    // needed for backwards support to stash.js json store which didnt store proper json
    console.log("Failed to process local stored JSON.");
    return {};
  }
}

// LocalStorage wrapper compatible with kizzy's API.
// https://github.com/ded/kizzy
// Removed unused features like TTL.
class Kizzy {
  constructor(ns) {
    this.ns = ns;
    this._ = jsonParse(window.localStorage.getItem(ns) || "{}");
  }

  set(k, v) {
    this._[k] = { value: v, e: 0 };
    _writeThrough(this.ns, this._) || this.remove(k);
    return v;
  }

  get(k) {
    return this._[k] ? this._[k].value : undefined;
  }

  remove(k) {
    delete this._[k];
    _writeThrough(this.ns, this._);
  }

  clear() {
    this._ = {};
    _writeThrough(this.ns, this._);
  }

  isEmpty() {
    return Object.keys(this._ || {}).length === 0;
  }
}
