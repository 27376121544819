import $ from "jquery";

import { Control } from "../control";
import { OutputPanel } from "./output_panel";

export class Ide extends Control {
  constructor(el, options) {
    super(el, options);
  }

  configureIde() {
    if (this.outputPanel) {
      this.outputPanel.destroy();
      const i = this._controllers.indexOf(this.outputPanel);
      if (i !== -1) this._controllers.splice(i, 1);
      this.outputPanel = null;
    }
    this.outputPanel = new OutputPanel("#code_results", {
      _app: this._app,
    });
    this._controllers.push(this.outputPanel);
    this.setBindings(this._app.localStorage.get("editorKeyMap"));
  }

  toggleBindings(mode) {
    if (mode == null) mode = this._app.data.mode;
    this.setBindings(this.keybinds !== mode ? mode : "default");
  }

  setBindings(mode) {
    this.element.find("#vim_mode, #emacs_mode").removeClass("is-active");
    if (mode !== "default") {
      this.element.find(`#${mode}_mode`).addClass("is-active");
    }

    if (this.editors != null) {
      this.editors.forEach((editor) => {
        if (Array.isArray(editor)) {
          editor[0].setBindings(mode);
        } else {
          editor.setBindings(mode);
        }
      });
    }

    $(".markdown-editor").each(function () {
      const x = $(this).data("editor");
      if (x != null) x.editor.setBindings(mode);
    });

    this.keybinds = mode;

    this._app.data.mode = mode;
  }

  toggleTabs() {
    const $desc = $('a[data-tab="description"]');

    if ($desc.parent().is(".is-active")) {
      $('a[data-tab="output"]').trigger("click");
    } else {
      $desc.trigger("click");
    }
  }

  toggleFocusedEditor() {
    if (this.editor.focused && this.fixture) {
      this.fixture.focus();
    } else {
      this.editor.focus();
    }
  }

  isOutputVisible() {
    return $("#output_frame").is(":visible");
  }

  showOutput() {
    $('a[data-tab="output"]').trigger("click");
  }

  showOutputWaiting(msg) {
    this.showOutput();
    this.editor.setBorder();
    if (this.fixture) {
      this.fixture.setBorder();
    }
  }

  showResults(completed, editor) {
    $(".btn.js-run").removeClass("is-disabled");

    this.showOutputWaiting();
    if (editor) {
      this.setEditorBorder(editor, completed);
    }
  }

  setEditorBorder(editor, active) {
    editor.setBorder(active ? "green" : "red");
  }

  reset() {
    this._app.confirmModal.show({
      titleHtml: "Reset your solution and example test cases?",
      messageHtml:
        "This will replace your solution and example test cases with the default setup. Are you sure?",
      confirmHtml: "Yes. Do it!",
      confirm: () => {
        this.editor.setValue(this.language.setup);
        this.fixture.setValue(this.language.exampleFixture);
      },
    });
  }

  // TODO: rename "setup" references to be package instead
  getSetupCode() {
    return null;
  }

  getLanguage() {
    return this.language.languageName;
  }

  getCode() {
    if (this.editor) {
      return this.editor.getValue();
    }
  }

  getFixture() {
    if (this.fixture) {
      return this.fixture.getValue();
    }
  }

  getTestFramework() {
    return this.language && this.language.testFramework;
  }

  run(data = {}) {
    this.running = true;
    const request = Object.assign(
      {
        language: this.getLanguage(),
        code: this.getCode(),
        fixture: this.getFixture(),
        setup: this.getSetupCode(),
        testFramework: this.getTestFramework(),
      },
      data
    );

    this.outputPanel.runner.run(request);
  }

  handleResponse() {
    (this.fixture || this.editor).messages.hide();
    $(".js-run").removeClass("is-disabled");
  }

  defaultFixtureText(language) {
    return this.editor.defaultFixtureText(language);
  }
}

Ide.prototype.events = {
  /**
   * @this {Ide}
   */
  "window popstate, window beforeunload"() {
    if (this.editor) {
      this.editor.updateStash();
      this.fixture.updateStash();
    }
  },

  /**
   * @this {Ide}
   */
  "window keydown"(el, e) {
    if (e.keyCode === 8 && !$(e.target).is("input, textarea")) {
      e.preventDefault();
      return false;
    }
  },

  /**
   * @this {Ide}
   */
  "#fixture_help click"() {
    this._app.confirmModal.show({
      cancelHtml: "Got It",
      titleHtml: "Test Cases Help",
      messageHtml:
        "<p>The test cases section is provided so that you can use Test Driven Development while coding your solution. For some kata, authors may have provided some example test cases to get you started. When you click \"Submit\" your solution will be validated against the kata author's test cases, which are usually more thorough than the example tests cases that may be provided. <br><br>You run your tests by clicking the \"Run Tests\" button. You can also use the <span class='text-ui-text-lc'>Cmd/Control key + '</span> shortcut to run your test cases.</p> <p>Please read the documentation for <a href='https://docs.codewars.com/languages' class='is-alt' target='_blank' rel='noopener'>languages and test frameworks</a>.</p>",
    });
  },

  /**
   * @this {Ide}
   */
  "div#fixture texteditor.submit"() {
    this.validate();
  },

  /**
   * @this {Ide}
   */
  "#validate_btn click"() {
    this.validate();
  },

  /**
   * @this {Ide}
   */
  "#maximize_layout click"() {
    this.setLayout("max");
  },

  /**
   * @this {Ide}
   */
  "#minimize_layout click"() {
    this.setLayout("min");
  },

  /**
   * @this {Ide}
   */
  "#vim_mode click"() {
    this.toggleBindings("vim");
  },

  /**
   * @this {Ide}
   */
  "#emacs_mode click"() {
    this.toggleBindings("emacs");
  },

  /**
   * @this {Ide}
   */
  "#runner_frame message.notifyReceipt"(_, e) {
    if (this.running) {
      $(".btn.js-run").addClass("is-disabled");
      this.showOutputWaiting();
    }
  },

  /**
   * @this {Ide}
   */
  "#runner_frame message.notifyResponse"() {
    if (this.running && this.outputPanel.runner.response) {
      this.running = false;
      this.handleResponse();
    }
  },
};
