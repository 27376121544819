import $ from "jquery";
import axios from "axios";

import { Control } from "../control";

export class StarsController extends Control {
  constructor(el, options) {
    super(el, options);
    this.syncStarred();
  }

  // refresh the favorites list with the latest html from the server
  refreshList() {
    const $starred = this.element.find(".stars-item .menu-body");
    if ($starred.length === 0) return;

    axios
      .get(this._app.route("user_stars"), { responseType: "text" })
      .then((response) => {
        $starred.html(response.data);
      });
  }

  toggleCodeChallengeStar($el) {
    const $parent = $el.closest("[data-id]");
    const id = $parent.data("id");
    axios
      .post(this._app.route("star_code_challenge", { id }))
      .then((response) => {
        const json = response.data;
        if (json.success) {
          this.updateCodeChallengeStar(
            $parent,
            $el.hasClass("js-add-code-challenge-star")
          );
          this.refreshList();
        }
      });
  }

  // update the star UI
  updateCodeChallengeStar($parent, added) {
    if (added) {
      $parent.find(".js-add-code-challenge-star").hide();
      $parent.find(".js-remove-code-challenge-star").show();
    } else {
      $parent.find(".js-add-code-challenge-star").show();
      $parent.find(".js-remove-code-challenge-star").hide();
    }
  }

  // syncs any server cached UI elements so that they accurately show an active star state for the current user.
  // This is used to allow server code to cache on a non-user specific level. All starred items are set client-side
  // using this method.
  syncStarred() {
    if (
      this._app.currentUser &&
      this._app.currentUser.starred_code_challenge_ids
    ) {
      this._app.currentUser.starred_code_challenge_ids.forEach((id) => {
        this.updateCodeChallengeStar($(`[data-id='${id}']`), true);
      });
    }
  }
}

StarsController.prototype.events = {
  /**
   * @this {StarsController}
   */
  ".js-add-code-challenge-star click"($el) {
    this.toggleCodeChallengeStar($el);
  },

  /**
   * @this {StarsController}
   */
  ".js-remove-code-challenge-star click"($el) {
    this.toggleCodeChallengeStar($el);
  },

  /**
   * @this {StarsController}
   */
  infinitescrollcontent() {
    this.syncStarred();
  },
};
