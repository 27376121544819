export const rankColors = {
  white: "#FFF",
  yellow: "#ECB613",
  blue: "#3C7EBB",
  purple: "#9574E6",
  black: "#333",
};

export const successMessages = [
  "Good Job!",
  "Impressive!",
  "Outstanding!",
  "Correct!",
  "Great!",
  "Correctamundo!",
  "Excellent!",
];

export const failureMessages = ["Incorrect!", "Nope! Try again."];
