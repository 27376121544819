import { Controller } from "@stimulus/core";
import Turbolinks from "turbolinks";

import { preserveScroll } from "./utils";

export default class extends Controller {
  toggleFilterFlag(event) {
    updateAndVisit(event.target.name, event.target.checked);
  }
}

const updateAndVisit = (name, value) => {
  const url = new URL(window.location.href);
  if (value) {
    url.searchParams.set(name, "1");
  } else {
    url.searchParams.delete(name);
  }
  preserveScroll();
  Turbolinks.visit(url.toString());
};
