import $ from "jquery";
import axios from "axios";

import { Control } from "../../control";
import { CodeChallengeInfoAreas } from "../code_challenge_info_areas";
import { CompareSolutionsController } from "../compare_solutions_controller";
import { turbolinksRender } from "../../turbolinks_utils";
import { LabelVotesController } from "../label_votes_controller";
import { automountComments } from "../../mounts/comments";

export class CodeChallengeSolutionGroupsShowController extends Control {
  constructor(el, options) {
    super(el, options);
    automountComments(this._app).then(() => {
      this._controllers.push(
        new LabelVotesController($("#app"), { _app: this._app })
      );
    });
    const infoAreas = new CodeChallengeInfoAreas(this.element, {
      _app: this._app,
    });
    const compareSolutions = new CompareSolutionsController(this.element, {
      _app: this._app,
    });
    this._controllers.push(infoAreas, compareSolutions);
    this.updateTitle();
  }

  updateTitle() {
    const users = document.querySelector(
      ".solution-group-users-list"
    ).textContent;
    if (!document.title.includes(users)) {
      document.title = document.title.replace(
        /\| Codewars/,
        (m) => `by ${users} ${m}`
      );
    }
  }
}

CodeChallengeSolutionGroupsShowController.prototype.events = {
  ".js-unlock-solutions click"($el) {
    axios
      .post(this._app.route("unlock_solutions"), {}, { responseType: "text" })
      .then((response) => {
        turbolinksRender(response.data);
      });
  },

  ".js-unlock-test-cases click"($el) {
    axios
      .post(this._app.route("unlock_test_cases"), {}, { responseType: "text" })
      .then((response) => {
        turbolinksRender(response.data);
      });
  },
};
