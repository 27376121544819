import $ from "jquery";
import axios from "axios";

import { Control } from "../../control";
import { loadScript } from "./utils";

export class SubscriptionEditController extends Control {
  constructor(el, options) {
    super(el, options);
    this.stripe = null;

    loadScript("https://js.stripe.com/v3/", () => {
      axios.get("/subscription/key").then((response) => {
        this.stripe = window.Stripe(response.data.pubKey);

        $("#update_payment_method.is-disabled").removeClass("is-disabled");
      });
    });
  }
}

SubscriptionEditController.prototype.events = {
  /** @this {SubscriptionEditController} */
  "#update_payment_method:not(.is-disabled) click"($el) {
    $("#update_payment_method").addClass("is-disabled");

    axios
      .post("/subscription/edit/session")
      .then((response) => {
        this.stripe
          .redirectToCheckout({
            sessionId: response.data.sessionId,
          })
          .then((result) => {
            // Browser of network error
            if (result.error) alert(result.error.message);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        $("#update_payment_method").removeClass("is-disabled");
        if (error.response) {
          alert(error.response.data.reason);
        } else {
          alert("Something went wrong, please try again.");
        }
      });
  },

  /** @this {SubscriptionEditController} */
  "#reactivate:not(.is-disabled) click"($el) {
    $el.addClass("is-disabled");
    axios
      .post("/subscription/edit/renewal")
      .then((_) => {
        Turbolinks.visit();
      })
      .catch((error) => {
        $el.removeClass("is-disabled");
        if (error.response) {
          alert(error.response.data.reason);
        } else {
          alert("Something went wrong, please try again.");
        }
      });
  },

  /** @this {SubscriptionEditController} */
  "#cancel:not(.is-disabled) click"($el) {
    $el.addClass("is-disabled");
    axios
      .delete("/subscription/edit/renewal")
      .then((_) => {
        Turbolinks.visit();
      })
      .catch((error) => {
        $el.removeClass("is-disabled");
        if (error.response) {
          alert(error.response.data.reason);
        } else {
          alert("Something went wrong, please try again.");
        }
      });
  },
};
