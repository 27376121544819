import { Control } from "../control";
import { updateLayout } from "../ui";
import { slideDown, slideUp } from "../animation";

export class CommentsController extends Control {
  constructor(el, options) {
    super(el, options);
  }
}

CommentsController.prototype.events = {
  /**
   * Expand / Collapse comments
   * @this {CommentsController}
   */
  ".comments .js-show-comments:not(.is-disabled) click"($el, e) {
    const $comments = $el.closest(".comments");
    const $commentsList = $comments.find("ul.comments-list").first();
    $el.addClass("is-disabled");
    if ($el.data("open")) {
      slideUp($commentsList.get(0), {
        finish: () => {
          $el.removeClass("is-disabled");
          updateLayout();
          $el.data("open", false);
        },
      });
    } else {
      slideDown($commentsList.get(0), {
        finish: () => {
          $el.removeClass("is-disabled");
          updateLayout();
          $el.data("open", true);
        },
      });
    }
  },

  /**
   * @this {CommentsController}
   */
  ".comments .new-comment .js-expand click"($el, e) {
    e.preventDefault();
    e.stopPropagation();
    const $newComment = $el.closest(".new-comment");
    $newComment.data("editor").editor.enterFullScreen();
    $newComment.find("dl.tabs > dd:first a").trigger("click");
    setTimeout(() => $el.parent().removeClass("is-active"), 0);
  },
};
