import $ from "jquery";
import Turbolinks from "turbolinks";

import { Control } from "../../control";
import { InfiniteScroll } from "../infinite_scroll";

export class CollectionsIndexController extends Control {
  constructor(el, options) {
    super(el, options);
    this.infiniteScroll = new InfiniteScroll("section.items-list", {
      _app: this._app,
    });
    this._controllers.push(this.infiniteScroll);
  }

  filterUrl(search) {
    const params = {
      q: search || $("#search_input").val(),
      created_by: $("#created_by").val(),
      order_by: $("#order_by").val(),
    };

    // clean out all of the empty params, except for q which we need so that the filters stay active
    Object.keys(params).forEach(function (k) {
      if (!params[k] && k !== "q") delete params[k];
    });

    return this._app.route("search", null, params);
  }

  search(value) {
    if (value == null) value = $("#search_input").val();
    this.filter(value);
  }

  filter(search) {
    $("#search").attr("disabled", "");
    Turbolinks.clearCache();
    Turbolinks.visit(this.filterUrl(search));
  }
}

CollectionsIndexController.prototype.events = {
  /**
   * @this {CollectionsIndexController}
   */
  "#search click, #search_input sl-clear"() {
    this.search();
  },

  /**
   * @this {CollectionsIndexController}
   */
  "#search_form submit"(_, e) {
    e.preventDefault();
    this.search();
  },

  /**
   * @this {CollectionsIndexController}
   */
  "#order_by sl-change, #created_by sl-change"() {
    this.filter();
  },
};
