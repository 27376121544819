import { Channel } from "@anycable/web";

export default class UsersComparisonsChannel extends Channel {
  static identifier = "UsersComparisonsChannel";

  receive(message, meta) {
    if (message.event === "ready") return this.emit("refresh", message);

    super.receive(message, meta);
  }
}
