import * as braze from "@braze/web-sdk";
import axios from "axios";

import {
  createPromotedAdDisplayState,
  mountPromotedAdDisplay,
} from "../mounts/promoted_ad_display";
import { sampleArray } from "../utils";
import { mountApp } from "../vue";

/*
  Sets up a bridge between promoted ad display and job ad display
 */
export async function createDisplayManager({
  user, // current user
  partner, // same settings that would go to promoted_ad_display
  id, // used for identifying displays
  brazeTargets, // ids to select Braze content cards
}) {
  return {
    ...(await mountDisplays({ user, partner, id, brazeTargets })),
    async refresh() {
      const { partnerDisplay, houseDisplay } = this;
      if (partnerDisplay) {
        partnerDisplay.refresh();
      }
      if (houseDisplay) {
        houseDisplay.refresh();
      }
    },
  };
}

async function mountDisplays({ user, partner, id, brazeTargets }) {
  if (brazeTargets?.length > 0) {
    // The order of `house-display` and `partner-display` are inconsistent, so
    // use the order found on the page.
    const targets = [
      ...document.querySelectorAll("#house-display, #partner-display"),
    ];
    if (targets.length > 0) {
      // `getUser()` is used to make sure the SDK is initialized.
      // The SDK is not initialized when the user is not logged in.
      // TODO Maybe `requestContentCardsRefresh()` based on `lastUpdated`?
      const contentCards =
        (braze.getUser() && braze.getCachedContentCards())?.cards || [];
      if (contentCards.length > 0) {
        const [first, second] = brazeTargets;
        if (first) {
          renderContentCard(targets[0], contentCards, first);
        }
        if (second && targets[1]) {
          renderContentCard(targets[1], contentCards, second);
        }
      }
    }
  }

  const displays = {};
  if (partner) {
    const partnerEl = document.getElementById("partner-display");
    if (partnerEl && !partnerEl.dataset.brazeContentCards) {
      displays.partnerDisplay = mountPromotedAdDisplay(partnerEl, {
        ...partner,
        id: partner.id || id ? `${id}_ad` : null,
        user,
      });
    }
  }

  const houseEl = document.getElementById("house-display");
  if (houseEl && !houseEl.dataset.brazeContentCards) {
    const state = createPromotedAdDisplayState({
      user,
      id,
      type: "image",
      zone: "house",
      red: !!partner?.red,
    });
    state.view = "ad";
    displays.houseDisplay = mountApp(houseEl, state);

    // simple hack to hide partner ad if it is the same as house ad
    setTimeout(() => {
      if (
        displays.partnerDisplay &&
        displays.partnerDisplay.ad &&
        displays.houseDisplay.ad
      ) {
        if (displays.partnerDisplay.ad.copy === displays.houseDisplay.ad.copy) {
          document.getElementById("partner-display").style.display = "none";
        }
      }
    }, 1500);
  }

  console.debug("display manager", displays);
  return displays;
}

const renderContentCard = (el, cards, area) => {
  const filtered = cards.filter((c) =>
    (c.extras.display_area || "").includes(area)
  );
  const card = sampleArray(filtered);
  if (!card) return;

  // Add an attribute to prevent mounting on the content cards container.
  el.dataset.brazeContentCards = "true";
  // HACK Remove vue templates and unhide it.
  el.replaceChildren();
  el.removeAttribute("v-cloak");
  braze.showContentCards(el, () => [card]);
  setTimeout(() => {
    // handle custom action
    if (card.extras.action_handler) {
      const handler = brazeActionHandlers[card.extras.action_handler];
      if (!handler) {
        console.log("Missing Braze handler for", card.extras.action_handler);
        return;
      }
      el.addEventListener("click", (e) => handler(e, card), { capture: true });
    }
  }, 100);
};

const brazeActionHandlers = {
  "andela-opt-in": (e, card) => {
    if (e.target.tagName !== "A") return;
    axios.post("/trainer/andela_opt_in").then((response) => {
      console.debug("Opted in");
      if (card.extras.action_url) {
        // don't use TurboLinks so that anchors get handled properly
        window.location.href = card.extras.action_url;
      }
    });
  },
};
