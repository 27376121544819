import $ from "jquery";
import { Controller } from "@stimulus/core";
import Turbolinks from "turbolinks";

import { disableButton } from "./utils";

// TODO Move sign out here
export default class extends Controller {
  async linkGitHub(event) {
    const back = event.currentTarget.dataset.currentPath || "";
    doAction(event, `/users/auth/github?intent=link&back=${back}`);
  }

  async unlinkGitHub(event) {
    doAction(event, "/users/unauth/github");
  }

  async githubSignIn(event) {
    doAction(event, `/users/auth/github`);
  }
}

const doAction = (event, path) => {
  const button = event.currentTarget;
  disableButton(button);
  event.stopPropagation();
  Turbolinks.clearCache();

  const token = button.dataset.token;
  // Handle this the same way as jquery-ujs.
  const form = $(
    [
      `<form method="post" action="${path}">`,
      `<input name="_method" value="POST" type="hidden" />`,
      `<input name="authenticity_token" value="${token}" type="hidden" />`,
      `</form>`,
    ].join("")
  );
  form.hide().appendTo("body");
  form.trigger("submit");
};
