import { Controller } from "@stimulus/core";
import axios from "axios";
import Turbolinks from "turbolinks";
import swal from "sweetalert";

import { disableButton } from "./utils";

export default class extends Controller {
  static values = {
    id: String,
  };

  /**
   * Check if beta votes is correct. Moderator only.
   * @param {MouseEvent} event
   * @returns {Promise<void>}
   */
  async checkVotes(event) {
    disableButton(event.currentTarget);
    event.stopPropagation();

    try {
      const response = await axios.post(
        `/api/v1/code-challenges/${this.idValue}/check-beta-votes`
      );
      const json = response.data;
      if (json.success) {
        await swal({
          icon: "info",
          text: "Successfully enqueued a job. Try refreshing the page in a bit.",
        });
        return;
      }
    } catch (err) {
      console.error(err.message);
    }

    await swal({
      icon: "error",
      text: "Something went wrong. Check the console for any error messages, and let the admin know if the problem persists.",
    });
  }

  /**
   * Unpublish beta challenge. Moderator only.
   * @param {MouseEvent} event
   * @returns {Promise<void>}
   */
  async unpublishBeta(event) {
    disableButton(event.currentTarget);
    event.stopPropagation();

    try {
      const response = await axios.post(
        `/api/v1/code-challenges/${this.idValue}/unpublish-beta`
      );
      const json = response.data;
      if (json.success) {
        await swal({
          icon: "info",
          text: "Successfully unpublished",
        });
        Turbolinks.visit();
        return;
      }
    } catch (err) {
      console.error(err.message);
    }

    await swal({
      icon: "error",
      text: "Something went wrong. Check the console for any error messages, and let the admin know if the problem persists.",
    });
  }

  /**
   * Unapprove challenge. Admin only.
   * @param {MouseEvent} event
   * @returns {Promise<void>}
   */
  async unapprove(event) {
    disableButton(event.currentTarget);
    event.stopPropagation();

    try {
      const response = await axios.post(
        `/api/v1/code-challenges/${this.idValue}/unapprove`
      );
      const json = response.data;
      if (json.success) {
        await swal({
          icon: "info",
          text: "Successfully unapproved",
        });
        Turbolinks.visit();
        return;
      }
    } catch (err) {
      console.error(err.message);
    }

    await swal({
      icon: "error",
      text: "Something went wrong. Check the console for any error messages.",
    });
  }
}
