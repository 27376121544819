import $ from "jquery";
import axios from "axios";
import Turbolinks from "turbolinks";

import { Control } from "../../control";
import { RunnerIFrame } from "../runner_iframe";
import { sampleArray } from "../../utils";
import { MarkdownDisplay } from "../markdown_display";
import { CodeEditor } from "../code_editor";
import { slideUp } from "../../animation";

export class LandingIndexController extends Control {
  constructor(el, options) {
    super(el, options);
    this.challenge = this._app.data.challenge;

    if (this.challenge) {
      this.editor = new CodeEditor("#code_editor", {
        _app: this._app,
        allowFullScreen: false,
        lineNumbers: false,
      });
      this.markdownDisplay = new MarkdownDisplay("#description", {
        _app: this._app,
      });
      this.runner = new RunnerIFrame("#runner_frame", {
        _app: this._app,
      });
      this.runner.setTheme(true);
      this._controllers.push(this.editor, this.markdownDisplay, this.runner);

      this.setCodeChallenge();
    }

    // Need to wait for `this._app.utmProps`.
    setTimeout(() => {
      this.setUtmValues();
    }, 500);
  }

  setUtmValues() {
    // Set UTM values to secret inputs in the sign up form.
    const utm = this._app.utmProps;
    for (const input of document.querySelectorAll("input.utm-param")) {
      // `utm[source]` to `utm_source`
      const key = input.name.replace("[", "_").replace("]", "");
      const val = utm[key];
      if (val) input.value = val;
    }
  }

  attempt() {
    const request = {
      code: this.editor.getValue(),
      language: this._app.data.language,
      source: "landing",
      ciphered: ["setup", "fixture"],
      languageVersion: this.session.activeVersion,
      setup: this.session.package,
      fixture: this.session.fixture,
      testFramework: this.session.testFramework,
      relayId: this.session.solutionId,
    };

    if (!request.code) {
      this.editor.messages.fail(this.editor.emptyCodeMsg());
      return;
    }

    this.runner.run(request);
    $(".output").show();

    $("#attempt_btn").addClass("is-disabled");
    $("#try_again_btn").addClass("is-disabled");
  }

  handleResponse() {
    $("#attempt_btn").removeClass("is-disabled");
    $("#try_again_btn").removeClass("is-disabled");

    if (
      this.runner.response &&
      this.runner.response.type === "execution success"
    ) {
      if (
        this.runner.response.result &&
        this.runner.response.result.completed
      ) {
        $("#try_again_btn").hide();
        $("#success_msg b").html(
          sampleArray(["Great Job!", "Well Done!", "Solid!", "Impressive!"])
        );
        $("#success_msg").show();

        axios
          .post(
            this._app.route("notify", { solutionId: this.session.solutionId }),
            {
              token: this.runner.response.token,
              testFramework: this.runner.request.testFramework,
              code: this.runner.request.code,
              fixture: this.runner.request.fixture,
              languageVersion: this.runner.request.languageVersion,
            }
          )
          .then((_) => {
            setTimeout(() => Turbolinks.visit(), 1000);
          });
      }
    }
  }

  setCodeChallenge() {
    this.markdownDisplay.setMarkdown(this.challenge.description);

    $("#other_languages, #code_editor").show();

    axios
      .post(this._app.route("session", { language: this._app.data.language }))
      .then((response) => {
        this.session = response.data;
        $("#attempt_btn").removeClass("is-disabled");
      });

    this.editor.setValue(this.challenge.setup);
    this.editor.setMode(this._app.data.language);
    this.editor.focus();
  }
}

LandingIndexController.prototype.events = {
  /**
   * @this {LandingIndexController}
   */
  "#attempt_btn:not(.is-disabled) click"() {
    this.attempt();
  },

  /**
   * @this {LandingIndexController}
   */
  "#code_editor codeeditor.submit"() {
    if (this.session) this.attempt();
  },

  /**
   * @this {LandingIndexController}
   */
  "#sign_up_button click"() {
    $("#step_start").show();
    $("#landing").addClass("sign-up");
  },

  /**
   * @this {LandingIndexController}
   */
  "#step_signup form submit"($el) {
    $("#enlist_btn").addClass("is-disabled");
  },

  /**
   * @this {LandingIndexController}
   */
  "#step_start [data-language] click"($el, e) {
    Turbolinks.visit(`?language=${$el.attr("data-language")}`);
  },

  /**
   * @this {LandingIndexController}
   */
  "#try_again_btn click"() {
    slideUp($(".output").get(0));
  },

  /**
   * @this {LandingIndexController}
   */
  "#runner_frame message.notifyResponse"() {
    this.handleResponse();
  },
};
