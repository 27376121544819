import { successMessages, failureMessages } from "../consts";
import { Control } from "../control";
import { AlertMessageBox } from "./alert_message_box";
import { RunnerIFrame } from "./runner_iframe";

export class OutputPanel extends Control {
  constructor(el, options) {
    super(el, options);
    // TODO `this.element.find('iframe')` can be undefined
    this.runner = new RunnerIFrame(this.element.find("iframe"), {
      _app: this._app,
    });
    this.runner.setTheme(this._app.darkMode);
    this._controllers.push(this.runner);
    if (!this.options.box) return;

    this.box = new AlertMessageBox(this.element, {
      successMessage: this.options.successMessage,
      failMessage: this.options.failMessage,
      warnClassName: "info",
      displayDuration: 0,
      showClose: this.options.showClose,
    });
    this._controllers.push(this.box);
  }

  showStatus(success) {
    if (!this.box || !this.runner.request) return;

    if (this.runner.request.fixture && this.runner.request.fixture !== "") {
      this.box.showResult(success, null, 0);
    } else {
      this.box.warn("No tests cases provided", 0);
    }
  }
}

OutputPanel.prototype.events = {
  /**
   * @this {OutputPanel}
   */
  "iframe message.notifyResponse"() {
    this.showStatus(
      this.runner.response &&
        this.runner.response.result &&
        this.runner.response.result.completed
    );
  },

  /** @this {OutputPanel} */
  "#app dark-mode-toggled"($el, ev, isDark) {
    this.runner.setTheme(isDark);
  },
};

OutputPanel.defaults = {
  showClose: false,
  successMessage: successMessages,
  failMessage: failureMessages,
  box: true,
};
