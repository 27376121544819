import { Controller } from "@stimulus/core";
import { serialize } from "@shoelace-style/shoelace/dist/utilities/form.js";
import Turbolinks from "turbolinks";

import { fixSelectValueArray } from "./utils.js";

export default class extends Controller {
  static targets = ["drawer", "form", "ranks", "languages"];

  initialize() {
    fixSelectValueArray(this.ranksTarget);
    fixSelectValueArray(this.languagesTarget);
  }

  languagesChanged(event) {
    // Prevent combining `All` with language names
    if (event.target.value.length > 1 && event.target.value.includes("all")) {
      event.target.value = ["all"];
    }
  }

  openDrawer() {
    this.drawerTarget.show();
  }

  applyFilter() {
    const params = new URLSearchParams();
    const url = new URL(location.href);
    const obj = serialize(this.formTarget);
    for (const [k, v] of Object.entries(obj)) {
      params.set(k, Array.isArray(v) ? v.join(",") : v + "");
    }
    url.search = params;
    Turbolinks.visit(url.toString());
  }
}
