import $ from "jquery";

import { View } from "../view";
import { MarkdownEditor } from "../controls/markdown_editor";
import { CodeEditor } from "../controls/code_editor";

export class FormView extends View {
  configureDisabled(e) {
    if (e.element.parent(".is-disabled").length > 0) {
      const input = e.element.find("input");
      input.addClass("is-disabled");
      input.attr("disabled", true);
    }
  }

  configureMarkdownField(e, options) {
    const el = e.element.find(".markdown-editor");

    const editor = new MarkdownEditor(
      el,
      Object.assign({}, options || {}, { _app: this._app })
    );
    editor.ready = function () {
      if (e.element.parent(".is-disabled").length > 0) {
        editor.editor.setReadOnly(true);
      }
    };
    // two way binding
    el.on("texteditor.change", () => {
      e.element.data("context").value = editor.getValue();
    });
    el.data("editor", editor);
    el.addClass("js-markdown-editor");
  }

  configureCodeEditorField(e, options) {
    const editor = new CodeEditor(
      e.element,
      Object.assign({}, options || {}, { _app: this._app })
    );
    // two way binding
    e.element.on("texteditor.change", () => {
      e.element.data("context").value = editor.getValue();
    });
    e.element.data("editor", editor);
    e.element.addClass("js-code-editor");
  }
}

FormView.prototype.filters = {
  field_class(params) {
    let rules = "field";
    if (params.context.id) rules += ` ${params.context.id}`;
    if (params.includeData.required) rules += " required";
    if (params.context.errors && params.context.errors.length > 0) {
      rules += " has-error";
    }
    return rules;
  },

  field_value_class(params) {
    let rules = "field";
    if (params.context.errors && params.context.errors.length > 0) {
      rules += " has-error";
    }
    return rules;
  },
};

FormView.prototype.renderEvents = {
  /**
   * @this {FormView}
   */
  view() {
    // whenever a view is rendered we want to update the select boxes so that their value is actually selected.
    // This allows us to just set the select element's value attribute only, the select attribute will be added to the
    // appropriate option element once the following code is executed.
    this.element.find("select").each((_, el) => {
      const $el = $(el);
      if ($el.attr("value")) $el.val($el.attr("value"));
    });
  },

  /**
   * @this {FormView}
   */
  hidden_field(e) {
    // two way binding
    e.element.on("change", function () {
      e.element.data("context").value = $(this).val();
    });
  },

  /**
   * @this {FormView}
   */
  input_field(e) {
    // two way binding
    e.element.find("input").on("change, keyup", function () {
      e.element.data("context").value = $(this).val();
    });
    this.configureDisabled(e);
  },

  /**
   * @this {FormView}
   */
  checkbox_field(e) {
    // two way binding
    e.element.find("input").on("change", function () {
      e.element.data("context").value = $(this).is(":checked");
    });
    this.configureDisabled(e);
  },

  /**
   * @this {FormView}
   */
  markdown_field(e) {
    this.configureMarkdownField(e);
  },

  /**
   * @this {FormView}
   */
  code_editor_field(e) {
    this.configureCodeEditorField(e);
  },
};
