import axios from "axios";
import * as Sentry from "@sentry/browser";

import { createApiOp, mountApp } from "../vue";

// // we will pull from house ads to ensure we show each one once per session before resetting
// let houseAds = [];

export function createPromotedAdDisplayState(
  { type, id, user, red, contrast, zone } = {
    type: "image",
    contrast: false,
    red: false,
  }
) {
  const state = {
    type,
    id,
    user,
    contrast,
    get imgStyle() {
      let style = "";
      if (this.ad?.raw?.backgroundColor) {
        style += `background-color: ${this.ad.raw.backgroundColor}; width: 40px !important;`;
      }
      if (this.ad?.maxWidth) {
        style += `max-width: ${this.ad.maxWidth};`;
      }

      return style;
    },
    zone,
    zoneLoader: createApiOp(async () => {
      const response = await axios.get("/ads/fill", {
        params: {
          zone: state.zone,
          placement: id,
        },
      });
      return response.data?.ad;
    }),
    get zoneAd() {
      this.zoneLoader.maybeLoad();
      return this.zoneLoader.data;
    },
    get ad() {
      if (this.zoneAd) {
        return this.zoneAd;
      }
      // at this point if there is no zoneAd, we tried loading it, and we haven't tried
      // loading a house ad, let's try that
      if (
        !this.zoneLoader.loading &&
        this.zoneLoader.loaded &&
        this.zone !== "house"
      ) {
        this.zone = "house";
        this.zoneLoader.load();
      }

      return this.zoneLoader.data;
    },
    mounted() {},
    refresh() {
      this.zoneLoader.load();
    },
  };

  return state;
}

export function mountPromotedAdDisplay(sel, options) {
  try {
    return mountApp(sel, createPromotedAdDisplayState(options));
  } catch (err) {
    Sentry.captureException(err);
  }
}
