import $ from "jquery";

import { toUpperFirst } from "../utils";

export const filters = {
  escape_html(params) {
    if (params.value) {
      return (params.value + "")
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
    }
  },

  unescape_html(params) {
    if (params.value) {
      return (params.value + "")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&amp;/g, "&")
        .replace(/&#39;/g, "'");
    }
  },

  strip_scripts(params) {
    if (params.value) {
      return (params.value + "").replace(/<script.*?>[\s\S]*<\/script>/gi, "");
    }
  },

  pluralize(params) {
    const word = params.value === 1 ? params.args[0] : params.args[1];
    return `${params.value} ${word}`;
  },

  stringify(params) {
    return JSON.stringify(params.value);
  },

  count(params) {
    return numberValue(params.value);
  },

  // converts a value to a string, useful for when you need to make sure falsy values get rendered correctly
  string(params) {
    return params.value + "";
  },

  "any?"(params) {
    return numberValue(params.value) > 0;
  },

  "many?"(params) {
    return numberValue(params.value) > 1;
  },

  "one?"(params) {
    return numberValue(params.value) === 1;
  },

  "none?"(params) {
    return !params.value || numberValue(params.value) === 0;
  },

  "zero?"(params) {
    return numberValue(params.value) === 0;
  },

  "contains?"(params) {
    return params.value.indexOf(params.args[0]) >= 0;
  },

  "false?"(params) {
    return params.value === false;
  },

  "true?"(params) {
    return params.value === true;
  },

  "null?"(params) {
    return params.value === null;
  },

  "el_is_active?"(params) {
    return $(params.args[0]).is(".is-active");
  },

  disabled_class(params) {
    if (params.value) return "is-disabled";
  },

  active_class(params) {
    if (params.value) return "is-active";
  },

  hidden_class(params) {
    if (params.value) return "is-hidden";
  },

  "current_user_is_admin?"(params) {
    return params.currentUser.role === "admin";
  },

  "moderator?"(params) {
    return (
      params.currentUser.role === "admin" ||
      params.currentUser.role === "moderator"
    );
  },

  "guest_user?"(params) {
    const currentUser = params.currentUser;
    return !currentUser.id || currentUser.guest;
  },

  "is_current_user?"(params) {
    const currentUser = params.currentUser;
    if (currentUser) {
      return (
        params.value === currentUser.id || params.value === currentUser.username
      );
    } else {
      return false;
    }
  },

  prepend(params) {
    return params.args[0] + params.value;
  },

  append(params) {
    return params.value + params.args[0];
  },

  lt(params) {
    return params.value < params.args[0];
  },

  gt(params) {
    return params.value > params.args[0];
  },

  eq(params) {
    return params.value === params.args[0];
  },

  ne(params) {
    return params.value !== params.args[0];
  },

  in(params) {
    return (params.args[0] || []).indexOf(params.value) >= 0;
  },

  nin(params) {
    return (params.args[0] || []).indexOf(params.value) === -1;
  },

  lower_case(params) {
    if (params.value != null) return params.value.toString().toLowerCase();
  },

  upper_case(params) {
    if (params.value != null) return params.value.toString().toUpperCase();
  },

  upper_first(params) {
    if (params.value != null) return toUpperFirst(params.value.toString());
  },

  take(params) {
    if (params.value != null)
      return params.value.slice(0, Math.max(0, params.args[0] || 0));
  },

  skip(params) {
    if (params.value != null) return params.value.slice(params.args[0]);
  },

  split(params) {
    if (params.value) {
      const values = params.value.split(
        params.args.length > 0 ? params.args[0] : /,[ ]*/
      );
      return values.filter((v) => !!v);
    } else {
      return [];
    }
  },
};

const numberValue = (value) => {
  if (Array.isArray(value)) return value.length;
  if (value) return value;
  return 0;
};
