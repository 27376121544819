import $ from "jquery";

import { Control } from "../control";
import { TextEditor } from "./text_editor";
import { MarkdownDisplay } from "./markdown_display";
import { fadeIn } from "../animation";

export class MarkdownEditor extends Control {
  constructor(el, options) {
    super(el, options);
    const init = () => {
      // we use composition instead of inheritance to extend the text editor.
      this.editor = new TextEditor(
        this.element.find(".text-editor-container"),
        {
          _app: this._app,
          mode: "markdown",
          theme: this.options.theme,
          lineNumbers: false,
          lineWrapping: true,
          stashGroup: this.options.stashGroup,
          value: this.options.originalValue,
          styleActiveLine: this.options.styleActiveLine,
          allowFullScreen: this.options.allowFullScreen,
          enterFullScreen: this.onEnterFullScreen.bind(this),
          exitFullScreen: this.onExitFullScreen.bind(this),
          smartIndent: false,
          autoCloseBrackets: false,
          change: () => this.refresh(),
          // Jump to the ends of visual lines (can be wrapped) instead of the actual line.
          extraKeys: { End: "goLineRight", Home: "goLineLeft" },
        }
      );

      this.previewDisplay = new MarkdownDisplay(
        this.element.find(".markdown").eq(0),
        {
          _app: this._app,
          emptyText: this.options.emptyPreviewText,
          language: this.options.language,
        }
      );

      this._controllers.push(this.editor, this.previewDisplay);

      this.originalValue = this.options.originalValue || this.editor.getValue();

      this.refreshHeight();
      setTimeout(() => this.updatePreview(), 0);

      // assign elements to array
      this.panels = [this.editor.element, this.previewDisplay.element];

      if (typeof this.ready === "function") this.ready();
    };

    const initWhenVisible = () => {
      if (this.options.alwaysVisible || this.element.is(":visible")) {
        init();
      } else if (!this.destroyed) {
        setTimeout(initWhenVisible, 500);
      }
    };
    initWhenVisible();
  }

  focus() {
    this.editor.focus();
  }

  refreshHeight(height) {
    if (height == null) {
      height = this.options.editorHeight || this.element.height();
    }
    if (this.element.is(":visible")) {
      if (!this.fullScreenEnabled) {
        this.editor.setHeight(height);
      }
    }
  }

  refresh() {
    this.updatePreview();

    if (this.isDirty()) {
      if (!this.dirtyFired) {
        this.element.trigger("markdowneditor.dirty", this, this.getValue());
        this.dirtyFired = true;
      }
    } else if (this.dirtyFired) {
      this.markClean();
    }
  }

  isDirty() {
    return this.getValue() !== this.originalValue;
  }

  markClean() {
    this.originalValue = this.editor.getValue();
    this.dirtyFired = false;
    this.element.trigger("markdowneditor.clean", this, this.getValue());
  }

  getValue() {
    return this.editor.getValue();
  }

  // this method is called internally by stashable.setValue(value, stashId, preferStash)
  setValue(...args) {
    this.editor.setValue(...args);
  }

  setStashId(id) {
    this.editor.setStashId(id);
  }

  updatePreview() {
    this.previewDisplay.setMarkdown(this.getValue());
  }

  setLanguage(lang) {
    this.previewDisplay.setLanguage(lang);
  }

  setTheme(theme) {
    this.editor.setTheme(theme);
  }

  appendToParent(parentElement) {
    $(this).appendTo(parentElement);
  }

  resize() {
    let height = $(window).height();

    if (this.fullScreenEnabled) {
      const padding = Object.values(
        this.element.css(["paddingTop", "paddingBottom"])
      ).reduce((s, v) => parseInt(v, 10), 0);
      height -= padding;
    }

    for (let panel of this.panels) $(panel).height(height);

    this.editor.resize();
  }

  onEnterFullScreen() {
    if (this.fullScreenEnabled) return;

    this.fullScreenEnabled = true;
    this.originalParents = [];
    this.originalHeight = this.editor.element.height();

    this.fullScreenContainer().appendTo("#app").hide();
    this.collapseLink().on("click", () => this.editor.exitFullScreen()); // click handler is removed each time element is removed

    for (let $panel of this.panels) {
      // populate array of current panel parents
      this.originalParents.push($panel.parent());
      $panel.addClass("is-full-screen").appendTo(this.fullScreenContainer());
    }

    fadeIn(this.fullScreenContainer().get(0));

    setTimeout(() => this.resize(), 0);
  }

  onExitFullScreen() {
    if (!this.fullScreenEnabled) return;

    this.fullScreenEnabled = false;
    this.editor.setHeight(this.originalHeight);
    for (let i = 0; i < this.panels.length; i++) {
      const $panel = this.panels[i];
      $panel
        .removeClass("is-full-screen")
        .height("")
        .appendTo(this.originalParents[i]);
    }

    this.fullScreenContainer().remove();

    if (typeof this.options.exitFullScreen === "function")
      this.options.exitFullScreen();
  }

  fullScreenContainer() {
    if (!this._fullScreenContainer)
      this._fullScreenContainer = $('<div id="markdown_full_screen"></div>');
    return this._fullScreenContainer;
  }

  collapseLink() {
    if (!this._collapseLink)
      this._collapseLink = $(
        '<a class="collapse-btn"><i class="icon-moon-collapse"></i>Exit Full Screen</a>'
      ).appendTo(this.fullScreenContainer());

    return this._collapseLink;
  }
}

MarkdownEditor.prototype.events = {
  /**
   * @this {MarkdownEditor}
   */
  ".btn-expand click, .js-expand click"(el, e) {
    e.preventDefault();
    e.stopPropagation();

    this.editor.enterFullScreen();
  },
};

MarkdownEditor.defaults = {
  allowFullScreen: true,
  emptyPreviewText: null,
  stashGroup: "markdown",
  language: null,
  styleActiveLine: true,
  editorHeight: null,
  theme: null,
  // true if visibility detection should not be used for lazy initialization
  alwaysVisible: false,
};
