import $ from "jquery";
import axios from "axios";

import { Control } from "../control";

export class LabelVotesController extends Control {
  constructor(el, options) {
    super(el, options);
  }
}

LabelVotesController.prototype.events = {
  /**
   * @this {LabelVotesController}
   */
  "a.vote-label click"($el, e) {
    const $voter = $el.closest(".vote-labels");
    const route = this._app.route("label_vote", {
      refId: $voter.data("vote-ref-id"),
      label: $el.data("label"),
    });
    if ($el.hasClass("is-voted")) {
      axios.delete(route).then(onResponse);
    } else {
      axios.post(route).then(onResponse);
    }
  },
};

const onResponse = (response) => {
  const json = response.data;
  if (json.success) {
    const votes = json.votes;
    const $el = $(`[data-vote-ref-id='${json.groupId}']`);
    for (const [label, { count, voted }] of Object.entries(votes)) {
      const $button = $el.find(`.vote-label[data-label=${label}]`);
      $button.find(`span`).text(count);
      $button.toggleClass("is-voted", voted);
    }
  }
};
