import { Control } from "../../control";
import { MarkdownEditor } from "../markdown_editor";

export class CollectionsEditController extends Control {
  constructor(el, options) {
    super(el, options);
    setTimeout(() => {
      this.markdownEditor = new MarkdownEditor("#description", {
        _app: this._app,
        stashGroup: "collection",
      });
      this._controllers.push(this.markdownEditor);
    }, 0);
  }
}
