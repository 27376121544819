import { Channel } from "@anycable/web";

export default class DjaxChannel extends Channel {
  static identifier = "DjaxChannel";

  receive(message, _meta) {
    // TODO Also receives a message without `dmid`
    if (message.dmid) return this.emit("djax", message);
  }
}
